import { api } from "./api";

export const sendNewMessageService = async (data: INewMessage) => {
  const response2 = await api.post("/answer-message", data);
  return response2.data;
};

export const readMessageService = async (
  orderID: number,
  messageType?: string
) => {
  const response2 = await api.post("/read-messages", {
    orderID,
    messageType: messageType ? messageType : "orderToBuy",
  });
  return response2.data;
};

export const sendEmail = async (data: ISendEmail) => {
  const response2 = await api.post("/send-email", {
    ...data,
  });
  return response2.data;
};
