import { Box, Typography } from "@mui/material";
import { EmailField } from "../../Molecules";
import { Button } from "../../Atoms";
import treasureBoxLogo from "../../../../assets/images/NEWTREASUREBOXLOGO2.png";
import { useNavigate } from "react-router-dom";
import { BoxContent } from "../../Atoms";
import { FormikProps } from "formik";
import { useTranslate } from "../../../../hooks/useTranslate";
import useAuth from "../../../../hooks/context/useAuth";

interface Props {
  error: boolean;
  formik: FormikProps<IForgotPasswordFormValues>;
}

export function ForgotPasswordBox({ error, formik }: Props) {
  const { strings } = useTranslate();
  const { language } = useAuth();
  const navigate = useNavigate();

  const handle = () => {
    formik.handleSubmit();
  };

  return (
    <Box>
      <BoxContent>
        <img
          style={{ marginTop: -15, marginBottom: 20 }}
          src={treasureBoxLogo}
          width={150}
          alt="treasurebox logo"
        />
        <Typography>
          {strings.forgotPassword.emailStep.forgotPassword[language]}
        </Typography>
        <EmailField
          onChange={(val) => formik.setFieldValue("email", val)}
          value={formik.values.email}
          error={formik.touched.email && formik.errors.email ? true : false}
          errorMessage={formik.touched.email ? formik.errors.email : ""}
          placeholder="E-mail"
        />

        <Box mt={3} width={"100%"}>
          <Button config={{ variant: "contained", onClick: handle }}>
            {strings.forgotPassword.emailStep.askCode[language]}
          </Button>

          <Button
            config={{
              variant: "text",
              onClick: () => navigate("/"),
              sx: { bgcolor: "transparent" },
            }}
            style={{ marginTop: 12 }}
          >
            {strings.buttons.cancelBtn[language]}
          </Button>
        </Box>
      </BoxContent>
    </Box>
  );
}
