import { createContext, useContext, useEffect, useMemo, useState } from "react";
import useAuth from "./useAuth";
import {
  readMessageService,
  sendNewMessageService,
} from "../../services/messageService";
import { useLocalStorage } from "../useLocalStorage";
import useToast from "./useToast";
import { useTranslate } from "../useTranslate";
import {
  createCreditInstance,
  deleteCreditProof,
  updatePaymentCreditsService,
  userOneCredit,
  userUpdateCredit,
  usersCreditList,
} from "../../services/creditsService";
import {
  CreateCreditIntance,
  Credits,
  UpdateCreditInstance,
} from "../../types/credits";

const CreditsContextAdmin = createContext({} as CreditsContextProps);

type OrderProviderProps = {
  children: React.ReactNode;
};

function CreditsAdminProvider({ children }: OrderProviderProps) {
  const { getFilterStorage, setFilters } = useLocalStorage();
  const { getUserLocalStorage, language } = useAuth();
  const { setOpen, setToastData } = useToast();
  const { strings } = useTranslate();
  const { user } = getUserLocalStorage();
  const [creditsData, setCreditsData] = useState<Credits[]>([]);
  const [credit, setCredit] = useState<Credits>();
  const [handleIDCredit, setHandleIDCredit] = useState<number | null>();
  const [records, setRecords] = useState(0);
  const [attData, setAttData] = useState(false);
  const [loading, setLoading] = useState({
    uploadLoading: false,
    creditsLoading: false,
    oneCredit: false,
  });
  const [keepAttMessages, setKeepAttMessages] = useState(false);
  // setHowManyPages(Math.ceil(response.count / filter.itensPerPage));
  const creditsDataMemo = useMemo(() => creditsData, [creditsData]);

  const getData = async (cleanFilter?: boolean, noLoading?: boolean) => {
    if (!noLoading) {
      setLoading((old) => ({ ...old, creditsLoading: true }));
    }
    try {
      const filt = getFilterStorage(cleanFilter);
      const response = await usersCreditList({
        ...filt,
        searchKey: filt.key,
        userID: user.user.id,
      });
      setCreditsData(response.data);
      setRecords(response.count);
    } catch {
    } finally {
      setLoading((old) => ({ ...old, creditsLoading: false }));
    }
  };

  const getOneCredit = async (loading?: boolean) => {
    if (loading) {
      setLoading((old) => ({ ...old, oneCredit: true }));
    }
    try {
      if (handleIDCredit) {
        const response = await userOneCredit(handleIDCredit);
        setCredit(response);
      }
    } catch {
    } finally {
      setLoading((old) => ({ ...old, oneCredit: false }));
    }
  };

  const submitCredits = async (info: CreateCreditIntance) => {
    try {
      await createCreditInstance(info);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: strings.toastMessages.orderCreated[language],
      });
      setOpen(true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    } finally {
      setHandleIDCredit(null);
      getData(false, true);
    }
  };

  const updateCredits = async (info: UpdateCreditInstance) => {
    try {
      await userUpdateCredit(info);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: strings.toastMessages.proofPayment[language],
      });
      setOpen(true);

      getData();
      getOneCredit();
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    } finally {
    }
  };

  const fileRemoveAdmin = async (creditID: number) => {
    try {
      setLoading((old) => ({ ...old, uploadLoading: true }));
      await deleteCreditProof(creditID);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    } finally {
      setLoading((old) => ({ ...old, uploadLoading: false }));
      getOneCredit();
    }
  };

  const sendNewMessage = async ({
    orderID,
    userID,
    description,
    type,
    messageType,
  }: INewMessage) => {
    if (!userID || !orderID) return;

    try {
      await sendNewMessageService({
        orderID,
        userID,
        description,
        type,
        messageType,
      });
      getOneCredit();
      getData(false, true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    }
  };

  const readAllMessages = async (orderID?: number) => {
    if (!orderID) return;
    try {
      await readMessageService(orderID, "credits");

      getOneCredit();
      getData(false, true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    } finally {
    }
  };

  const verifyPayment = async (chargeID: string, creditID: number) => {
    try {
      if (handleIDCredit) {
        await updatePaymentCreditsService({ creditID, sessionid: chargeID });
      }
    } catch {
    } finally {
      getOneCredit(true);
      getData();
    }
  };

  const cleanFilters = () => {
    getFilterStorage(true);
    getData(true);
  };

  useEffect(() => {
    getOneCredit(true);
    // eslint-disable-next-line
  }, [handleIDCredit]);

  useEffect(() => {
    const interval = setInterval(() => {
      getOneCredit();
    }, 300000);

    if (!keepAttMessages) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [keepAttMessages]);

  useEffect(() => {
    const interval = setInterval(() => {
      getData(false, true);
    }, 300000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (getFilterStorage().searchTerm !== "") {
      getData();
    } else {
      getData(false, true);
    }
    // eslint-disable-next-line
  }, [attData]);

  return (
    <CreditsContextAdmin.Provider
      value={{
        verifyPayment,
        creditsDataMemo,
        setFilters,
        submitCredits,
        updateCredits,
        cleanFilters,
        loading,
        getOneCredit,
        setCredit,
        credit,
        handleIDCredit,
        setHandleIDCredit,
        sendNewMessage,
        records,
        getFilterStorage,
        setAttData,
        readAllMessages,
        setKeepAttMessages,
        fileRemoveAdmin,
      }}
    >
      {children}
    </CreditsContextAdmin.Provider>
  );
}

export { CreditsAdminProvider, CreditsContextAdmin };

export default function useCreditsAdmin() {
  return useContext(CreditsContextAdmin);
}
