import { Divider, Paper, Typography } from "@mui/material";
import { BoxWithTitle, TextCard } from "../../Molecules";

interface Props {
  fullAddress: any[];
  complement?: string;
  alignText?: "center" | "right" | "left";
  boxTitle: string;
  padding?: string;
  width?: string;
}

export function BoxInfo({
  fullAddress,
  complement,
  alignText,
  boxTitle,
  padding,
  width = "100%",
}: Props) {
  return (
    <Paper elevation={1} sx={{ borderRadius: "5px", width }}>
      <BoxWithTitle title={boxTitle}>
        {fullAddress.map((item, index) => (
          <div key={index}>
            <TextCard
              title={item.name}
              text={item.value}
              alignText={alignText}
              padding={padding}
            />
            <Divider />
          </div>
        ))}
        {complement && (
          <Typography textAlign={"center"} fontWeight={500} mt={3}>
            {complement}
          </Typography>
        )}
      </BoxWithTitle>
    </Paper>
  );
}
