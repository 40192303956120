import { api } from "./api";

export const getSystemConfigService = async () => {
  const response2 = await api.get("/get-site-config");
  return response2.data;
};

export const updateSystemConfigService = async (data: any) => {
  const response2 = await api.post("/update-site-config", data);
  return response2.data;
};
