import { Button as MaterialButton, ButtonProps } from "@mui/material";
import { colors } from "../../../../theme/colors";

export function Button({
  config,
  children,
  style,
}: {
  config?: ButtonProps;
  children: React.ReactNode;
  style?: React.CSSProperties;
}) {
  return (
    <MaterialButton
      sx={{
        bgcolor: colors.red[500],
        "&:hover": { bgcolor: colors.redAlpha[500] },
      }}
      fullWidth
      {...config}
      style={style}
    >
      {children}
    </MaterialButton>
  );
}
