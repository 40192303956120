import { isValid, format } from "date-fns";
import useAuth from "./context/useAuth";
export function UseDateTime() {
  const { language } = useAuth();

  const formatedDate = (date: Date, hasHours?: boolean) => {
    if (date === null) {
      return "-";
    }

    const formatWithHours =
      language === "english" ? "MM/dd/yyyy HH:mm:ss" : "dd/MM/yyyy HH:mm:ss";
    const formatDate = language === "english" ? "MM/dd/yyyy" : "dd/MM/yyyy";

    const formatedHours = hasHours ? formatWithHours : formatDate;
    if (isValid(new Date(date))) {
      return format(new Date(date), formatedHours);
    } else {
      return "Invalid date";
    }
  };

  return { formatedDate };
}
