import {
  CardActions,
  CardContent,
  Card,
  Box,
  Typography,
  Chip,
} from "@mui/material";
import {
  DataText,
  Button,
  NoData,
  CircularLoading,
  CardImages,
  MailNotification,
} from "../../Atoms";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { UseDateTime } from "../../../../hooks/useDateTime";
import { useCommonData } from "../../../../hooks/useCommonData";
import { blue } from "@mui/material/colors";
import useAuth from "../../../../hooks/context/useAuth";

interface Props {
  data: Shippment[];
  onClickShippment(id: number): void;
  isLoading?: boolean;
}

export function ShippmentCards({ data, onClickShippment, isLoading }: Props) {
  const { labelStatus, shippmentLabelStatus, shipCards } = useCommonData();
  const { formatedDate } = UseDateTime();
  const { isSmalldesktop, isMobile, mediumDesktop, semiSmalldesktop } =
    useBreakpoints();
  const { user, siteConfig } = useAuth();

  const titleWidth = 100;

  const breakpoints = () => {
    if (isMobile) return "100%";
    if (isSmalldesktop) return "48.5%";
    if (semiSmalldesktop) return "32%";
    if (mediumDesktop) return "23.9%";
    return "19%";
  };

  const hasNewMessage = (data: Shippment) => {
    const messages = data?.WarningMessage as any;
    if (!messages) return false;
    if (messages?.length === 0) return false;
    if (!messages[0]?.read && user?.user?.id !== messages[0]?.usersId) {
      if (user?.user?.role === messages[0]?.type) {
        return false;
      }
      return true;
    }
    return false;
  };

  const getImages = (item: Shippment) => {
    const imagens = item?.Delivery?.map((delivery) => {
      return delivery?.ArrivedProducts;
    });
    const imagesArray = imagens.map((prod: any) => ({
      code: prod?.ProductImages[0]?.code,
    }));

    return imagesArray ?? [];
  };

  const getKG = (item: Shippment) => {
    const orderToShip = item.OrderToShip;

    const arrivePRDS = item?.Delivery?.map((order) => {
      return order.ArrivedProducts;
    });

    const calc = arrivePRDS?.map((item: any, index: any) => {
      const qtd = orderToShip[index]?.shippedQtd;
      const prodID = orderToShip[index]?.arrivedProductsId;
      const itemKG = item?.id === prodID ? item?.kg * qtd : 0;
      return itemKG;
    });
    const sum = calc?.reduce((acc, next) => acc + next, 0);
    const boxWeight =
      item?.customBoxWeight > 0
        ? item?.customBoxWeight
        : siteConfig?.defaultBoxWeight;
    const sumBoxWeight = boxWeight + sum;
    return sumBoxWeight;
  };

  const shipType = {
    smallPacket: "Small Packet",
    ems: "EMS",
    surface: "Surface",
    DHL: "DHL",
  } as any;

  return (
    <Box
      width={"auto"}
      display="flex"
      flexWrap={"wrap"}
      rowGap={1.5}
      columnGap={1.5}
      justifyContent={"flex-start"}
    >
      {isLoading ? (
        <CircularLoading />
      ) : data && data?.length > 0 ? (
        data.map((item, index) => (
          <Card
            sx={{
              width: breakpoints(),
              minWidth: "245px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              border: `2px solid ${blue[50]}`,
            }}
            key={index}
          >
            <Box sx={{ bgcolor: blue[50] }}>
              <Typography
                p={1}
                variant="h2"
                fontWeight={500}
                sx={{
                  maxHeight: 14,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {shipCards.shippment} {item?.id}
              </Typography>
            </Box>
            <CardImages images={getImages(item)} />
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  justifyContent: "center",
                  rowGap: 0.5,
                }}
              >
                <DataText
                  title={shipCards.arrivedAt}
                  text={formatedDate(item?.created)}
                  titleProps={{ width: titleWidth }}
                />

                <DataText
                  title={getKG(item) > 999 ? shipCards.kg : shipCards.g}
                  text={`${getKG(item).toLocaleString()}`}
                  titleProps={{ width: titleWidth }}
                />

                <DataText
                  title={"Suite:"}
                  text={`${item?.suite}`}
                  titleProps={{ width: titleWidth }}
                />

                <DataText
                  title={shipCards.shipped}
                  text={formatedDate(item?.shipped)}
                  titleProps={{ width: titleWidth }}
                />

                <DataText
                  title={shipCards.type}
                  text={
                    shipType[item?.shippmentType as keyof typeof shipType] ?? ""
                  }
                  titleProps={{ width: titleWidth }}
                />
              </Box>
            </CardContent>
            <CardActions
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                config={{
                  size: "small",
                  fullWidth: false,
                  onClick: () => onClickShippment(item?.id),
                }}
                style={{ color: "white" }}
              >
                {labelStatus.button.label}
              </Button>
              <MailNotification hasNewMessage={hasNewMessage(item)} disabled />
              <Chip
                label={shippmentLabelStatus[item.status]?.label}
                style={{
                  background: shippmentLabelStatus[item.status]?.color,
                  color: "white",
                  fontWeight: 500,
                }}
              />
            </CardActions>
          </Card>
        ))
      ) : (
        <NoData text="No data" />
      )}
    </Box>
  );
}
