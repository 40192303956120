import { Box, Button, Typography } from "@mui/material";
import { amber, blue, orange, teal } from "@mui/material/colors";
import { useTranslate } from "../../../../hooks/useTranslate";
import useAuth from "../../../../hooks/context/useAuth";

interface Props {
  setOrderType(val: OrderType): void;
}

export function SelectOrderType({ setOrderType }: Props) {
  const { strings } = useTranslate();
  const { language } = useAuth();

  return (
    <Box
      width={"100%"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      gap={3}
      mb={4}
    >
      <Typography variant="h1">
        {strings.orderModal.titleWichOrder[language]}
      </Typography>
      <Button
        variant="contained"
        onClick={() => setOrderType("orderToBuy")}
        sx={{
          width: 300,
          bgcolor: blue[400],
          "&:hover": { bgcolor: blue[300] },
          fontWeight: 800,
        }}
      >
        {strings.buttons.newService[language]}
      </Button>
      <Button
        fullWidth
        variant="contained"
        onClick={() => setOrderType("askQuestion")}
        sx={{
          width: 300,
          bgcolor: amber[400],
          "&:hover": { bgcolor: amber[200] },
          fontWeight: 800,
        }}
      >
        {strings.buttons.askProduct[language]}
      </Button>
      <Button
        fullWidth
        variant="contained"
        onClick={() => setOrderType("bid")}
        sx={{
          width: 300,
          bgcolor: teal[400],
          "&:hover": { bgcolor: teal[300] },
          fontWeight: 800,
        }}
      >
        {strings.buttons.bid[language]}
      </Button>
      <Button
        fullWidth
        variant="contained"
        onClick={() => setOrderType("auction")}
        sx={{
          width: 300,
          bgcolor: orange[400],
          "&:hover": { bgcolor: orange[300] },
          fontWeight: 800,
        }}
      >
        {strings.buttons.auction[language]}
      </Button>
    </Box>
  );
}
