import * as yup from "yup";
import { useTranslate } from "../../../../hooks/useTranslate";

export function Validation(language: "english" | "portuguese") {
  const { strings } = useTranslate();
  const validationSchema = yup.object({
    name: yup.string().required(strings.validationFields.title[language]),
    kg: yup
      .number()
      .test((val) => {
        if (!val) {
          return false;
        }
        if (val <= 0) {
          return false;
        } else {
          return true;
        }
      })
      .required(strings.validationFields.kg[language]),
    itemsQuantity: yup
      .number()
      .test((val) => {
        if (!val) {
          return false;
        }
        if (val <= 0) {
          return false;
        } else {
          return true;
        }
      })
      .required(strings.validationFields.itemQtd[language]),
    suiteId: yup.number().required(strings.validationFields.suite[language]),
    arrivedAt: yup.string().required(strings.validationFields.date[language]),
  });
  return { validationSchema };
}
