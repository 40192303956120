import { CardContent, Typography, Card } from "@mui/material";

import * as MaterialIcons from "@mui/icons-material";

interface Props {
  text: string;
  icon: string;
  background?: string;
  cardWidth?: number | string;
  iconColor?: string;
}

export function BasicCard({
  text,
  icon = "",
  background = "transparent",
  cardWidth = 275,
  iconColor,
}: Props) {
  const getIcon = (iconName: any, color?: string) => {
    const Icon = MaterialIcons[iconName as keyof typeof MaterialIcons];
    return Icon ? <Icon style={{ fill: color }} /> : <></>;
  };
  return (
    <Card
      sx={{
        width: cardWidth,
        display: "flex",
        alignItems: "center",
        background,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{ fontSize: 14, mr: 2 }}
          color="text.secondary"
          textAlign={"center"}
          display={"flex"}
          variant="h2"
        >
          {getIcon(icon, iconColor)}
        </Typography>
        <Typography variant="h2">{text}</Typography>
      </CardContent>
    </Card>
  );
}
