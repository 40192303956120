import { Box, Container, Typography, Button, Divider } from "@mui/material";

import merryBright from "../../../../../assets/images/merry_bright.png";
import { amber, blue, cyan, grey, red, teal } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { useBreakpoints } from "../../../../../hooks/useBreakpoins";
import { BasicCard } from "../../../Molecules/BasicCard";
import { ShippingFeeSimulator } from "../../ShippingFeeSimulator";
import { FadeInContainer, TitleDivider } from "../../../Atoms";

export function Home() {
  const nav = useNavigate();
  const { isSmalldesktop } = useBreakpoints();

  const titlesize = isSmalldesktop ? 20 : 25;
  return (
    <FadeInContainer>
      <Box>
        <Box>
          <Container
            maxWidth="lg"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 5,
              mb: isSmalldesktop ? -8 : -15,
              zIndex: 1515418518,
            }}
          >
            <Typography
              sx={{
                color: "black",
                fontSize: titlesize,
                textAlign: "center",
              }}
            >
              From Japan directly to your address
            </Typography>
            <Typography
              sx={{
                color: "black",
                fontSize: titlesize,
                textAlign: "center",
                zIndex: 488748748,
              }}
            >
              Without leaving home!
            </Typography>
            <Typography
              sx={{
                color: "black",
                fontSize: isSmalldesktop ? 19 : 25,
                textAlign: "center",
                zIndex: 488748748,
              }}
            >
              Ease and comfort in your hands
            </Typography>
            <Button
              variant={"contained"}
              sx={{
                width: 300,
                height: 60,

                background: amber[500],
                "&:hover": { background: amber[200] },
                mt: 3,
                fontSize: 20,
              }}
              onClick={() => nav("/login/new-user")}
            >
              Create your account
            </Button>
            <TitleDivider fontSize={30} title="WHY USE OUR SERVICES?" />
            <img
              src={merryBright}
              alt="boxPhoto"
              width={"100%"}
              height={"100%"}
              style={{ overflowY: "hidden", objectFit: "cover", zIndex: 1 }}
            />
          </Container>
        </Box>
      </Box>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            justifyContent: "center",
            mt: isSmalldesktop ? 10 : 20,
          }}
        >
          <Box width={isSmalldesktop ? "100%" : "48%"}>
            <Box width={"100%"} mb={2}>
              <Typography
                fontSize={titlesize}
                fontWeight={500}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                How to buy on our site
              </Typography>
              <Divider sx={{ mt: 2 }} />
            </Box>
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
              <iframe
                width="100%"
                height="500px"
                src="https://www.youtube.com/embed/ZHIp9JDfrMM?si=a6dlFaJmsuZLoH1s"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              />
            </Box>
          </Box>

          <Box width={isSmalldesktop ? "100%" : "48%"} mb={5}>
            <Box width={"100%"} mb={2}>
              <Typography
                fontSize={titlesize}
                fontWeight={500}
                textAlign={"center"}
                alignSelf={"center"}
              >
                How to ask for shipping?
              </Typography>
              <Divider sx={{ mt: 2 }} />
            </Box>
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
              <iframe
                width="100%"
                height="500px"
                src="https://www.youtube.com/embed/PNxrk-j6QB8?si=pGj5EdCBCaE9ZH8j"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              />
            </Box>
          </Box>

          <Box width={isSmalldesktop ? "100%" : "48%"} mb={5}>
            <Box width={"100%"} mb={2}>
              <Typography
                fontSize={titlesize}
                fontWeight={500}
                textAlign={"center"}
                alignSelf={"center"}
              >
                How does order redirection works?
              </Typography>
              <Divider sx={{ mt: 2 }} />
            </Box>
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
              <iframe
                width="100%"
                height="500px"
                src="https://www.youtube.com/embed/Cj_CXB5DIiA?si=VkvQf7p3SkrXL5HS"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              />
            </Box>
          </Box>

          <Box display={"flex"} gap={1} flexWrap={"wrap"}>
            <BasicCard
              icon={"Assignment"}
              text={"Register and buy from any online website"}
              background={grey[100]}
              cardWidth={isSmalldesktop ? "100%" : 232}
              iconColor={blue[900]}
            />
            <BasicCard
              icon={"LocalShipping"}
              text={"Have your purchase delivered to our address"}
              background={grey[100]}
              cardWidth={isSmalldesktop ? "100%" : 232}
              iconColor={teal[500]}
            />
            <BasicCard
              icon={"CardGiftcard"}
              text={"We consolidate and store your purchase at no cost"}
              background={grey[100]}
              cardWidth={isSmalldesktop ? "100%" : 232}
              iconColor={red[500]}
            />
            <BasicCard
              icon={"FlightTakeoff"}
              text={"We ship to your address at the lowest prices"}
              background={grey[100]}
              cardWidth={isSmalldesktop ? "100%" : 232}
              iconColor={cyan[300]}
            />
          </Box>
        </Box>
      </Container>
      <Box sx={{ background: grey[200], width: "100%", pb: 5 }}>
        <Container maxWidth="lg">
          <Box width={"100%"} mt={5} mb={5}>
            <Typography
              fontSize={40}
              fontWeight={500}
              textAlign={"center"}
              pt={3}
            >
              Price Simulation
            </Typography>
            <Divider sx={{ mt: 2, bgcolor: "white" }} />
          </Box>

          <ShippingFeeSimulator />
        </Container>
      </Box>

      {/* <Box sx={{ background: grey[900], width: "100%", pb: 5 }}></Box> */}
    </FadeInContainer>
  );
}
