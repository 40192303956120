import { useFormik } from "formik";
import { NewUserBox } from "../../components/AtomicDesign/Organisms";
import * as yup from "yup";
import { createUserService } from "../../services/createUserService";

import useToast from "../../hooks/context/useToast";
import { Fade } from "@mui/material";
import useAuth from "../../hooks/context/useAuth";
import { useTranslate } from "../../hooks/useTranslate";
import { useNavigate } from "react-router-dom";

export function NewUser() {
  const { language } = useAuth();
  const { strings } = useTranslate();
  const navigate = useNavigate();

  const { setOpen, setToastData } = useToast();

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(strings.validationFields.name.required[language]),
    phoneCode: yup
      .string()
      .required(strings.validationFields.phoneCode.required[language]),
    phone: yup
      .number()
      .typeError(strings.validationFields.phoneCode.typeError[language])
      .required(strings.validationFields.phoneCode.required[language]),
    email: yup
      .string()
      .email(strings.validationFields.email[language])
      .required(strings.validationFields.email.required[language]),
    password: yup
      .string()
      .required(strings.validationFields.password[language]),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phoneCode: "",
      phone: "",
      ssn: "",
      email: "",
      password: "",
    },
    validationSchema,
    async onSubmit(values: any) {
      try {
        const formatPhone = `${values?.phoneCode}${values?.phone}`;
        await createUserService({ ...values, phone: formatPhone });

        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "success",
          message: strings.toastMessages.welcomeNew[language],
        });
        setOpen(true);
        navigate("/login");
      } catch (e) {
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "error",
          message: strings.toastMessages.emailAlreadyExists[language],
        });
        setOpen(true);
      }
    },
  });

  return (
    <Fade in={true} style={{ transformOrigin: "0 0 0" }} timeout={1000}>
      <div>
        <NewUserBox formik={formik} />
      </div>
    </Fade>
  );
}
