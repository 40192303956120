import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { RoutesTreasureBox } from "./routes";
import { AuthProvider } from "./hooks/context/useAuth";
import { Toast } from "./components/AtomicDesign/Organisms";
import { ToastProvider } from "./hooks/context/useToast";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <ToastProvider>
          <RoutesTreasureBox />
          <Toast />
        </ToastProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
