import { Box } from "@mui/material";
import { FadeInContainer } from "../../../components/AtomicDesign/Atoms";
import {
  AddressBox,
  BoxWithTitle,
} from "../../../components/AtomicDesign/Molecules";
import {
  CustomModal,
  EditCreateAddress,
  UpdateMyUser,
} from "../../../components/AtomicDesign/Organisms";
import useAuth from "../../../hooks/context/useAuth";
import {
  createAddress,
  deleteAddress,
  editAddress,
  getUserAddress,
} from "../../../services/addressService";
import { useEffect, useState } from "react";
import useToast from "../../../hooks/context/useToast";
import { useBreakpoints } from "../../../hooks/useBreakpoins";
import { useTranslate } from "../../../hooks/useTranslate";

export function ClientMyInfo() {
  const { user, language } = useAuth();
  const { setOpen, setToastData } = useToast();
  const { isSmalldesktop } = useBreakpoints();
  const { strings } = useTranslate();
  const [modalOpen, setModalOpen] = useState(false);
  const [address, setAddress] = useState<IAddress[]>();
  const [loading, setLoading] = useState(false);
  const [loadingNewAd, setLoadingNewAd] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<IAddress | null>();

  const closeModal = () => {
    setModalOpen(false);
    setSelectedAddress(null);
  };

  const myAddress = async (id: number) => {
    setLoading(true);
    try {
      const myAddress = await getUserAddress(id);
      setAddress(myAddress);
    } catch (err) {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 3000,
        type: "error",
        message: strings.toastMessages.errorAddress[language],
      });
      setOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const localUser = localStorage.getItem("@treasurebox_user") as any;
    if (localUser) {
      const parseID = JSON.parse(localUser).user.id;

      myAddress(parseID);
    }
    // eslint-disable-next-line
  }, []);

  const createEditAddress = async (val: EditCreateAddress) => {
    setLoadingNewAd(true);
    if (val.type === "create") {
      try {
        await createAddress(val);
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "success",
          message: strings.toastMessages.successCreateAddress[language],
        });
        setOpen(true);
        await myAddress(user?.user?.id);
        closeModal();
      } catch (err) {
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "error",
          message: strings.toastMessages.errorCreateAddress[language],
        });
        setOpen(true);
      } finally {
        setLoadingNewAd(false);
      }
    } else {
      try {
        await editAddress(val);
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "success",
          message: strings.toastMessages.successUpdateAddress[language],
        });
        setOpen(true);
        await myAddress(user?.user?.id);
        closeModal();
      } catch (err) {
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "error",
          message: strings.toastMessages.errorUpdateAddress[language],
        });
        setOpen(true);
      } finally {
        setLoadingNewAd(false);
      }
    }
  };

  const deleteMyAddress = async (addressID: number) => {
    try {
      await deleteAddress(addressID);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: strings.toastMessages.successDeleteAddress[language],
      });
      setOpen(true);
      await myAddress(user?.user?.id);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.errorDeleteAddress[language],
      });
      setOpen(true);
    }
  };

  return (
    <FadeInContainer>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        alignContent={"center"}
        gap={5}
        p={1}
      >
        <BoxWithTitle
          title={strings.myInfoPage.personalData[language]}
          props={{
            width: isSmalldesktop ? "100%" : "35%",
            minWidth: isSmalldesktop ? "auto" : "450px",
          }}
        >
          <UpdateMyUser />
        </BoxWithTitle>
        <BoxWithTitle
          title={strings.myInfoPage.myAddress[language]}
          width={isSmalldesktop ? "100%" : "55%"}
          props={{ minHeight: "100%" }}
          childrenProps={{ style: { overflowY: "auto" } }}
          hasButton
          onClick={() => setModalOpen(true)}
          buttonTitle={strings.myInfoPage.addressBTN[language]}
        >
          <AddressBox
            loading={loading}
            data={address}
            onClickEditAddress={(val) => {
              setSelectedAddress(val);
              setModalOpen(true);
            }}
            onClickDeleteAddress={(addressID) => deleteMyAddress(addressID)}
          />
        </BoxWithTitle>
      </Box>

      <CustomModal
        openModal={modalOpen}
        setOpenModal={closeModal}
        width={isSmalldesktop ? "90%" : "50%"}
      >
        <EditCreateAddress
          submitLoading={loadingNewAd}
          address={selectedAddress}
          handleSubmit={(val) => createEditAddress(val)}
          thisUserAlreadyHaveAddress={
            address && address?.length > 0 ? true : false
          }
        />
      </CustomModal>
    </FadeInContainer>
  );
}
