import { api } from "./api";

export const loginApi = async (email: string, password: string) => {
  // const response: LoginProps = await new Promise((resolve, reject) => {
  //   setTimeout(async () => {
  //     try {
  //       resolve(await { token: tkn, data: { user } });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   }, 2000);
  // });
  const { data } = await api.post("/login", { email, password });
  return data;
};

export const forgotPasswordApi = async (email: string) => {
  const { data } = await api.post("/recover-password", { email });
  return data;
};

export const getUserApi = async (userID: number) => {
  const { data } = await api.get("/user-info", { params: { userID } });
  return data;
};

export const verifyCodeForgotPasswordApi = async (
  email: string,
  code: string
) => {
  const { data } = await api.post("/verify-code", { email, code });
  return data;
};

export const changePasswordApi = async (
  email: string,
  code: string,
  newPassword: string
) => {
  const { data } = await api.post("/change-password", {
    email,
    code,
    newPassword,
  });
  return data;
};
