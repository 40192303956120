import { api } from "./api";

export const getFretesList = async () => {
  const response2 = await api.get("/fretes-list");
  return response2.data;
};

export const getOneFrete = async () => {
  const response2 = await api.get("/one-frete");
  return response2.data;
};

export const getFreteByZone = async () => {
  const response2 = await api.get("/frete-by-zone");
  return response2.data;
};

export const updateFrete = async (data: any) => {
  const response2 = await api.post("/update-frete", data);
  return response2.data;
};

export const createFrete = async (data: CreateFrete) => {
  const response2 = await api.post("/create-frete", { data });
  return response2.data;
};

export const deleteFrete = async (data: DeleteFrete) => {
  const response2 = await api.delete("/delete-frete", {
    params: { ...data },
  });
  return response2.data;
};

export const getMyTaxes = async () => {
  const response2 = await api.get("/my-services");
  return response2.data;
};

export const updateMyTaxes = async (data: any) => {
  const response2 = await api.post("/my-services", data);
  return response2.data;
};

export const createNewService = async (data: CreateNewService) => {
  const response2 = await api.post("/my-services-create", { data });
  return response2.data;
};

export const deleteService = async (data: DeleteMyService) => {
  const response2 = await api.delete("/my-services-delete", {
    params: { ...data },
  });
  return response2.data;
};
