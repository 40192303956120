import {
  ChargeCardCredits,
  ConfirmPayCredits,
  CreateCreditIntance,
  UpdateCreditInstance,
  UpdateFileCreditIntance,
} from "../types/credits";
import { api } from "./api";

export const userOneCredit = async (creditID: number) => {
  const response2 = await api.get("/one-credit-instance", {
    params: { creditID },
  });
  return response2.data;
};

export const usersCreditList = async (filter: {
  userID: number;
  searchKey: string;
  searchTerm: string;
  itensPerPage: number;
  offset: number;
}) => {
  const response2 = await api.get("/credits-list", { params: { ...filter } });
  return response2.data;
};

export const createCreditInstance = async (data: CreateCreditIntance) => {
  try {
    if (data?.paymentChoice === "card") {
      const creditID = await api.post("/create-credits-instance-card", {
        userID: data.userID,
        creditValue: data.creditValue,
        paymentChoice: data.paymentChoice,
      });
      return creditID?.data?.id;
    } else {
      const creditID = await api.post(
        "/create-credits-instance",
        {
          file: data.files[0],
          userID: data.userID,
          creditValue: data.creditValue,
          paymentChoice: data.paymentChoice,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return creditID?.data?.id;
    }
  } catch (err: any) {
    throw new Error(err);
  }
};

export const updateFilesCreditInstance = async (
  data: UpdateFileCreditIntance
) => {
  try {
    await api.post(
      "/update-file-credits-instance",
      {
        file: data.files[0],
        userID: data.userID,
        creditID: data.creditID,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (err: any) {
    throw new Error(err);
  }
};

export const userUpdateCredit = async (data: UpdateCreditInstance) => {
  const { creditID, creditValue, status, userID } = data;
  const response2 = await api.post("/update-credits-instance", {
    creditID,
    creditValue,
    status,
    userID,
  });
  return response2.data;
};

export const deleteCreditProof = async (creditID: number) => {
  const response2 = await api.delete("/delete-credit-proof", {
    params: { creditID },
  });
  return response2.data;
};

export const chargeCreditCardCreditsService = async (
  data: ChargeCardCredits
) => {
  const response2 = await api.post("/charge-credits", {
    ...data,
  });
  return response2.data;
};

export const updatePaymentCreditsService = async (data: ConfirmPayCredits) => {
  const response2 = await api.post("/update-payment-credits", {
    ...data,
  });
  return response2.data;
};
