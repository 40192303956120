import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import MagicUrl from "quill-magic-url";

Quill.register("modules/magicUrl", MagicUrl);

interface Props {
  onChange(val: string): void;
  value: string;
  disabled?: boolean;
}

export function HtmlTextEditor({ onChange, value, disabled = false }: Props) {
  return (
    <ReactQuill
      style={{ background: "white", width: "100%", height: 200 }}
      value={value}
      onChange={onChange}
      readOnly={disabled}
      modules={{ magicUrl: true }}
    />
  );
}
