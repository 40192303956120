import { useFormik } from "formik";
import { LoginBox } from "../../components/AtomicDesign/Organisms/LoginBox";
import useAuth from "../../hooks/context/useAuth";
import * as yup from "yup";
import useToast from "../../hooks/context/useToast";
import { Fade } from "@mui/material";
import { useTranslate } from "../../hooks/useTranslate";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export function Login() {
  const { strings } = useTranslate();
  const { authenticate, loginError, language, user, token } = useAuth();
  const { setOpen, setToastData } = useToast();

  const navigate = useNavigate();

  useEffect(() => {
    if (user && token) {
      if (user.user.role === "admin") {
        navigate("/treasurebox-app/admin");
      } else {
        navigate("/treasurebox-app");
      }
    }
    // eslint-disable-next-line
  }, []);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(strings.validationFields.email[language])
      .required(strings.validationFields.email.required[language]),
    password: yup
      .string()
      .required(strings.validationFields.password[language]),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    async onSubmit(values: ILoginFormValues) {
      try {
        await authenticate(values.email, values.password);
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "success",
          message: strings.toastMessages.welcome[language],
        });
        setOpen(true);
      } catch (e: any) {
        const message =
          e?.message === "blocked"
            ? strings.toastMessages.userBlocked[language]
            : strings.toastMessages.loginError[language];
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 10000,
          type: "error",
          message,
        });
        setOpen(true);
      }
    },
  });

  return (
    <Fade in={true} style={{ transformOrigin: "0 0 0" }} timeout={1000}>
      <div>
        <LoginBox formik={formik} error={loginError} />
      </div>
    </Fade>
  );
}
