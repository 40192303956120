import { Box, Tooltip, Typography } from "@mui/material";
import { MailNotification } from "../../Atoms";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";

interface Props {
  hasNewMessage: boolean;
  openModal: VoidFunction;
  tooltipOpen: boolean;
  text: string;
  chatButton?: boolean;
}

export function MessageIcon({
  hasNewMessage,
  openModal,
  tooltipOpen,
  text,
  chatButton = false,
}: Props) {
  const { isMobile } = useBreakpoints();

  return (
    <Tooltip
      open={tooltipOpen}
      title={
        <Typography
          variant="h2"
          sx={{
            width: isMobile ? 100 : 200,
            wordBreak: "break-word",
          }}
        >
          {text}
        </Typography>
      }
      arrow
      placement="right"
    >
      <Box display={"flex"} alignItems={"center"}>
        <MailNotification
          hasNewMessage={hasNewMessage}
          onClick={openModal}
          chatButton={chatButton}
        />
      </Box>
    </Tooltip>
  );
}
