import { Box, BoxProps } from "@mui/material";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";

interface Props {
  children: React.ReactNode;
  config?: BoxProps;
}

export function BoxContent({ children, config }: Props) {
  const { isMobile } = useBreakpoints();
  return (
    <Box
      border="1px solid lightgray"
      borderRadius={5}
      p={isMobile ? 4 : 8}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      {...config}
    >
      {children}
    </Box>
  );
}
