export function usePhoneCode() {
  const convertPhone = (code?: string, phone?: string) => {
    if (!code || !phone) return "";
    const phoneWithoutCode = phone
      ?.split("")
      .map((number, index) => {
        const phoneCodeLength = code?.length - 1;
        if (index > phoneCodeLength) {
          return number;
        } else {
          return null;
        }
      })
      .join("");

    return phoneWithoutCode;
  };

  return { convertPhone };
}
