import { Box } from "@mui/material";
import { FadeInContainer } from "../../../components/AtomicDesign/Atoms";
import {
  BoxWithTitle,
  CreditCards,
} from "../../../components/AtomicDesign/Molecules";
import { CustomModal } from "../../../components/AtomicDesign/Organisms";
import { useState } from "react";
import { useBreakpoints } from "../../../hooks/useBreakpoins";

import { useTranslate } from "../../../hooks/useTranslate";
import useAuth from "../../../hooks/context/useAuth";
import useCreditsAdmin from "../../../hooks/context/useCreditsAdmin";
import { CreditBoxAdmin } from "../../../components/AtomicDesign/Organisms/CreditBoxAdmin";

export function CreditsAdmin() {
  const {
    cleanFilters,
    creditsDataMemo,
    setFilters,
    records,
    getFilterStorage,
    setAttData,
    updateCredits,
    handleIDCredit,
    setHandleIDCredit,
    setCredit,
    loading,
  } = useCreditsAdmin();
  const { strings } = useTranslate();
  const { language } = useAuth();
  const { isSmalldesktop } = useBreakpoints();
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setCredit({} as any);
    setModalOpen(false);
    setHandleIDCredit(null);
  };

  return (
    <FadeInContainer>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        alignContent={"center"}
        gap={5}
        p={1}
      >
        <BoxWithTitle
          title={strings.creditsPage.title[language]}
          width="100%"
          childrenProps={{ height: "100%" }}
          padding={1}
          // filter
          hasFilter
          onSelectField={(val) => setFilters("key", val)}
          selectedField={getFilterStorage().key}
          onChangeTextField={(val) => {
            setFilters("searchTerm", val);
            setAttData((bool: boolean) => !bool);
          }}
          searchTerm={getFilterStorage().searchTerm}
          onClickCleanFilter={cleanFilters}
          // filter
          // pagnation
          hasPagination
          itensPerpage={(val) => {
            setFilters("itensPerPage", val);
            setAttData((bool: boolean) => !bool);
          }}
          itensPerPage={getFilterStorage().itensPerPage} // quantidade
          howManyPages={Math.ceil(records / getFilterStorage().itensPerPage)}
          onChangePage={(page) => {
            setFilters("offset", page - 1);
            setAttData((bool: boolean) => !bool);
          }}
          // pagnation
        >
          <CreditCards
            data={creditsDataMemo}
            onClickCredit={(cred) => {
              setHandleIDCredit(cred.id);
              setModalOpen(true);
            }}
          />
        </BoxWithTitle>
      </Box>
      <CustomModal
        openModal={modalOpen}
        setOpenModal={closeModal}
        width={isSmalldesktop ? "90%" : "40%"}
      >
        <CreditBoxAdmin
          handleSubmit={(val) => updateCredits(val)}
          creditID={handleIDCredit}
          isLoading={loading.oneCredit}
        />
      </CustomModal>
    </FadeInContainer>
  );
}
