import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";

type ListProps = {
  name: string;
  id: number;
  imgUrl: string;
  qtd: number;
  kg: number;
  stock: number;
  packageRemove: boolean;
  adminRemovePackage: any;
  arrivedAt?: any;
};

interface Props {
  list: ListProps[];
  itemsSelected: ListProps[];
  oldProducts?: ListProps[];
  btnName: string;
  handleClickItem(item: ListProps): void;
  removeItemFromSelected(item: ListProps): void;
}

export function BasicPopover({
  list,
  btnName,
  handleClickItem,
  removeItemFromSelected,
  itemsSelected,
  oldProducts,
}: Props) {
  const { isMobile } = useBreakpoints();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const showDeleteBtn = (id: number) => {
    if (oldProducts && oldProducts?.map((item) => item?.id).includes(id))
      return true;
    if (itemsSelected?.map((item) => item?.id).includes(id)) return true;

    return false;
  };

  return (
    <div style={{ width: "100%", marginBottom: 15 }}>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        fullWidth
      >
        {btnName}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            minWidth: "100%",

            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            p: 3,
          }}
        >
          {list?.map((item, index) => (
            <Box key={index} width={isMobile ? "85%" : "auto"}>
              <Button
                onClick={() => handleClickItem(item)}
                variant="outlined"
                disabled={showDeleteBtn(item?.id)}
                fullWidth
                sx={{
                  width: isMobile ? "100%" : 200,
                  height: 80,
                  display: "flex",
                  bgcolor: showDeleteBtn(item?.id)
                    ? "lightgreen"
                    : "transparent",
                }}
              >
                <img
                  src={item?.imgUrl}
                  alt={item?.name}
                  style={{
                    objectFit: "contain",
                    height: "100%",
                    width: 50,
                    borderRadius: 5,
                    marginRight: 2,
                  }}
                />
                <Typography width={"100%"}>{item?.name}</Typography>
              </Button>
              {itemsSelected?.map((item) => item?.id).includes(item?.id) && (
                <IconButton
                  onClick={() => removeItemFromSelected(item)}
                  aria-label="delete"
                  sx={{
                    position: "absolute",
                    bgcolor: "red",
                    marginTop: "-90px",
                    marginLeft: isMobile ? "78%" : "180px",
                    zIndex: 12651651651561561,
                    "&:hover": { bgcolor: "pink" },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          ))}
        </Box>
      </Popover>
    </div>
  );
}
