import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { GenericInput, GenericSelect } from "../../../Molecules";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { useJPYmoney } from "../../../../../hooks/useJPYmoney";
import { useBreakpoints } from "../../../../../hooks/useBreakpoins";
import EditIcon from "@mui/icons-material/Edit";
import { CustomModal } from "../../Modal";
import { useCommonData } from "../../../../../hooks/useCommonData";
import { green, red } from "@mui/material/colors";

interface Props {
  freteList: Fretes[];
  selectedTab: number;
  updateFrete(values: UpdateFrete, index: number): void;
  createFrete(data: CreateFrete): void;
  delFrete(data: DeleteFrete, index: number): void;
  loadingLine: { index: number; loading: boolean };
}

const zones = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
];

const freteTypes = {
  0: "smallPacket",
  1: "ems",
  2: "surface",
  3: "airParcel",
};

export function FretesConfig({
  freteList,
  selectedTab,
  updateFrete,
  createFrete,
  delFrete,
  loadingLine,
}: Props) {
  const { adminOrder } = useCommonData();
  const { isSmalldesktop, isMobile } = useBreakpoints();
  const { JPYmoney } = useJPYmoney();
  const [zoneSelected, setZoneSelected] = useState(5);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    weight: 0,
    price: 0,
    freteID: 0,
    index: 0,
  });
  const [newFrete, setNewFrete] = useState({
    weight: 0,
    price: 0,
  });
  const tabs = {
    0: "SmallPacket",
    1: "EMS",
    2: "Surface",
    3: "AirParcel",
  };

  const labels = {
    0: "Small Packet",
    1: "EMS",
    2: "Sal Ship Parcel",
    3: "Sal Air Parcel",
  };

  const selectedTabs = tabs[selectedTab as keyof typeof tabs];
  const selectedFrete = freteTypes[
    selectedTab as keyof typeof freteTypes
  ] as any;

  const filterByZone = () => {
    if (zoneSelected > 0) {
      const filterData = freteList?.filter(
        (frete) => frete?.zone === zoneSelected
      );
      return filterData[0][selectedTabs as keyof (typeof filterData)[0]] as {
        id: number;
        price: number;
        weight: number;
        globalZoneId: number;
      }[];
    }
    return [] as {
      id: number;
      price: number;
      weight: number;
      globalZoneId: number;
    }[];
  };

  const confirmFreteEdit = () => {
    const payload = {
      freteType: selectedFrete,
      freteID: values.freteID,
      weight: values.weight,
      price: values.price,
    };
    updateFrete(payload, values.index);
    setOpen(false);
  };

  const submit = async () => {
    const payload = {
      weight: newFrete.weight,
      price: newFrete.price,
      freteType: selectedFrete,
      globalZoneId: zoneSelected,
    };
    await createFrete(payload);

    setNewFrete({ price: 0, weight: 0 });
  };

  const deleteFrete = (freteID: number, index: number) => {
    const payload = {
      freteType: selectedFrete,
      freteID,
    };

    delFrete(payload, index);
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
    >
      <GenericSelect
        onChange={(value) => setZoneSelected(Number(value))}
        name="select-zone"
        options={zones}
        placeholder="Select zone"
        value={String(zoneSelected)}
        width={isSmalldesktop ? "100%" : "20%"}
      />
      {freteList?.length > 0 && (
        <Box
          p={2}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          width={"100%"}
        >
          {filterByZone()
            .sort((a, b) => (a.weight > b.weight ? 1 : -1))
            ?.map((freteOption, index) => (
              <Box
                key={index}
                width={isSmalldesktop ? "100%" : "50%"}
                mt={1}
                mb={2}
              >
                <Box
                  display="flex"
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                >
                  <Box display="flex" justifyContent={"space-between"}>
                    <Typography>Peso</Typography>
                    <Typography>Preço</Typography>
                    <Box display="flex">
                      <Typography mr={1}>Editar</Typography>
                      <Typography>Deletar</Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography>
                      {freteOption?.weight.toLocaleString()} g
                    </Typography>
                    <Typography>{JPYmoney(freteOption?.price)}</Typography>
                    {loadingLine.loading && loadingLine.index === index ? (
                      <CircularProgress size={40} />
                    ) : (
                      <Box display="flex">
                        <IconButton
                          onClick={() => {
                            setValues({
                              price: freteOption?.price,
                              weight: freteOption?.weight,
                              freteID: freteOption?.id,
                              index,
                            });
                            setOpen(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => deleteFrete(freteOption?.id, index)}
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Divider sx={{ mt: 1.5 }} />
              </Box>
            ))}
        </Box>
      )}

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            border: "1px solid lightgray",
            borderRadius: 5,
            width: isSmalldesktop ? "100%" : "50%",
            mt: 1,
            p: 2,
          }}
        >
          <Box display="flex">
            <Typography variant="h1" mt={2} mr={0.5}>
              Novo frete para {labels[selectedTab as keyof typeof labels]} -
            </Typography>
            <Typography variant="h1" mt={2}>
              Zona selecionada: {zoneSelected}
            </Typography>
          </Box>
          <GenericInput
            inputName={"weight"}
            onChange={(value) =>
              setNewFrete((old) => ({ ...old, weight: Number(value) }))
            }
            value={newFrete.weight}
            defaultValue={newFrete.weight}
            placeholder={"Peso em gramas"}
            hasIcon={false}
          />
          <GenericInput
            inputName={"price"}
            onChange={(value) =>
              setNewFrete((old) => ({ ...old, price: Number(value) }))
            }
            value={newFrete.price}
            defaultValue={newFrete.price}
            placeholder={"Taxa em ienes"}
            hasIcon={false}
          />
          <Button
            variant="contained"
            onClick={submit}
            sx={{ mt: 1, width: isMobile ? "100%" : "auto" }}
          >
            Adicionar
          </Button>
        </Box>
      </Box>

      <CustomModal setOpenModal={setOpen} openModal={open} title="Editar frete">
        <Box width={300} p={2}>
          <GenericInput
            inputName="weight"
            onChange={(value, name) =>
              setValues((old) => ({ ...old, [name]: Number(value) }))
            }
            placeholder="Weight"
            value={values.weight}
            hasIcon={false}
          />

          <GenericInput
            inputName="price"
            onChange={(value, name) =>
              setValues((old) => ({ ...old, [name]: Number(value) }))
            }
            placeholder="Price"
            value={values.price}
            hasIcon={false}
          />
        </Box>
        <Box display={"flex"} justifyContent={"center"} gap={5} mb={2}>
          <Button
            sx={{ bgcolor: green[500], color: "white" }}
            style={{ width: 100 }}
            onClick={confirmFreteEdit}
          >
            {adminOrder.confirmBtn}
          </Button>
          <Button
            sx={{ bgcolor: red[500], color: "white" }}
            style={{ width: 100 }}
            onClick={() => setOpen(false)}
          >
            {adminOrder.cancelBtn}
          </Button>
        </Box>
      </CustomModal>
    </Box>
  );
}
