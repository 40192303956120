import { Box, Link, Typography } from "@mui/material";
import { EmailField } from "../../Molecules";
import { PasswordField } from "../../Molecules";
import { Button } from "../../Atoms";

import NEWTREASUREBOXLOGO from "../../../../assets/images/NEWTREASUREBOXLOGO2.png";
import { useNavigate } from "react-router-dom";
import { BoxContent } from "../../Atoms";
import { FormikProps } from "formik";
import { useTranslate } from "../../../../hooks/useTranslate";
import useAuth from "../../../../hooks/context/useAuth";
import { useEffect } from "react";

interface Props {
  error: boolean;
  formik: FormikProps<ILoginFormValues>;
}

export function LoginBox({ error, formik }: Props) {
  const { strings } = useTranslate();
  const { language } = useAuth();
  const { forgotPassword, noAccount, registerFree } = strings.login;
  const navigate = useNavigate();

  const handleLogin = () => {
    formik.handleSubmit();
  };

  useEffect(() => {
    const getPasswordField = document.getElementById(
      "standard-adornment-password"
    );

    getPasswordField?.addEventListener("keypress", (e) => {
      if (e.key === "Enter") {
        document?.getElementById("login-btn")?.click();
      }
    });
  }, []);

  return (
    <Box>
      <BoxContent>
        <img
          style={{ marginTop: -30, marginBottom: -5 }}
          src={NEWTREASUREBOXLOGO}
          width={150}
          alt="treasurebox logo"
        />

        <EmailField
          onChange={(val) => formik.setFieldValue("email", val.trim())}
          value={formik.values.email}
          error={formik.touched.email && formik.errors.email ? true : false}
          errorMessage={formik.touched.email ? formik.errors.email : ""}
          placeholder="E-mail"
        />
        <PasswordField
          onChange={(val) => formik.setFieldValue("password", val.trim())}
          value={formik.values.password}
          error={
            formik.touched.password && formik.errors.password ? true : false
          }
          errorMessage={formik.touched.password ? formik.errors.password : ""}
          placeholder="Password"
        />
        <Box mt={3} width={"100%"}>
          <Button
            config={{
              variant: "contained",
              onClick: handleLogin,
              id: "login-btn",
            }}
          >
            {strings.buttons.signInbtn[language]}
          </Button>

          <Button
            config={{
              variant: "text",
              onClick: () => navigate("/login/forgot-password"),
              sx: { bgcolor: "transparent" },
            }}
            style={{ marginTop: 12 }}
          >
            {forgotPassword[language]}
          </Button>
        </Box>
      </BoxContent>
      <Box display={"flex"} mt={2} justifyContent={"center"}>
        <Typography>{noAccount[language]} &nbsp;</Typography>
        <Link
          component="button"
          onClick={() => navigate("/login/new-user")}
          underline="none"
          fontFamily={"Roboto"}
        >
          {registerFree[language]}
        </Link>
      </Box>
    </Box>
  );
}
