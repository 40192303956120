import { Box, Typography } from "@mui/material";
import { PasswordField } from "../../Molecules";
import { Button } from "../../Atoms";
import treasureBoxLogo from "../../../../assets/images/NEWTREASUREBOXLOGO2.png";
import { useNavigate } from "react-router-dom";
import { BoxContent } from "../../Atoms";
import { FormikProps } from "formik";
import { useTranslate } from "../../../../hooks/useTranslate";
import useAuth from "../../../../hooks/context/useAuth";

interface Props {
  error: boolean;
  formik: FormikProps<IForgotPasswordFormValues>;
}

export function ChangePasswordBox({ error, formik }: Props) {
  const { strings } = useTranslate();
  const { language } = useAuth();
  const navigate = useNavigate();

  const handle = () => {
    formik.handleSubmit();
  };

  return (
    <Box>
      <BoxContent>
        <img
          style={{ marginTop: -15, marginBottom: 20 }}
          src={treasureBoxLogo}
          width={150}
          alt="treasurebox logo"
        />
        <Typography mb={2}>
          {
            strings.forgotPassword.changePasswordStep.registerNewPassword[
              language
            ]
          }
        </Typography>

        <PasswordField
          onChange={(val) => formik.setFieldValue("newPassword", val)}
          value={formik.values.newPassword}
          error={false}
          errorMessage={""}
          placeholder="Password"
        />

        <Box mt={3} width={"100%"}>
          <Button config={{ variant: "contained", onClick: handle }}>
            {strings.buttons.changePassword[language]}
          </Button>

          <Button
            config={{
              variant: "text",
              onClick: () => navigate("/"),
              sx: { bgcolor: "transparent" },
            }}
            style={{ marginTop: 12 }}
          >
            {strings.buttons.cancelBtn[language]}
          </Button>
        </Box>
      </BoxContent>
    </Box>
  );
}
