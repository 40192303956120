import { Box, Paper } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import useAuth from "../../../hooks/context/useAuth";
import { colors } from "../../../theme/colors";
import { useEffect, useState } from "react";
import { useBreakpoints } from "../../../hooks/useBreakpoins";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
// import { WarningMessageYellow } from "../../AtomicDesign/Atoms";

export function AppLayout() {
  const { isSmalldesktop } = useBreakpoints();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const route = useLocation();

  const navigate = useNavigate();
  const { getUserLocalStorage, token, getUser } = useAuth();
  const { getFilterStorage } = useLocalStorage();
  const { user } = getUserLocalStorage();

  const maintenance = process.env.REACT_APP_MAINTENANCE === "ON";

  if (maintenance) {
    navigate("/maintenance");
  }

  if (!token || user.user.role !== "client") {
    navigate("/");
  }

  const getUserAtt = async () => {
    getUser(user?.user?.id);
  };

  useEffect(() => {
    if (user?.user?.id) {
      getUserAtt();
      getFilterStorage(true);
    }
    // eslint-disable-next-line
  }, [route]);

  return (
    <Box
      bgcolor={colors.gray[100]}
      sx={{ overflowX: "hidden" }}
      height={"100vh"}
    >
      <Paper elevation={2} sx={{ borderRadius: 0 }}>
        <Header drawerStatus={setDrawerOpen} />
      </Paper>
      {/* <WarningMessageYellow message="O pagamento por cartão de crédito está temporariamente inativo, em breve retornaremos a normalidade" /> */}
      <Box
        mt={5}
        marginLeft={drawerOpen && !isSmalldesktop ? "240px" : "0"}
        width={"100%"}
        display={"flex"}
        justifyContent={drawerOpen ? "center" : "flex-start"}
      >
        <Box
          maxWidth={"1920px"}
          width={"100%"}
          marginRight={drawerOpen && !isSmalldesktop ? "240px" : "0"}
        >
          <Outlet />
        </Box>
      </Box>
      {/* <Container maxWidth="lg"></Container> */}
    </Box>
  );
}
