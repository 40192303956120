import { Box, Tab, Tabs } from "@mui/material";
import { amber, blue, teal } from "@mui/material/colors";
import { useState } from "react";

import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useBreakpoints } from "../../../../../hooks/useBreakpoins";

interface Props {
  list: ShippmentProft[];
}

export function ChartGraphByShippmentsProfit({ list }: Props) {
  const { isMobile } = useBreakpoints();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const filterYear = () => {
    const filterByYear = list.find((item) => item.year === selectedYear);
    if (filterByYear) {
      return filterByYear.months as unknown as ShippmentProft[];
    } else {
      return [] as ShippmentProft[];
    }
  };
  return (
    <Box>
      <Tabs
        value={selectedYear}
        onChange={(_e, value) => setSelectedYear(value)}
        aria-label="disabled tabs example"
        centered
        variant={"scrollable"}
        scrollButtons
        allowScrollButtonsMobile
        sx={{ mb: 1 }}
      >
        {list.map((item) => (
          <Tab label={item.year} value={item.year} />
        ))}
      </Tabs>

      <Box sx={{ width: "100%", height: "500px" }}>
        <Box sx={{ width: "100%", height: "100%" }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={filterYear()}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="ExtraServices"
                name="Extra services"
                fill={teal[500]}
                activeBar={<Rectangle fill="pink" stroke="blue" />}
                stackId={isMobile ? "a" : "a"}
              />
              <Bar
                dataKey="Stock"
                fill={amber[500]}
                activeBar={<Rectangle fill="pink" stroke="blue" />}
                stackId={isMobile ? "a" : "b"}
              />
              <Bar
                dataKey="Delivery"
                fill={blue[500]}
                activeBar={<Rectangle fill="pink" stroke="blue" />}
                stackId={isMobile ? "a" : "c"}
              />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </Box>
  );
}
