import axios from "axios";

export const api = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

api.interceptors.request.use(
  (config) => {
    const tkn = localStorage.getItem("@treasurebox_token") as any;
    if (tkn) {
      const parse = JSON.parse(tkn);
      config.headers["token"] = parse;
    }
    return config;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("@treasurebox_user");
      localStorage.removeItem("@treasurebox_token");
      window.location.replace("/");
    }
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/login/new-user"
    ) {
      localStorage.removeItem("@treasurebox_user");
      localStorage.removeItem("@treasurebox_token");
      localStorage.removeItem("@treasureBox-menuOption");
      window.location.replace("/login");
    }
    return Promise.reject(error);
  }
);
