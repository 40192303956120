import {
  Backdrop,
  Box,
  Modal,
  Fade,
  IconButton,
  Typography,
} from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { colors } from "../../../../theme/colors";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid lightgray",
  borderRadius: 2,
  boxShadow: 24,
  p: 1,
};

interface Props {
  openModal: boolean;
  setOpenModal(val: boolean): void;
  children: React.ReactNode | React.ReactNode[];
  width?: string | number;
  title?: string;
  turnBack?: VoidFunction | undefined;
  disableBackdropClick?: boolean;
}

export function CustomModal({
  disableBackdropClick = false,
  openModal,
  setOpenModal,
  children,
  width,
  title,
  turnBack,
}: Props) {
  const handleClose = (_event: any, reason: any) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      if (disableBackdropClick) {
        return;
      } else {
        setOpenModal(false);
      }
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      sx={{ overflowY: "scroll" }}
    >
      <Fade in={openModal}>
        <Box
          sx={{
            ...style,
            width,
            maxHeight: "95%",
            overflowY: "auto",
            "&::-webkit-scrollbar": { width: 10 },
            "&::-webkit-scrollbar-track": {
              backgroundColor: colors.blue[900],
              borderRadius: 10,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: colors.blue[400],
              borderRadius: 5,
            },
          }}
        >
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={title || turnBack ? "space-between" : "flex-end"}
            alignItems={"center"}
          >
            {turnBack && (
              <IconButton
                aria-label="delete"
                onClick={turnBack}
                sx={{ borderRadius: 2, pr: 2, height: 30 }}
              >
                <ArrowBackIosNewIcon style={{ fontSize: 30 }} />
                Back
              </IconButton>
            )}
            {title && (
              <Typography variant="h1" margin={"auto"}>
                {title}
              </Typography>
            )}
            <IconButton aria-label="delete" onClick={() => setOpenModal(false)}>
              <HighlightOffOutlinedIcon style={{ fill: "red", fontSize: 30 }} />
            </IconButton>
          </Box>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
}
