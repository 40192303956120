import { Box, Typography } from "@mui/material";

export function NoData({ text }: { text: string }) {
  return (
    <Box
      width={"100%"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={100}
    >
      <Typography variant="h1">{text}</Typography>
    </Box>
  );
}
