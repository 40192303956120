import * as React from "react";
import useAuth from "../../../../hooks/context/useAuth";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";

export default function SelectLanguage() {
  const { isMobile } = useBreakpoints();
  const { changeLanguage, language } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const countryLanguage = [
    {
      lang: "portuguese",
      imageXL: "https://flagcdn.com/w40/br.png 2x",
      image: "https://flagcdn.com/w20/br.png",
      name: "Português",
    },
    {
      lang: "english",
      imageXL: "https://flagcdn.com/w40/us.png 2x",
      image: "https://flagcdn.com/w20/us.png",
      name: "English",
    },
  ];

  const findSelected = countryLanguage.find((lang) => lang.lang === language);

  return (
    <div>
      <Button
        style={{ width: !isMobile ? 150 : 30 }}
        aria-describedby={id}
        variant="text"
        onClick={handleClick}
      >
        <img
          loading="lazy"
          width="20"
          height="15"
          srcSet={findSelected?.imageXL}
          src={findSelected?.image}
          alt=""
        />
        {!isMobile && <Typography ml={1}>{findSelected?.name}</Typography>}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box p={1}>
          {countryLanguage.map((lang: any, index: number) => (
            <Box
              key={index}
              onClick={() => changeLanguage(lang.lang)}
              style={{
                background:
                  language === lang.lang ? "lightgray" : "transparent",
              }}
              borderRadius={1}
              display={"flex"}
              alignItems={"center"}
              mb={1}
              paddingRight={1}
              paddingLeft={1}
            >
              <img
                loading="lazy"
                width="20"
                height="15"
                srcSet={lang.imageXL}
                src={lang.image}
                alt=""
              />
              <Typography ml={1}>{lang.name}</Typography>
            </Box>
          ))}
        </Box>
      </Popover>
    </div>
  );
}
