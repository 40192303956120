import { Box, Typography } from "@mui/material";
import useAuth from "../../../hooks/context/useAuth";
import SelectLanguage from "../../AtomicDesign/Molecules/SelectLanguage";
import NEWTREASUREBOXLOGO from "../../../assets/images/NEWTREASUREBOXLOGO2.png";

import { MenuDrawer } from "../../AtomicDesign/Organisms";
import { useBreakpoints } from "../../../hooks/useBreakpoins";
import { useNavigate } from "react-router-dom";

export default function Header({
  drawerStatus,
}: {
  drawerStatus(val: boolean): void;
}) {
  const { isMobile } = useBreakpoints();
  const { user, token } = useAuth();
  const navigate = useNavigate();

  const redirect = () => {
    if (user && token) {
      if (user.user.role === "admin") {
        navigate("/treasurebox-app/admin");
      } else {
        navigate("/treasurebox-app");
      }
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p="10px 20px"
      // border="1px solid gray"
    >
      <Box
        display="flex"
        alignItems={"center"}
        id="fngweouingouiwrnoiugwrnoiugnwoiur"
      >
        <MenuDrawer drawerStatus={drawerStatus} />
        <img
          src={NEWTREASUREBOXLOGO}
          width={isMobile ? 100 : 140}
          height={isMobile ? 40 : "auto"}
          alt="treasurebox logo"
          onClick={redirect}
          style={{ marginBottom: -6, marginTop: -5 }}
        />
      </Box>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <SelectLanguage />
        <Box
          display={"flex"}
          flexWrap={"wrap"}
          justifyContent={"flex-end"}
          minWidth={"86px"}
          overflow={"hidden"}
        >
          <Typography
            style={{
              textAlign: "right",
              marginRight: 5,
              maxWidth: "86px",
              minWidth: "86px",
            }}
          >
            ¥ {user?.money === "" ? 0 : user?.money}
          </Typography>
          <Typography
            style={{
              textAlign: "right",
              wordBreak: "keep-all",
              maxWidth: "150px",
              minWidth: "150px",
              maxHeight: isMobile ? "28px" : "48px",
              overflow: "hidden",
              marginLeft: -15,
            }}
          >
            {user?.user?.name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
