import { api } from "./api";

export const createProductService = async (data: any) => {
  const { name, description, kg, itemsQuantity, arrivedAt, suiteId, file } =
    data;

  try {
    await api.post(
      "/create-product",
      {
        name,
        description,
        kg,
        itemsQuantity,
        arrivedAt,
        suiteId,
        file,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (err: any) {
    throw new Error(err);
  }
};

export const getProductsListService = async (
  userID: number,
  suiteID: number,
  filter: {
    key: string;
    searchTerm: string;
    itensPerPage: number;
    offset: number;
  }
) => {
  const response2 = await api.get("/products-list", {
    params: {
      userID,
      searchKey: filter.key,
      searchTerm: filter.searchTerm,
      itensPerPage: filter.itensPerPage,
      offset: filter.offset,
      suiteID,
    },
  });
  return response2.data;
};

export const getOneProductService = async (productID: number) => {
  const response2 = await api.get("/one-product", {
    params: {
      productID,
    },
  });
  return response2.data;
};

export const removeImageProductService = async (imageID: number) => {
  const response2 = await api.delete("/delete-image-product", {
    params: {
      imageID,
    },
  });
  return response2.data;
};

export const updateProductService = async (data: any) => {
  const {
    name,
    description,
    kg,
    itemsQuantity,
    arrivedAt,
    suiteId,
    productID,
  } = data;

  const response2 = await api.post("/update-product", {
    data: {
      name,
      description,
      kg,
      itemsQuantity,
      arrivedAt,
      suiteId,
      productID,
    },
  });
  return response2.data;
};

export const solicitationService = async (data: any, solicitationType: any) => {
  const { suiteId, productID } = data;

  const response2 = await api.post("/product-solicitation", {
    data: {
      suiteId,
      productID,
      solicitationType,
    },
  });
  return response2.data;
};

export const solicitationAdminService = async (
  productID: number,
  solicitationType: any
) => {
  const response2 = await api.post("/admin-product-solicitation", {
    data: {
      productID,
      solicitationType,
    },
  });
  return response2.data;
};

export const updateProductImages = async (data: any) => {
  const { productID, file } = data;

  try {
    await api.post(
      "/update-product-images",
      {
        productID,
        file,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (err: any) {
    throw new Error(err);
  }
};

export const deleteProductService = async (id: number) => {
  try {
    await api.delete("/delete-product", { params: { productID: id } });
  } catch (err: any) {
    throw new Error(err);
  }
};
