import { useState } from "react";
import { BoxContent, CircularLoading, FadeInContainer } from "../../../Atoms";
import { GenericInput } from "../../../Molecules";
import { Box, Button, Typography } from "@mui/material";
import { LoadingConfigs } from "../../../../../pages";
import { useBreakpoints } from "../../../../../hooks/useBreakpoins";

type AddressConfigProps = {
  field: string;
  value: string;
};

interface Props {
  addressConfig: AddressConfigProps[];
  onSubmit(val: any): void;
  loading: LoadingConfigs;
}

export function AddressConfig({ addressConfig, onSubmit, loading }: Props) {
  const { isMobile, isSmalldesktop } = useBreakpoints();
  const [newAddressConfig, setNewAddressConfig] = useState(
    [] as AddressConfigProps[]
  );

  const changeValues = (value: string, field: string) => {
    const item = { field, value };
    const filter = newAddressConfig.filter((adr) => adr.field !== field);
    setNewAddressConfig([...filter, item]);
  };

  const submit = () => {
    const submitItems: AddressConfigProps[] = [];
    addressConfig.forEach((adr) => {
      const findItem = newAddressConfig.find(
        (newAdr) => newAdr?.field === adr.field
      );
      if (findItem) {
        submitItems.push(findItem);
      } else {
        submitItems.push(adr);
      }
    });
    const payload = {
      shoppingAddress: submitItems,
    };
    onSubmit(payload);
  };

  return (
    <BoxContent
      config={{ mb: 1, width: isMobile || isSmalldesktop ? "100% " : "50%" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={"space-between"}
        height={"100%"}
        width={"100%"}
      >
        {loading.shoppingAddress ? (
          <CircularLoading text="Atualizando" />
        ) : (
          <Box width={"100%"}>
            <FadeInContainer>
              <Typography variant="h1" mb={2}>
                System address
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {addressConfig?.length > 0 &&
                  addressConfig?.map((adr) => (
                    <GenericInput
                      inputName={adr?.field}
                      onChange={(value, name) => changeValues(value, name)}
                      placeholder={adr?.field}
                      hasIcon={false}
                      defaultValue={adr?.value}
                    />
                  ))}
              </Box>
            </FadeInContainer>
          </Box>
        )}
        <Button fullWidth variant="contained" onClick={submit}>
          Update
        </Button>
      </Box>
    </BoxContent>
  );
}
