import { useEffect, useState } from "react";
import { BoxWithTitle } from "../../../components/AtomicDesign/Molecules";
import useAuth from "../../../hooks/context/useAuth";
import { useTranslate } from "../../../hooks/useTranslate";

import {
  CircularLoading,
  FadeInContainer,
} from "../../../components/AtomicDesign/Atoms";
import { Box, Tab, Tabs } from "@mui/material";
import {
  createFrete,
  createNewService,
  deleteFrete,
  deleteService,
  getFretesList,
  getMyTaxes,
  updateFrete,
  updateMyTaxes,
} from "../../../services/fretesConfigService";
import {
  FretesConfig,
  MyTaxesConfig,
} from "../../../components/AtomicDesign/Organisms";
import useToast from "../../../hooks/context/useToast";

export function FreteConfigAdmin() {
  const { setOpen, setToastData } = useToast();
  const { strings } = useTranslate();
  const { language } = useAuth();
  const [fretesList, setFretesList] = useState([] as Fretes[]);
  const [taxesList, setTaxesList] = useState({} as MyTaxes);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeServiceTab, setActiveServiceTab] = useState(0);
  const [myTaxActiveTab, setMyTaxActiveTab] = useState(0);
  const [updateLine, setUpdateLine] = useState({ index: 0, loading: false });

  const getFretes = async (loading?: boolean) => {
    if (loading) {
      setLoading(true);
    }
    try {
      const response = await getFretesList();
      setFretesList(response);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const getTaxes = async (loading?: boolean) => {
    if (loading) {
      setLoading(true);
    }
    try {
      const response = await getMyTaxes();
      setTaxesList(response);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const updateFretes = async (val: UpdateFrete, index: number) => {
    setUpdateLine({ index, loading: true });
    const payload = {
      data: { ...val },
    };

    try {
      await updateFrete(payload);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: strings.toastMessages.successEditFrete[language],
      });
      setOpen(true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.errorEditFrete[language],
      });
      setOpen(true);
    } finally {
      setUpdateLine({ index, loading: false });
      getFretes();
    }
  };

  const newFrete = async (data: CreateFrete) => {
    try {
      await createFrete(data);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: strings.toastMessages.successEditFrete[language],
      });
      setOpen(true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.errorEditFrete[language],
      });
      setOpen(true);
    } finally {
      getFretes(true);
    }
  };

  const delFrete = async (data: DeleteFrete, index: number) => {
    setUpdateLine({ index, loading: true });
    try {
      await deleteFrete(data);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: strings.toastMessages.successDeleteTax[language],
      });
      setOpen(true);
      return true;
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.errorDeleteTax[language],
      });
      setOpen(true);
      return false;
    } finally {
      setUpdateLine({ index, loading: false });
      getFretes(true);
    }
  };

  const updateTaxes = async (val: UpdateFrete, index: number) => {
    setUpdateLine({ index, loading: true });
    const payload = {
      data: { ...val },
    };

    try {
      await updateMyTaxes(payload);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: strings.toastMessages.successEditTax[language],
      });
      setOpen(true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: strings.toastMessages.errorTaxFrete[language],
      });
      setOpen(true);
    } finally {
      setUpdateLine({ index, loading: false });
      getTaxes();
    }
  };

  const newServiceTax = async (data: CreateNewService): Promise<boolean> => {
    try {
      await createNewService(data);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: strings.toastMessages.successEditTax[language],
      });
      setOpen(true);
      return true;
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.errorTaxFrete[language],
      });
      setOpen(true);
      return false;
    } finally {
      getTaxes(true);
    }
  };

  const delService = async (data: DeleteMyService, index: number) => {
    setUpdateLine({ index, loading: true });
    try {
      await deleteService(data);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: strings.toastMessages.successDeleteTax[language],
      });
      setOpen(true);
      return true;
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.errorDeleteTax[language],
      });
      setOpen(true);
      return false;
    } finally {
      setUpdateLine({ index, loading: false });
      getTaxes(true);
    }
  };

  useEffect(() => {
    getFretes(true);
    getTaxes(true);
  }, []);

  return (
    <FadeInContainer>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        alignContent={"center"}
        gap={5}
        p={1}
      >
        <BoxWithTitle
          title={strings.siteConfigPage.title[language]}
          width="100%"
          childrenProps={{ height: "100%" }}
          padding={2}
        >
          {loading ? (
            <CircularLoading />
          ) : (
            <FadeInContainer>
              <Tabs
                value={activeServiceTab}
                onChange={(_e, value) => setActiveServiceTab(value)}
                aria-label="disabled tabs example"
                centered
                variant="fullWidth"
                sx={{ mb: 2 }}
              >
                <Tab label="Fretes" value={0} />
                <Tab label="Taxas" value={1} />
              </Tabs>

              {activeServiceTab === 0 && (
                <>
                  <Tabs
                    value={activeTab}
                    onChange={(_e, value) => setActiveTab(value)}
                    aria-label="disabled tabs example"
                    centered
                    variant="fullWidth"
                  >
                    <Tab label="SMALL PACKET" value={0} />
                    <Tab label="EMS" value={1} />
                    <Tab label="SAL SHIP PARCEL" value={2} />
                    <Tab label="SAL AIR PARCEL" value={3} />
                  </Tabs>

                  <FretesConfig
                    freteList={fretesList}
                    selectedTab={activeTab}
                    updateFrete={updateFretes}
                    createFrete={newFrete}
                    delFrete={delFrete}
                    loadingLine={updateLine}
                  />
                </>
              )}

              {activeServiceTab === 1 && (
                <>
                  <Tabs
                    value={myTaxActiveTab}
                    onChange={(_e, value) => setMyTaxActiveTab(value)}
                    aria-label="disabled tabs example"
                    centered
                    variant="fullWidth"
                  >
                    <Tab label="SMALL PACKET" value={0} />
                    <Tab label="EMS" value={1} />
                    <Tab label="SAL SHIP PARCEL" value={2} />
                    <Tab label="SAL AIR PARCEL" value={3} />
                  </Tabs>

                  <MyTaxesConfig
                    taxesList={taxesList}
                    loadingLine={updateLine}
                    updateTax={updateTaxes}
                    createTax={newServiceTax}
                    deleteTax={delService}
                    selectedTab={myTaxActiveTab}
                  />
                </>
              )}
            </FadeInContainer>
          )}
        </BoxWithTitle>
      </Box>
    </FadeInContainer>
  );
}
