import { Box, Container, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Outlet } from "react-router-dom";
import { ContactInfo } from "../../AtomicDesign/Molecules";
import { CookieWarning } from "../../AtomicDesign/Atoms";

export function LpLayout() {
  return (
    // <Container maxWidth="lg">
    <Box
      minWidth={"100%"}
      minHeight={"100vh"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      position={"relative"}
    >
      <Outlet />

      <Box sx={{ background: grey[900], width: "100%" }}>
        <Container maxWidth={"lg"} sx={{ mt: 2, mb: 2 }}>
          <ContactInfo />
          <Typography textAlign={"center"} mt={10} color={"#FFF"}>
            {String(new Date().getFullYear())} © Treasure Box
          </Typography>
        </Container>
      </Box>
      <CookieWarning />
    </Box>
    // </Container>
  );
}
