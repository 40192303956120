import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { GenericInput, GenericSelect } from "../../../Molecules";
import { useState } from "react";
import { useJPYmoney } from "../../../../../hooks/useJPYmoney";
import { useBreakpoints } from "../../../../../hooks/useBreakpoins";
import EditIcon from "@mui/icons-material/Edit";
import { CustomModal } from "../../Modal";
import { useCommonData } from "../../../../../hooks/useCommonData";
import { green, red } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
interface Props {
  taxesList: MyTaxes;
  selectedTab: number;
  updateTax(values: any, index: number): void;
  createTax(data: CreateNewService): Promise<boolean>;
  deleteTax(data: DeleteMyService, index: number): void;
  loadingLine: { index: number; loading: boolean };
}

const zones = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
];

export function MyTaxesConfig({
  taxesList,
  selectedTab,
  updateTax,
  createTax,
  deleteTax,
  loadingLine,
}: Props) {
  const { adminOrder } = useCommonData();
  const { isSmalldesktop, isMobile } = useBreakpoints();
  const { JPYmoney } = useJPYmoney();
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    weight: 0,
    price: 0,
    taxID: 0,
    index: 0,
  });
  const [newTax, setNewTax] = useState({ weight: 0, price: 0 });
  const [zoneSelected, setZoneSelected] = useState(5);
  const tabs = {
    0: "smallPacket",
    1: "ems",
    2: "surface",
    3: "airParcel",
  };

  const activeTAB = tabs[selectedTab as keyof typeof tabs];

  const confirmFreteEdit = () => {
    const freteTypes = {
      0: "smallPacket",
      1: "ems",
      2: "surface",
      3: "airParcel",
    };
    const payload = {
      serviceType: freteTypes[selectedTab as keyof typeof freteTypes] as
        | "ems"
        | "smallPacket"
        | "surface",
      id: values.taxID,
      weight: values.weight,
      price: values.price,
    };
    updateTax(payload, values.index);
    setOpen(false);
  };

  const submit = async () => {
    const payload = {
      weight: newTax.weight,
      price: newTax.price,
      serviceType: activeTAB,
      globalZoneId: zoneSelected,
    };
    await createTax(payload);

    setNewTax({ price: 0, weight: 0 });
  };

  const delMyTax = (serviceID: number, index: number) => {
    const payload = {
      serviceType: activeTAB,
      serviceID,
    };

    deleteTax(payload, index);
  };

  const selectedTabs = tabs[selectedTab as keyof typeof tabs];

  const filterByZone = () => {
    if (zoneSelected > 0) {
      const filterData = taxesList[
        selectedTabs as keyof typeof taxesList
      ]?.filter((tax) => tax?.globalZoneId === zoneSelected);
      return filterData as {
        id: number;
        price: number;
        weight: number;
        globalZoneId: number;
      }[];
    }
    return [] as {
      id: number;
      price: number;
      weight: number;
      globalZoneId: number;
    }[];
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
    >
      <GenericSelect
        onChange={(value) => setZoneSelected(Number(value))}
        name="select-zone"
        options={zones}
        placeholder="Select zone"
        value={String(zoneSelected)}
        width={isSmalldesktop ? "100%" : "20%"}
      />
      <Box
        p={2}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        width={"100%"}
      >
        {filterByZone()
          .sort((a, b) => (a.weight > b.weight ? 1 : -1))
          ?.map((taxOption, index) => (
            <Box
              key={index}
              width={isSmalldesktop ? "100%" : "50%"}
              mt={1}
              mb={2}
            >
              <Box
                display="flex"
                flexDirection={"column"}
                justifyContent={"space-between"}
              >
                <Box display="flex" justifyContent={"space-between"}>
                  <Typography>Peso</Typography>
                  <Typography>Preço</Typography>
                  <Box display="flex">
                    <Typography mr={1}>Editar</Typography>
                    <Typography>Deletar</Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography>
                    {taxOption?.weight.toLocaleString()} g
                  </Typography>
                  <Typography>{JPYmoney(taxOption?.price)}</Typography>
                  {loadingLine.loading && loadingLine.index === index ? (
                    <CircularProgress size={40} />
                  ) : (
                    <Box display="flex">
                      <IconButton
                        sx={{ mr: 2 }}
                        onClick={() => {
                          setValues({
                            price: taxOption?.price,
                            weight: taxOption?.weight,
                            taxID: taxOption?.id,
                            index,
                          });
                          setOpen(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => delMyTax(taxOption?.id, index)}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </Box>
              <Divider sx={{ mt: 1.5 }} />
            </Box>
          ))}
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            border: "1px solid lightgray",
            borderRadius: 5,
            width: isSmalldesktop ? "100%" : "50%",
            mt: 1,
            p: 2,
          }}
        >
          <Typography variant="h1" mt={2}>
            Nova taxa
          </Typography>
          <GenericInput
            inputName={"weight"}
            onChange={(value) =>
              setNewTax((old) => ({ ...old, weight: Number(value) }))
            }
            value={newTax.weight}
            defaultValue={newTax.weight}
            placeholder={"Peso em gramas"}
            hasIcon={false}
          />
          <GenericInput
            inputName={"price"}
            onChange={(value) =>
              setNewTax((old) => ({ ...old, price: Number(value) }))
            }
            value={newTax.price}
            defaultValue={newTax.price}
            placeholder={"Taxa em ienes"}
            hasIcon={false}
          />
          <Button
            variant="contained"
            onClick={submit}
            sx={{ mt: 1, width: isMobile ? "100%" : "auto" }}
          >
            Adicionar
          </Button>
        </Box>
      </Box>

      <CustomModal setOpenModal={setOpen} openModal={open} title="Editar taxa">
        <Box width={300} p={2}>
          <GenericInput
            inputName="weight"
            onChange={(value, name) =>
              setValues((old) => ({ ...old, [name]: Number(value) }))
            }
            placeholder="Weight"
            value={values.weight}
            hasIcon={false}
          />

          <GenericInput
            inputName="price"
            onChange={(value, name) =>
              setValues((old) => ({ ...old, [name]: Number(value) }))
            }
            placeholder="Price"
            value={values.price}
            hasIcon={false}
          />
        </Box>
        <Box display={"flex"} justifyContent={"center"} gap={5} mb={2}>
          <Button
            sx={{ bgcolor: green[500], color: "white" }}
            style={{ width: 100 }}
            onClick={confirmFreteEdit}
          >
            {adminOrder.confirmBtn}
          </Button>
          <Button
            sx={{ bgcolor: red[500], color: "white" }}
            style={{ width: 100 }}
            onClick={() => setOpen(false)}
          >
            {adminOrder.cancelBtn}
          </Button>
        </Box>
      </CustomModal>
    </Box>
  );
}
