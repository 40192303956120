import { createContext, useContext, useEffect, useMemo, useState } from "react";
import useAuth from "./useAuth";
import {
  createOrderToBuy,
  getOneOrdersToBuy,
  getOrdersToBuy,
  verifyOrderToBuyPayment,
} from "../../services/orderToBuyService";
import {
  deleteProofPaymentService,
  uploadService,
} from "../../services/uploadFiles";
import {
  readMessageService,
  sendNewMessageService,
} from "../../services/messageService";
import { useLocalStorage } from "../useLocalStorage";
import useToast from "./useToast";
import { useTranslate } from "../useTranslate";

const OrdersContext = createContext({} as OrdersContextProps);

type OrderProviderProps = {
  children: React.ReactNode;
};

function OrdersProvider({ children }: OrderProviderProps) {
  const { getFilterStorage, setFilters } = useLocalStorage();
  const { getUserLocalStorage, language, getUser } = useAuth();
  const { setOpen, setToastData } = useToast();
  const { strings } = useTranslate();
  const { user } = getUserLocalStorage();
  const [ordersData, setOrdersData] = useState<IOrderToBuy[]>([]);
  const [order, setOrder] = useState<IOrderToBuy>();
  const [handleIDOrder, setHandleIDOrder] = useState<number | null>();
  const [records, setRecords] = useState(0);
  const [attData, setAttData] = useState(false);

  const [loading, setLoading] = useState({
    uploadLoading: false,
    ordersLoading: false,
    oneOrder: false,
  });
  const [keepAttMessages, setKeepAttMessages] = useState(false);
  // setHowManyPages(Math.ceil(response.count / filter.itensPerPage));
  const ordersDataMemo = useMemo(() => ordersData, [ordersData]);

  const getData = async (cleanFilter?: boolean, noLoading?: boolean) => {
    if (!noLoading) {
      setLoading((old) => ({ ...old, ordersLoading: true }));
    }
    try {
      const filt = getFilterStorage(cleanFilter);
      const response = await getOrdersToBuy(user.user.id, filt);
      setOrdersData(response.data);
      setRecords(response.count);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
    } finally {
      setLoading((old) => ({ ...old, ordersLoading: false }));
    }
  };

  const getOneOrder = async (loading?: boolean) => {
    if (loading) {
      setLoading((old) => ({ ...old, oneOrder: true }));
    }
    try {
      if (handleIDOrder) {
        const response = await getOneOrdersToBuy(handleIDOrder);
        setOrder(response);
      }
    } catch {
    } finally {
      setLoading((old) => ({ ...old, oneOrder: false }));
    }
  };

  const submitOrder = async (info: any) => {
    if (info?.type === "create") {
      try {
        await createOrderToBuy(info?.data);
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "success",
          message: strings.toastMessages.orderCreated[language],
        });
        setOpen(true);

        await getUser(info?.data?.userID);
      } catch {
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "error",
          message: strings.toastMessages.genericError[language],
        });
        setOpen(true);
      } finally {
        setHandleIDOrder(null);
        getData(false, true);
      }
    }
    if (info?.type === "createFiles") {
      try {
        setLoading((old) => ({ ...old, uploadLoading: true }));
        await uploadService(
          info.data.files,
          info.data.suite,
          info.data.creditPaidAll,
          info.data.orderID
        );

        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "success",
          message: strings.toastMessages.proofPayment[language],
        });
        setOpen(true);

        getData();
        getOneOrder();

        setLoading((old) => ({ ...old, uploadLoading: false }));
      } catch (err: any) {
        if (err?.message === "Error: fileMissing") {
          setToastData({
            anchor: { vertical: "top", horizontal: "center" },
            duration: 5000,
            type: "error",
            message: strings.toastMessages.fileMissing[language],
          });
        } else {
          setToastData({
            anchor: { vertical: "top", horizontal: "center" },
            duration: 5000,
            type: "error",
            message: strings.toastMessages.genericError[language],
          });
        }

        getData();
        getOneOrder();
        setOpen(true);
        setLoading((old) => ({ ...old, uploadLoading: false }));
      }
    }
  };

  const verifyPayment = async (chargeID: string, orderID: number) => {
    if (loading) {
      setLoading((old) => ({ ...old, oneOrder: true }));
    }
    try {
      if (handleIDOrder) {
        const response = await verifyOrderToBuyPayment(chargeID, orderID);
        setOrder(response);
      }
    } catch {
    } finally {
      setLoading((old) => ({ ...old, oneOrder: false }));
      getOneOrder();
      getData();
    }
  };

  const fileRemoveAdmin = async (file: any, voucherID: number) => {
    try {
      setLoading((old) => ({ ...old, uploadLoading: true }));
      await deleteProofPaymentService(file, voucherID);
    } catch {
    } finally {
      setLoading((old) => ({ ...old, uploadLoading: false }));
      getOneOrder();
    }
  };

  const sendNewMessage = async ({
    orderID,
    userID,
    description,
    type,
    messageType,
  }: INewMessage) => {
    if (!userID || !orderID) return;

    try {
      await sendNewMessageService({
        orderID,
        userID,
        description,
        type,
        messageType,
      });
      getOneOrder();
      getData(false, true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    }
  };

  const readAllMessages = async (orderID?: number) => {
    if (!orderID) return;
    try {
      await readMessageService(orderID);

      getOneOrder();
      getData(false, true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    } finally {
    }
  };

  const cleanFilters = () => {
    getFilterStorage(true);
    getData(true);
  };

  useEffect(() => {
    getOneOrder(true);
    // eslint-disable-next-line
  }, [handleIDOrder]);

  useEffect(() => {
    const interval = setInterval(() => {
      getOneOrder();
    }, 300000);

    if (!keepAttMessages) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [keepAttMessages]);

  useEffect(() => {
    const interval = setInterval(() => {
      getData(false, true);
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (getFilterStorage().searchTerm !== "") {
      getData();
    } else {
      getData(false, false);
    }
    // eslint-disable-next-line
  }, [attData]);

  return (
    <OrdersContext.Provider
      value={{
        ordersDataMemo,
        setFilters,
        submitOrder,
        cleanFilters,
        fileRemoveAdmin,
        loading,
        getOneOrder,
        setOrder,
        order,
        handleIDOrder,
        setHandleIDOrder,
        sendNewMessage,
        records,
        getFilterStorage,
        setAttData,
        readAllMessages,
        setKeepAttMessages,
        verifyPayment,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
}

export { OrdersProvider, OrdersContext };

export default function useOrders() {
  return useContext(OrdersContext);
}
