import {
  Button,
  Box,
  Checkbox,
  Typography,
  FormHelperText,
  List,
  ListItemText,
  Divider,
  ListItem,
} from "@mui/material";
import { CustomModal } from "../../Organisms";
import { useState } from "react";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { useTranslate } from "../../../../hooks/useTranslate";
import useAuth from "../../../../hooks/context/useAuth";
import { useCommonData } from "../../../../hooks/useCommonData";

interface Props {
  checked: boolean;
  disabled: boolean;
  setChecked(val: boolean): void;
  error: boolean;
  errorMessage?: string;
}

export function CheckBoxTermsConditions({
  checked,
  disabled,
  setChecked,
  error,
  errorMessage,
}: Props) {
  const { strings } = useTranslate();
  const { language, siteConfig } = useAuth();
  const { isMobile } = useBreakpoints();
  const { termsAndconditions } = useCommonData();

  const text = {
    text1: strings.termsConditions.checkbox.text1[language],
    text2: strings.termsConditions.checkbox.text2[language],
    text3: strings.termsConditions.checkbox.text3[language],
  };

  const termsConditions =
    siteConfig?.termsAndConditions?.map((item) => ({ text: item[language] })) ??
    [];

  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <Box display="flex" mt={2}>
        <Checkbox
          checked={checked}
          disabled={disabled}
          onChange={(_e, check) => setChecked(check)}
        />

        <Button variant="text" onClick={() => setOpenModal(true)}>
          <Typography
            fontSize={12}
            sx={{ wordBreak: "break-word" }}
            align="left"
            color="black"
          >
            {text.text1} <u style={{ color: "blue" }}>{text.text2}</u>{" "}
            {text.text3}
          </Typography>
        </Button>
      </Box>
      {error && <FormHelperText error>{errorMessage}</FormHelperText>}
      <CustomModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        width={isMobile ? "95%" : "50%"}
        title={termsAndconditions.title}
      >
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {termsConditions?.map(({ text }, index) => (
            <div key={index}>
              <ListItem alignItems="flex-start">
                <ListItemText primary={`${index + 1}. ${text}`} />
              </ListItem>
              <Divider component="li" />
            </div>
          ))}
        </List>
      </CustomModal>
    </>
  );
}
