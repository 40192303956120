import { useFormik } from "formik";
import useToast from "../../../hooks/context/useToast";
import * as yup from "yup";
import {
  ChangePasswordBox,
  ForgotPasswordBox,
  VerifyCodeForgotPasswordBox,
} from "../../../components/AtomicDesign/Organisms";
import useAuth from "../../../hooks/context/useAuth";
import { AlertColor, Fade } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useTranslate } from "../../../hooks/useTranslate";

export function ForgotPassword() {
  const navigate = useNavigate();
  const { language } = useAuth();
  const { strings } = useTranslate();
  const { setOpen, setToastData } = useToast();
  const { forgotPassword, forgotPasswordVerifyCode, changePassword } =
    useAuth();

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(strings.validationFields.email[language])
      .required(strings.validationFields.email.required[language]),
  });

  const configureToast = (type: AlertColor, message: string) => {
    setToastData({
      anchor: { vertical: "top", horizontal: "center" },
      duration: 10000,
      type: type,
      message: message,
    });
    setOpen(true);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      code: "",
      newPassword: "",
      step: 1,
    },
    validationSchema,
    async onSubmit(values: IForgotPasswordFormValues) {
      if (values.step === 1) {
        const sendEmail = await forgotPassword(values.email);
        if (sendEmail === "success") {
          configureToast(
            "success",
            strings.toastMessages.recoverPassword[language]
          );
          formik.setFieldValue("step", 2);
        } else {
          configureToast("error", strings.toastMessages.verifyEmail[language]);
        }
      }
      if (values.step === 2) {
        const sendCode = await forgotPasswordVerifyCode(
          values.email,
          values.code
        );
        if (sendCode === "success") {
          configureToast(
            "success",
            strings.toastMessages.confirmCode[language]
          );
          formik.setFieldValue("step", 3);
        } else {
          configureToast("error", strings.toastMessages.errorCode[language]);
        }
      }
      if (values.step === 3) {
        const sendCode = await changePassword(
          values.email,
          values.code,
          values.newPassword
        );
        if (sendCode === "success") {
          configureToast(
            "success",
            strings.toastMessages.newPassword[language]
          );
          navigate("/login");
        } else {
          configureToast(
            "error",
            strings.toastMessages.errorNewPassword[language]
          );
        }
      }
    },
  });

  return (
    <>
      {formik.values.step === 1 && (
        <Fade in={true} style={{ transformOrigin: "0 0 0" }} timeout={1000}>
          <div>
            <ForgotPasswordBox error={false} formik={formik} />
          </div>
        </Fade>
      )}
      {formik.values.step === 2 && (
        <Fade in={true} style={{ transformOrigin: "0 0 0" }} timeout={1000}>
          <div>
            <VerifyCodeForgotPasswordBox error={false} formik={formik} />
          </div>
        </Fade>
      )}
      {formik.values.step === 3 && (
        <Fade in={true} style={{ transformOrigin: "0 0 0" }} timeout={1000}>
          <div>
            <ChangePasswordBox error={false} formik={formik} />
          </div>
        </Fade>
      )}
    </>
  );
}
