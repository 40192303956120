import { createContext, useContext, useEffect, useMemo, useState } from "react";
import useAuth from "./useAuth";
import { useLocalStorage } from "../useLocalStorage";
import useToast from "./useToast";
import { useTranslate } from "../useTranslate";
import { getUserServiceAdmin } from "../../services/getUsersAdmin";
import {
  createProductService,
  deleteProductService,
  getOneProductService,
  getProductsListService,
  removeImageProductService,
  solicitationAdminService,
  updateProductImages,
  updateProductService,
} from "../../services/productsService";
import {
  readMessageService,
  sendNewMessageService,
} from "../../services/messageService";
import { useLocation } from "react-router-dom";

const ProductsAdminContext = createContext({} as ProductProviderPropsAdmin);

type Props = {
  children: React.ReactNode;
};

function ProductsAdminProvider({ children }: Props) {
  const { pathname } = useLocation();
  const { getFilterStorage, setFilters } = useLocalStorage();
  const { getUserLocalStorage, language } = useAuth();
  const { setOpen, setToastData } = useToast();
  const { strings } = useTranslate();
  const { user } = getUserLocalStorage();
  const [productsData, setProductsData] = useState<Products[]>([]);
  const [product, setProduct] = useState({} as Products);
  const [handleIDProduct, setHandleIDProduct] = useState<number | null>();
  const [records, setRecords] = useState(0);
  const [attData, setAttData] = useState(false);
  const [loading, setLoading] = useState({
    uploadLoading: false,
    usersLoading: false,
    productsLoading: false,
    submitProduct: false,
    oneOrder: false,
  });
  const [usersData, setUsersData] = useState<UsersAdmin[]>();
  const [menuProductsCount, setMenuProductsCount] = useState(0);
  const productsDataMemo = useMemo(() => productsData, [productsData]);

  const getDataAdmin = async (cleanFilter?: boolean, noLoading?: boolean) => {
    if (!noLoading) {
      setLoading((old) => ({ ...old, productsLoading: true }));
    }

    try {
      const filt = getFilterStorage(cleanFilter);
      const response = await getProductsListService(
        user?.user?.id,
        user?.suite[0]?.id,
        filt
      );
      setProductsData(response.data);
      setRecords(response.count);
      setMenuProductsCount(response.activeServices ?? 0);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
    } finally {
      setLoading((old) => ({ ...old, productsLoading: false }));
    }
  };

  const getOneProduct = async (loading?: boolean) => {
    if (loading) {
      setLoading((old) => ({ ...old, oneOrder: true }));
    }
    try {
      if (handleIDProduct) {
        const response = await getOneProductService(handleIDProduct);
        setProduct(response);
      }
    } catch {
    } finally {
      setLoading((old) => ({ ...old, oneOrder: false }));
    }
  };

  const submitProduct = async (info: any) => {
    setLoading((old) => ({ ...old, submitProduct: true }));
    try {
      await createProductService(info.data);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: strings.toastMessages.orderCreated[language],
      });
      setOpen(true);
      return true;
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
      return false;
    } finally {
      getDataAdmin(false, true);
      setLoading((old) => ({ ...old, submitProduct: false }));
    }
  };

  const updateProduct = async (info: any) => {
    try {
      await updateProductService(info.data);
      if (info?.data?.file?.length > 0) {
        await updateProductImages(info.data);
      }
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: strings.toastMessages.orderCreated[language],
      });
      setOpen(true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    } finally {
      getDataAdmin(false, true);
      getOneProduct();
    }
  };

  const productRemoveImage = async (imageID: number) => {
    try {
      await removeImageProductService(imageID);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    } finally {
      getOneProduct();
    }
  };

  const getUsersAdmin = async () => {
    setLoading((old) => ({ ...old, usersLoading: true }));
    const filter = getFilterStorage();
    try {
      const response = await getUserServiceAdmin({
        searchKey: "name",
        searchTerm: filter.searchTerm,
        itensPerPage: -1,
        offset: filter.offset,
      });
      setUsersData(response.data);
      setRecords(response.count);
    } catch {
    } finally {
      setLoading((old) => ({ ...old, usersLoading: false }));
    }
  };

  const productSolicitationAdmin = async (
    productID: number,
    solicitationType: "removePackage" | "takeNewPhotos"
  ) => {
    try {
      await solicitationAdminService(productID, solicitationType);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: strings.toastMessages.orderCreated[language],
      });
      setOpen(true);
    } catch (e: any) {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });

      setOpen(true);
    }
  };

  const deleteProduct = async (id: number) => {
    try {
      await deleteProductService(id);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: strings.toastMessages.deletePrd[language],
      });
      setOpen(true);
    } catch (err: any) {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.deletePrdError[language],
      });
      setOpen(true);
    } finally {
      getDataAdmin();
    }
  };

  const sendNewMessage = async ({
    orderID,
    userID,
    description,
    type,
    messageType,
  }: INewMessage) => {
    if (!userID || !orderID) return;

    try {
      await sendNewMessageService({
        orderID,
        userID,
        description,
        type,
        messageType,
      });
      getOneProduct();
      getDataAdmin(false, true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    }
  };

  const readAllMessages = async (orderID?: number) => {
    if (!orderID) return;
    try {
      await readMessageService(orderID, "product");

      getOneProduct();
      getDataAdmin(false, true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    } finally {
    }
  };

  const cleanFilters = () => {
    getFilterStorage(true);
    getDataAdmin(true);
  };

  useEffect(() => {
    getOneProduct(true);
    // eslint-disable-next-line
  }, [handleIDProduct]);

  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       getOneProduct();
  //     }, 30000);

  //     return () => clearInterval(interval);
  //     // eslint-disable-next-line
  //   }, []);

  useEffect(() => {
    if (pathname === "/treasurebox-app/admin/products") {
      if (getFilterStorage().searchTerm !== "") {
        getDataAdmin();
      } else {
        getDataAdmin(false, true);
      }
    }
    // eslint-disable-next-line
  }, [attData, pathname]);

  useEffect(() => {
    getDataAdmin(false, false);
    getUsersAdmin();
    // eslint-disable-next-line
  }, []);

  return (
    <ProductsAdminContext.Provider
      value={{
        deleteProduct,
        sendNewMessage,
        readAllMessages,
        productsDataMemo,
        productSolicitationAdmin,
        setFilters,
        submitProduct,
        cleanFilters,
        productsData,
        loading,
        handleIDProduct,
        setHandleIDProduct,
        getUsersAdmin,
        usersData,
        setAttData,
        getFilterStorage,
        menuProductsCount,
        records,
        product,
        getOneProduct,
        setProduct,
        productRemoveImage,
        updateProduct,
      }}
    >
      {children}
    </ProductsAdminContext.Provider>
  );
}

export { ProductsAdminProvider, ProductsAdminContext };

export default function useProductsAdmin() {
  return useContext(ProductsAdminContext);
}
