import { Box, Typography, BoxProps, Pagination } from "@mui/material";
import { colors } from "../../../../theme/colors";
import { Button } from "../../Atoms";
import { Filter } from "../../Organisms";
import { Props as FilterProps } from "../../Organisms/Filter";
import { useEffect, useState } from "react";

interface Props extends FilterProps {
  children: React.ReactNode[] | React.ReactNode;
  title: string;
  width?: string;
  padding?: number | string;
  childrenProps?: BoxProps;
  props?: BoxProps;
  hasButton?: boolean;
  onClick?: VoidFunction;
  buttonTitle?: string;
  hasFilter?: boolean;
  itensPerpage?(val: number): void;
  howManyPages?: number;
  hasPagination?: boolean;
  onChangePage?(val: number): void;
  itensPerPage?: number;
  btnWidth?: number;
}

export function BoxWithTitle({
  children,
  title,
  padding = 3,
  width,
  childrenProps,
  props,
  hasButton = false,
  hasFilter = false,
  buttonTitle,
  onClick,
  searchTerm,
  onChangeTextField,
  onSelectField,
  selectedField,
  onClickCleanFilter,
  itensPerpage,
  hasPagination = false,
  howManyPages,
  onChangePage,
  itensPerPage,
  btnWidth = 150,
  filterType,
}: Props) {
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (searchTerm && itensPerPage) {
      onChangePage && onChangePage(1);
      setPage(1);
    }
    // eslint-disable-next-line
  }, [searchTerm, itensPerPage]);

  return (
    <Box
      borderRadius={"5px"}
      border={"1px solid lightgray"}
      width={width}
      bgcolor={colors.white[500]}
      {...props}
    >
      <Box
        borderBottom={"1px solid lightgray"}
        p={2}
        bgcolor={colors.blue[900]}
        borderRadius={"5px 5px 0 0"}
        display="flex"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h1" fontWeight={500} color={colors.white[500]}>
          {title}
        </Typography>

        {hasButton && (
          <Button
            config={{
              sx: {
                width: btnWidth,
                bgcolor: colors.red[500],
                color: colors.white[500],
                "&:hover": { bgcolor: colors.redAlpha[500] },
              },
              onClick,
            }}
          >
            {buttonTitle}
          </Button>
        )}
      </Box>
      <Box p={padding} {...childrenProps}>
        {hasFilter && (
          <Filter
            onChangeTextField={onChangeTextField}
            onSelectField={onSelectField}
            selectedField={selectedField}
            searchTerm={searchTerm}
            onClickCleanFilter={onClickCleanFilter}
            handleItensperPage={itensPerpage}
            itensPerPage={itensPerPage}
            filterType={filterType}
          />
        )}
        {children}
        {hasPagination && (
          <Box display={"flex"} justifyContent={"center"} width={"100%"} mt={2}>
            <Pagination
              count={howManyPages}
              page={page}
              defaultPage={1}
              onChange={(_e, page) => {
                onChangePage && onChangePage(page);
                setPage(page);
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
