import { Box, List, ListItem, Typography } from "@mui/material";
import useAuth from "../../../hooks/context/useAuth";
import { useTranslate } from "../../../hooks/useTranslate";

import {
  BoxInfo,
  BoxInfoCards,
} from "../../../components/AtomicDesign/Organisms";
import {
  BoxWithTitle,
  ResumeBox,
} from "../../../components/AtomicDesign/Molecules";
import { useBreakpoints } from "../../../hooks/useBreakpoins";
import { colors } from "../../../theme/colors";
import { FadeInContainer } from "../../../components/AtomicDesign/Atoms";
import { blueGrey } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { getDashboard } from "../../../services/getUsersAdmin";

export function ClientDashboard() {
  const { strings } = useTranslate();
  const { language, user, siteConfig } = useAuth();
  const { isSmalldesktop } = useBreakpoints();
  const [dashboardInfo, setDashboardInfo] = useState({
    products: 0,
    orders: 0,
    deliveries: 0,
  });

  const filterField = {
    address: strings.address.address[language],
    city: strings.address.city[language],
    state: strings.address.state[language],
    zipCode: strings.address.zipCode[language],
    country: strings.address.country[language],
  };

  const makeAddressFields =
    siteConfig?.shoppingAddress?.map((adr) => ({
      name: filterField[adr?.field as keyof typeof filterField],
      value:
        adr?.field === "address"
          ? `${adr?.value}  - SUITE ${
              user?.suite?.length > 0 ? user?.suite[0]?.id : 0
            }`
          : adr?.value,
    })) ?? [];

  const fullAddress = [
    {
      name: strings.address.recipient[language],
      value: user?.user?.name,
    },
    ...makeAddressFields,
  ];

  const funct =
    siteConfig?.workHours?.map((work) => ({
      text: work?.text[language],
      icon: work?.icon,
    })) ?? [];

  const systemUse = siteConfig?.systemUse?.map((text) => text[language]) ?? [];

  const prazos =
    siteConfig?.deadlines?.map((work) => ({
      text: work?.text[language],
      icon: work?.icon,
    })) ?? [];

  const resume: any = [
    {
      text: strings.resumeService.product[language],
      icon: "game",
      color: colors.blue[400],
      value: dashboardInfo.products,
    },
    {
      text: strings.resumeService.services[language],
      icon: "service",
      color: colors.yellow[400],
      value: dashboardInfo.orders,
    },
    {
      text: strings.resumeService.shipments[language],
      icon: "shipping",
      color: colors.green[400],
      value: dashboardInfo.deliveries,
    },
    {
      text: strings.resumeService.credits[language],
      icon: "credits",
      color: blueGrey[400],
      value: user?.money,
    },
  ];

  const getMyDashboardInfo = async () => {
    try {
      const response = await getDashboard(user?.user?.id);
      setDashboardInfo(response);
    } catch {
      setDashboardInfo({ deliveries: 0, orders: 0, products: 0 });
    }
  };

  useEffect(() => {
    getMyDashboardInfo();
    // eslint-disable-next-line
  }, []);

  return (
    <FadeInContainer>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        gap={5}
        justifyContent={"center"}
        p={1}
      >
        <ResumeBox data={resume} />
        <BoxInfo
          fullAddress={fullAddress}
          complement={`${siteConfig.daysFreeInStorage} ${strings.address.store[language]}`}
          boxTitle={strings.servicesTitle.address[language]}
          width={isSmalldesktop ? "100%" : "45%"}
          padding={isSmalldesktop ? "10px" : "29px"}
        />
        <BoxWithTitle
          title={strings.servicesTitle.systemUse[language]}
          width={isSmalldesktop ? "100%" : "45%"}
          padding={isSmalldesktop ? "10px" : "25px"}
        >
          <List>
            {systemUse?.map((text, index) => (
              <ListItem key={index}>
                <Typography textAlign={"right"} minWidth="30px" mr={"10px"}>
                  {index + 1}
                </Typography>
                <Typography>{text}</Typography>
              </ListItem>
            ))}
          </List>
        </BoxWithTitle>

        <BoxInfoCards
          boxTitle={strings.servicesTitle.hours[language]}
          services={funct}
        />
        <BoxInfoCards
          boxTitle={strings.servicesTitle.deadlines[language]}
          services={prazos}
        />
      </Box>
    </FadeInContainer>
  );
}
