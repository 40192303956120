import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export function CircularLoading({
  text,
  size,
  mt = 5,
  mb = 5,
}: {
  text?: string;
  size?: number;
  mb?: number;
  mt?: number;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",

        mt,
        mb,
      }}
    >
      <CircularProgress size={size} />
      <Typography ml={1}>{text}</Typography>
    </Box>
  );
}
