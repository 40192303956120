import { useEffect, useState } from "react";
import {
  BoxWithTitle,
  UsersCards,
} from "../../../components/AtomicDesign/Molecules";
import useAuth from "../../../hooks/context/useAuth";
import { useTranslate } from "../../../hooks/useTranslate";
import { getUserServiceAdmin } from "../../../services/getUsersAdmin";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { FadeInContainer } from "../../../components/AtomicDesign/Atoms";
import { Box } from "@mui/material";
import {
  CustomModal,
  UpdateUserAdmin,
} from "../../../components/AtomicDesign/Organisms";
import { useBreakpoints } from "../../../hooks/useBreakpoins";

export function UsersAdmin() {
  const { strings } = useTranslate();
  const { language } = useAuth();
  const { getFilterStorage, setFilters } = useLocalStorage();
  const { isSmalldesktop } = useBreakpoints();
  const [usersData, setUsersData] = useState<UsersAdmin[]>();
  const [records, setRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [attData, setAttData] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [handleUser, setHandleUser] = useState<UsersAdmin | null>();

  const closeModal = () => {
    setModalOpen(false);
    setHandleUser(null);
  };

  const cleanFilters = () => {
    getFilterStorage(true);
    setAttData((bool: boolean) => !bool);
  };

  const getUsersAdmin = async () => {
    setLoading(true);
    const filter = getFilterStorage();
    try {
      const response = await getUserServiceAdmin({
        searchKey: filter.key,
        searchTerm: filter.searchTerm,
        itensPerPage: filter.itensPerPage,
        offset: filter.offset,
      });
      setUsersData(response.data);
      setRecords(response.count);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsersAdmin();
    // eslint-disable-next-line
  }, [attData]);

  return (
    <FadeInContainer>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        alignContent={"center"}
        gap={5}
        p={1}
      >
        <BoxWithTitle
          title={strings.usersPage.title[language]}
          width="100%"
          childrenProps={{ height: "100%" }}
          padding={1}
          // filter
          filterType="user"
          hasFilter
          onSelectField={(val) => setFilters("key", val)}
          selectedField={getFilterStorage().key}
          onChangeTextField={(val) => {
            setFilters("searchTerm", val);
            setAttData((bool: boolean) => !bool);
          }}
          searchTerm={getFilterStorage().searchTerm}
          onClickCleanFilter={cleanFilters}
          // filter
          // pagnation
          hasPagination
          itensPerpage={(val) => {
            setFilters("itensPerPage", val);
            setAttData((bool: boolean) => !bool);
          }}
          itensPerPage={getFilterStorage().itensPerPage} // quantidade
          howManyPages={Math.ceil(records / getFilterStorage().itensPerPage)}
          onChangePage={(page) => {
            setFilters("offset", page - 1);
            setAttData((bool: boolean) => !bool);
          }}
          // pagnation
        >
          <UsersCards
            data={usersData ?? []}
            onClickUser={(user) => {
              setHandleUser(user);
              setModalOpen(true);
            }}
            isLoading={loading}
          />
        </BoxWithTitle>
      </Box>
      <CustomModal
        openModal={modalOpen}
        setOpenModal={closeModal}
        width={isSmalldesktop ? "90%" : "40%"}
        title={strings.usersPage.editTitle[language]}
      >
        <UpdateUserAdmin
          data={handleUser}
          setAttData={() => setAttData((bool: boolean) => !bool)}
          closeModal={closeModal}
        />
      </CustomModal>
    </FadeInContainer>
  );
}
