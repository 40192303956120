import React from "react";
import {
  Box,
  Input,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { AccountCircle } from "@mui/icons-material";

interface Props {
  value: string;
  onChange(value: string): void;
  error?: boolean;
  hasIcon?: boolean;
  placeholder: string;
  errorMessage?: string;
}

export function EmailField({
  value,
  onChange,
  error,
  hasIcon = true,
  placeholder,
  errorMessage,
}: Props) {
  const onChangeValue = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = e.target as HTMLInputElement;
    onChange(target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
      >
        {hasIcon && (
          <AccountCircle sx={{ color: "action.active", mr: 1, mt: 2 }} />
        )}
        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
          <InputLabel htmlFor="standard-adornment-email">
            {placeholder}
          </InputLabel>
          <Input
            id="standard-adornment-email"
            type={"text"}
            value={value}
            onChange={onChangeValue}
            error={error}
          />
        </FormControl>
      </Box>
      <FormHelperText error>{errorMessage}</FormHelperText>
    </Box>
  );
}
