export function useJPYmoney() {
  const JPYmoney = (value: number) => {
    if (!value) return "￥ 0";
    const convert = new Intl.NumberFormat("ja-JP", {
      style: "currency",
      currency: "JPY",
    });
    return convert.format(value);
  };

  return { JPYmoney };
}
