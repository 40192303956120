import { Box, Button, Switch, Typography } from "@mui/material";
import { BoxContent, BreakpointBox } from "../../Atoms";
import { EmailField, GenericInput, PasswordField } from "../../Molecules";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import treasureBoxLogo from "../../../../assets/images/NEWTREASUREBOXLOGO2.png";
import useAuth from "../../../../hooks/context/useAuth";
import { useTranslate } from "../../../../hooks/useTranslate";
import * as yup from "yup";
import useToast from "../../../../hooks/context/useToast";
import { useFormik } from "formik";
import { updateUserService } from "../../../../services/updateUserService";
import CountrySelect from "../AutocompletePhone";
import { useEffect } from "react";
import { usePhoneCode } from "../../../../hooks/usePhoneCode";

interface IFormInputs {
  age: string;
  name: string;
  phoneCode: string;
  phone: string;
  ssn: string;
  email: string;
  password: string;
  mailNotification: boolean;
}

export function UpdateMyUser() {
  const { isMobile } = useBreakpoints();
  const { strings } = useTranslate();
  const { user, language, getUser } = useAuth();
  const { convertPhone } = usePhoneCode();

  const { setOpen, setToastData } = useToast();

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(strings.validationFields.name.required[language]),
    phoneCode: yup
      .string()
      .required(strings.validationFields.phoneCode.required[language]),
    phone: yup
      .number()
      .typeError(strings.validationFields.phoneCode.typeError[language])
      .required(strings.validationFields.phoneCode.required[language]),

    email: yup
      .string()
      .email(strings.validationFields.email[language])
      .required(strings.validationFields.email.required[language]),
  });

  const formik = useFormik<IFormInputs>({
    initialValues: {
      age: "",
      email: "",
      name: "",
      phone: "",
      phoneCode: "",
      ssn: "",
      password: "",
      mailNotification: true,
    },
    validationSchema,
    async onSubmit(values: any) {
      const updateUser = await updateUserService({
        id: user?.user?.id,
        ...values,
        phone: `${values?.phoneCode}${values.phone}`,
      });

      if (updateUser?.message === "success") {
        await getUser(user?.user?.id);
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "success",
          message: strings.toastMessages.editPersonalData[language],
        });
        setOpen(true);
      } else {
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "error",
          message: strings.toastMessages.editErrorPersonalData[language],
        });
        setOpen(true);
      }
    },
  });

  const getUserData = async (id: number) => {
    const userGet = (await getUser(id)) as any;

    formik.setFieldValue("age", userGet?.user?.age);
    formik.setFieldValue("email", userGet?.user?.email);
    formik.setFieldValue("name", userGet?.user?.name);
    formik.setFieldValue(
      "phone",
      convertPhone(userGet?.user?.phoneCode, userGet?.user?.phone)
    );
    formik.setFieldValue("phoneCode", userGet?.user?.phoneCode);
    formik.setFieldValue("ssn", userGet?.user?.ssn);
    formik.setFieldValue("mailNotification", userGet?.user?.mailNotification);
  };

  useEffect(() => {
    const localUser = localStorage.getItem("@treasurebox_user") as any;
    if (localUser) {
      const parseID = JSON.parse(localUser).user.id;

      getUserData(parseID);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <BoxContent config={{ p: 3, mt: 5, mb: 5 }}>
      <img src={treasureBoxLogo} width={150} alt="treasurebox logo" />
      <BreakpointBox>
        <GenericInput
          hasIcon={false}
          onChange={(value, name) => formik.setFieldValue(name, value)}
          error={formik?.touched?.name && formik?.errors?.name ? true : false}
          errorMessage={formik?.touched?.name ? formik?.errors?.name : ""}
          value={formik?.values?.name}
          placeholder={strings.createUser.namePlaceholder[language]}
          inputName="name"
        />
        <GenericInput
          hasIcon={false}
          onChange={(value, name) => formik.setFieldValue(name, value)}
          error={formik?.touched?.age && formik?.errors?.age ? true : false}
          errorMessage={formik?.touched?.age ? formik?.errors?.age : ""}
          value={formik?.values?.age}
          placeholder={strings.createUser.age[language]}
          inputName="age"
          width={isMobile ? "100%" : "50%"}
        />
      </BreakpointBox>
      <BreakpointBox>
        <Box display="flex" sx={{ width: "100%" }}>
          <CountrySelect
            selectedCode={(val) => formik.setFieldValue("phoneCode", val)}
            defaultValue={formik?.values?.phoneCode}
          />
          <GenericInput
            hasIcon={false}
            onChange={(value, name) => formik.setFieldValue(name, value)}
            error={
              (formik?.touched?.phone && formik?.errors?.phone) ||
              (formik?.touched?.phoneCode && formik?.errors?.phoneCode)
                ? true
                : false
            }
            errorMessage={
              formik?.touched?.phone || formik?.touched?.phoneCode
                ? formik?.errors?.phone
                  ? formik?.errors?.phone
                  : formik?.errors?.phoneCode
                  ? formik?.errors?.phoneCode
                  : ""
                : ""
            }
            value={formik?.values?.phone}
            placeholder={strings.createUser.phonePlaceholder[language]}
            inputName="phone"
          />
        </Box>
        <GenericInput
          hasIcon={false}
          onChange={(value, name) => formik.setFieldValue(name, value)}
          error={formik?.touched?.ssn && formik?.errors?.ssn ? true : false}
          errorMessage={formik?.touched?.ssn ? formik?.errors?.ssn : ""}
          value={formik?.values?.ssn}
          placeholder={strings.createUser.ssn[language]}
          inputName="ssn"
        />
      </BreakpointBox>

      <EmailField
        hasIcon={false}
        onChange={(value) => formik.setFieldValue("email", value)}
        error={formik?.touched?.email && formik?.errors?.email ? true : false}
        errorMessage={formik?.touched?.email ? formik?.errors?.email : ""}
        value={formik?.values?.email}
        placeholder="E-mail"
      />
      <PasswordField
        hasIcon={false}
        onChange={(value) => formik.setFieldValue("password", value)}
        error={false}
        value={formik?.values?.password}
        placeholder={strings.createUser.passwordPlaceholder[language]}
      />
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        width={"97%"}
        mt={2}
      >
        <Typography>{strings.createUser.mailNotification[language]}</Typography>
        <Switch
          checked={formik.values.mailNotification}
          size={"small"}
          color="warning"
          onChange={(_e, check) =>
            formik.setFieldValue("mailNotification", check)
          }
        />
      </Box>
      <Button
        sx={{ mt: 3 }}
        fullWidth
        variant="contained"
        onClick={() => formik.handleSubmit()}
      >
        {strings.buttons.edit[language]}
      </Button>
    </BoxContent>
  );
}
