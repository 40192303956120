import { Badge, IconButton, Button } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import { blue } from "@mui/material/colors";

interface Props {
  onClick?: VoidFunction;
  hasNewMessage: boolean;
  disabled?: boolean;
  chatButton?: boolean;
}

export function MailNotification({
  onClick,
  hasNewMessage,
  disabled = false,
  chatButton = false,
}: Props) {
  return chatButton ? (
    <Button
      variant="outlined"
      sx={{ height: 40 }}
      onClick={onClick}
      disabled={disabled}
    >
      <Badge
        badgeContent={hasNewMessage ? "!" : null}
        color="secondary"
        sx={{ mr: 1 }}
      >
        <MailIcon sx={{ fill: "black" }} />
      </Badge>
      Chat
    </Button>
  ) : (
    <IconButton
      onClick={onClick}
      disabled={disabled}
      sx={{
        bgcolor: hasNewMessage ? blue[200] : "inherits",
        "&:disabled": { bgcolor: hasNewMessage ? blue[200] : "inherits" },
      }}
    >
      <Badge badgeContent={hasNewMessage ? "!" : null} color="secondary">
        <MailIcon sx={{ fill: "black" }} />
      </Badge>
    </IconButton>
  );
}
