export const colors = {
  red: {
    400: "#e7505a",
    500: "#ed2623",
  },
  redAlpha: {
    500: "#ed262390",
  },
  white: {
    500: "#FFF",
  },
  green: {
    400: "#1BBC9B",
  },
  gray: {
    100: "#e9ecf3",
  },
  yellow: {
    400: "#F4D03F",
  },
  blue: {
    400: "#578ebe",
    900: "#2C3E50",
  },
};
