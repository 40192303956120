import { Box, Button, TextField, Typography } from "@mui/material";
import { BoxWithTitle } from "../BoxWithTitle";
import useAuth from "../../../../hooks/context/useAuth";
import { amber, blue } from "@mui/material/colors";
import { useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { FadeInContainer } from "../../Atoms";
import { colors } from "../../../../theme/colors";
import { useTranslate } from "../../../../hooks/useTranslate";
import { UseDateTime } from "../../../../hooks/useDateTime";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from "@mui/icons-material/Done";

interface Props {
  data: IMessage[] | undefined;
  sendMessage(val: string): void;
  readMessages: VoidFunction;
}

export function MessageBox({ data, sendMessage, readMessages }: Props) {
  const { language } = useAuth();
  const { strings } = useTranslate();
  const { formatedDate } = UseDateTime();
  const [newMessage, setMessage] = useState("");

  const scrollDown = () => {
    const getBarLocation = document.querySelector("#roll-Bar") as any;
    setTimeout(() => {
      getBarLocation.scrollTop =
        getBarLocation?.scrollHeight - getBarLocation?.clientHeight;
    }, 500);
  };

  const sendNewMessage = () => {
    if (newMessage !== "") {
      sendMessage(newMessage);
      setMessage("");
      scrollDown();
    }
  };

  useEffect(() => {
    scrollDown();

    const textMessage = document.getElementById("text-message");

    textMessage?.addEventListener("keypress", (e) => {
      if (e.key === "Enter") {
        document?.getElementById("messageSubmit")?.click();
      }
    });

    // eslint-disable-next-line
  }, []);

  return (
    <BoxWithTitle
      title={strings.messages.title[language]}
      width="99%"
      childrenProps={{ height: "70vh", overflow: "hidden" }}
      padding={0}
      hasButton
      onClick={readMessages}
      buttonTitle={strings.messages.btn[language]}
      btnWidth={170}
    >
      <Box
        width={"100%"}
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
      >
        <Box
          height={"100%"}
          sx={{
            maxHeight: "95%",
            overflowY: "auto",
            "&::-webkit-scrollbar": { width: 10 },
            "&::-webkit-scrollbar-track": {
              backgroundColor: colors.blue[900],
              borderRadius: 10,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: colors.blue[400],
              borderRadius: 5,
            },
          }}
          id="roll-Bar"
        >
          {data &&
            data.length > 0 &&
            data
              .map((message, index) => (
                <FadeInContainer key={index}>
                  <Box
                    maxWidth={"95%"}
                    display={"flex"}
                    justifyContent={
                      message?.type === "admin" ? "flex-end" : "flex-start"
                    }
                    padding={1}
                    borderRadius={5}
                  >
                    <Box
                      sx={{
                        bgcolor:
                          message?.type === "admin" ? amber[100] : blue[100],
                        p: 1,
                        borderRadius: 2,
                        maxWidth: "80%",
                      }}
                    >
                      <Typography variant="h1">
                        {message?.User?.name} - {formatedDate(message?.created)}
                      </Typography>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"flex-end"}
                      >
                        <Typography
                          ml={1}
                          variant="h2"
                          maxWidth={"94%"}
                          sx={{ wordBreak: "break-word" }}
                        >
                          {message?.description}
                        </Typography>
                        {message?.read ? (
                          <DoneAllIcon sx={{ fill: "green" }} />
                        ) : (
                          <DoneIcon />
                        )}
                      </Box>
                    </Box>
                  </Box>
                </FadeInContainer>
              ))
              .reverse()}
        </Box>
        <Box display={"flex"} width={"99%"} p={1}>
          <TextField
            id="text-message"
            variant="outlined"
            onChange={(e) => setMessage(e.target.value)}
            value={newMessage}
            fullWidth
          />
          <Button
            id="messageSubmit"
            onClick={sendNewMessage}
            sx={{ width: 50, borderRadius: 50 }}
          >
            <SendIcon />
          </Button>
        </Box>
      </Box>
    </BoxWithTitle>
  );
}
