import { createContext, useContext, useEffect, useMemo, useState } from "react";
import useAuth from "./useAuth";
import {
  readMessageService,
  sendNewMessageService,
} from "../../services/messageService";
import { useLocalStorage } from "../useLocalStorage";
import useToast from "./useToast";
import { useTranslate } from "../useTranslate";
import {
  addProductInShippment,
  createShippmentService,
  deleteProductInShippment,
  deleteShippment,
  findOneShippment,
  getShippmentsService,
  updateProductInShippment,
  uploadServiceShippment,
  verifyShippmentPayment,
} from "../../services/shippmentsService";
import { getProductsListService } from "../../services/productsService";
import { deleteProofPaymentService } from "../../services/uploadFiles";
import { useLocation } from "react-router-dom";

const ShippmentsContext = createContext({} as ShippmentContextProps);

type Props = {
  children: React.ReactNode;
};

function ShippmentsProvider({ children }: Props) {
  const { pathname } = useLocation();
  const { getFilterStorage, setFilters } = useLocalStorage();
  const { getUserLocalStorage, language } = useAuth();
  const { setOpen, setToastData } = useToast();
  const { strings } = useTranslate();
  const { user } = getUserLocalStorage();
  const [shippmentsData, setShippmentsData] = useState<Shippment[]>([]);
  const [shippment, setShippment] = useState<OneShippment>();
  const [handleIDOrder, setHandleIDOrder] = useState<number | null>();
  const [records, setRecords] = useState(0);
  const [attData, setAttData] = useState(false);
  const [loading, setLoading] = useState({
    uploadLoading: false,
    ordersLoading: false,
    oneOrder: false,
    submitDelivery: false,
  });
  const [keepAttMessages, setKeepAttMessages] = useState(false);
  const [menuShipCount, setMenuShipCount] = useState(0);
  // setHowManyPages(Math.ceil(response.count / filter.itensPerPage));
  const shippmentsDataMemo = useMemo(() => shippmentsData, [shippmentsData]);

  const getData = async (cleanFilter?: boolean, noLoading?: boolean) => {
    if (!noLoading) {
      setLoading((old) => ({ ...old, ordersLoading: true }));
    }
    try {
      const filt = getFilterStorage(cleanFilter);
      const response = await getShippmentsService(user.user.id, filt);
      setShippmentsData(response.data);
      setRecords(response.count);
      setMenuShipCount(response.activeServices ?? 0);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
    } finally {
      setLoading((old) => ({ ...old, ordersLoading: false }));
    }
  };

  const getOneShippment = async (loading?: boolean) => {
    if (loading) {
      setLoading((old) => ({ ...old, oneOrder: true }));
    }
    try {
      if (handleIDOrder) {
        const response = await findOneShippment(handleIDOrder);
        setShippment(response);
      }
    } catch {
    } finally {
      setLoading((old) => ({ ...old, oneOrder: false }));
    }
  };

  const getUserProducts = async () => {
    try {
      const response = await getProductsListService(
        user?.user?.id,
        user?.suite[0]?.id,
        { key: "", offset: 0, searchTerm: "", itensPerPage: -1 }
      );
      return response.data;
    } catch {}
  };

  const submitOrder = async (info: CreateShippment) => {
    setLoading((old) => ({ ...old, submitDelivery: true }));
    try {
      await createShippmentService(info);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: strings.toastMessages.orderCreated[language],
      });
      setOpen(true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    } finally {
      setHandleIDOrder(null);
      getData(false, true);
      setLoading((old) => ({ ...old, submitDelivery: false }));
    }
  };

  const updateProductQTDinDelivery = async (data: any) => {
    try {
      await updateProductInShippment(data);
      getOneShippment();
      getData(false, true);
    } catch {}
  };

  const deleteProductFromDelivery = async (data: any) => {
    try {
      await deleteProductInShippment(data);
      getOneShippment();
      getData();
    } catch {}
  };

  const addProductToDelivery = async (data: any) => {
    try {
      await addProductInShippment(data);
      getOneShippment();
      getData();
    } catch {}
  };

  const deleteDelivery = async (deliverieID: number) => {
    try {
      await deleteShippment(deliverieID);
      getData();
    } catch {}
  };

  const uploadPaymentProof = async (files: any) => {
    try {
      await uploadServiceShippment(files, handleIDOrder ?? 0);
      getData(false);
      getOneShippment();
    } catch {}
  };

  const verifyPayment = async (chargeID: string, shippmentID: number) => {
    if (loading) {
      setLoading((old) => ({ ...old, oneOrder: true }));
    }
    try {
      if (handleIDOrder) {
        await verifyShippmentPayment(chargeID, shippmentID);
      }
    } catch {
    } finally {
      setLoading((old) => ({ ...old, oneOrder: false }));
      getOneShippment();
      getData();
    }
  };

  const sendNewMessage = async ({
    orderID,
    userID,
    description,
    type,
    messageType,
  }: INewMessage) => {
    if (!userID || !orderID) return;

    try {
      await sendNewMessageService({
        orderID,
        userID,
        description,
        type,
        messageType,
      });
      getOneShippment();
      getData(false, true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    }
  };

  const readAllMessages = async (orderID?: number, messageType?: string) => {
    if (!orderID) return;
    try {
      await readMessageService(orderID, messageType);

      getOneShippment();
      getData(false, true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    } finally {
    }
  };

  const fileRemoveAdmin = async (file: any, voucherID: number) => {
    try {
      setLoading((old) => ({ ...old, uploadLoading: true }));
      await deleteProofPaymentService(file, voucherID);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    } finally {
      setLoading((old) => ({ ...old, uploadLoading: false }));
      getOneShippment();
    }
  };

  const cleanFilters = () => {
    getFilterStorage(true);
    getData(true);
  };

  useEffect(() => {
    getOneShippment(true);
    // eslint-disable-next-line
  }, [handleIDOrder]);

  useEffect(() => {
    const interval = setInterval(() => {
      getOneShippment();
    }, 300000);

    if (!keepAttMessages) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [keepAttMessages]);

  useEffect(() => {
    const interval = setInterval(() => {
      getData(false, true);
    }, 600000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      pathname === "/treasurebox-app/admin/shippments" ||
      pathname === "/shippments"
    ) {
      if (getFilterStorage().searchTerm !== "") {
        getData();
      } else {
        getData(false, true);
      }
    }
    // eslint-disable-next-line
  }, [attData, pathname]);

  useEffect(() => {
    getData(false, false);

    // eslint-disable-next-line
  }, []);

  return (
    <ShippmentsContext.Provider
      value={{
        shippmentsDataMemo,
        setFilters,
        submitOrder,
        cleanFilters,
        updateProductQTDinDelivery,
        uploadPaymentProof,
        deleteProductFromDelivery,
        fileRemoveAdmin,
        addProductToDelivery,
        deleteDelivery,
        verifyPayment,
        loading,
        getOneShippment,
        setShippment,
        shippment,
        handleIDOrder,
        setHandleIDOrder,
        sendNewMessage,
        menuShipCount,
        records,
        getFilterStorage,
        setAttData,
        readAllMessages,
        setKeepAttMessages,
        getUserProducts,
      }}
    >
      {children}
    </ShippmentsContext.Provider>
  );
}

export { ShippmentsProvider, ShippmentsContext };

export default function useShippments() {
  return useContext(ShippmentsContext);
}
