import * as yup from "yup";
import { useTranslate } from "../../../../hooks/useTranslate";

export function Validation(language: "english" | "portuguese") {
  const { strings } = useTranslate();

  const validationSchema = yup.object({
    creditValue: yup
      .string()
      .required(strings.creditCards.pricePlaceHolder[language]),
    userID: yup.number().required("userid"),
    // files: yup
    //   .array()
    //   .test((val) => {
    //     if (!val) return false;
    //     if (val.length === 0) return false;
    //     return true;
    //   })
    //   .required(),
  });
  return { validationSchema };
}
