import { Box, IconButton, Typography } from "@mui/material";
import { blue, green, grey, red } from "@mui/material/colors";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
// import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import ApartmentIcon from "@mui/icons-material/Apartment";
import EmailIcon from "@mui/icons-material/Email";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { useNavigate } from "react-router-dom";
interface Props {
  infoColor?: string;
}

export function ContactInfo({ infoColor = "#FFF" }: Props) {
  const { isMobile } = useBreakpoints();
  const nav = useNavigate();
  return (
    <Box
      width={"100%"}
      display={"flex"}
      justifyContent={"space-between"}
      flexWrap={"wrap"}
      gap={1}
    >
      <Box display={"flex"} gap={1}>
        <IconButton
          sx={{
            background: green[500],
            "&: hover": { background: green[100] },
            width: 40,
            height: 40,
          }}
        >
          <a
            href="https://api.whatsapp.com/send?phone=8109012381547"
            target="_blank"
            rel="noreferrer"
            style={{ marginBottom: -5 }}
          >
            <WhatsAppIcon style={{ fill: "#FFF" }} />
          </a>
        </IconButton>

        {/* <IconButton
          sx={{
            background: "#FFF",
            "&: hover": { background: grey[300] },
            width: 40,
            height: 40,
          }}
        >
          <a
            href="https://www.facebook.com/treasureboxfigures"
            target="_blank"
            rel="noreferrer"
            style={{ marginBottom: -5 }}
          >
            <FacebookIcon style={{ fill: blue[500] }} />
          </a>
        </IconButton> */}

        <IconButton
          sx={{
            background: "#FFF",
            "&: hover": { background: grey[300] },
            width: 40,
            height: 40,
          }}
        >
          <a
            href="https://www.instagram.com/treasureboxjapan"
            target="_blank"
            rel="noreferrer"
            style={{ marginBottom: -5 }}
          >
            <InstagramIcon
              style={{
                fill: "#FFF",
                background:
                  "radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)",
                borderRadius: 5,
              }}
            />
          </a>
        </IconButton>

        <IconButton
          sx={{
            background: "#FFF",
            "&: hover": { background: grey[300] },
            width: 40,
            height: 40,
          }}
        >
          <a
            href="https://www.youtube.com/@TreasureBoxJapan"
            target="_blank"
            rel="noreferrer"
            style={{ marginBottom: -5 }}
          >
            <YouTubeIcon style={{ fill: red[500] }} />
          </a>
        </IconButton>
      </Box>

      <Box
        sx={{
          background: blue[900],
          borderRadius: 2,
          p: 2,
          mt: isMobile ? 2 : 0,
          height: 100,
        }}
      >
        <Typography color="#FFFFFF">愛知県公安委員会</Typography>
        <Typography color="#FFFFFF">第543842401200号</Typography>
        <Typography color="#FFFFFF">Treasure Box Japan合同会社</Typography>
        <Typography color="#FFFFFF">愛知県西尾市上町薬師前１−１２</Typography>
      </Box>

      <Box display={"flex"} flexDirection={"column"} gap={3} p={2}>
        <Typography display={"flex"} alignItems={"center"} color={infoColor}>
          <PhoneInTalkIcon style={{ fill: infoColor, marginRight: 10 }} />
          +81 090 1238 1547
        </Typography>

        <Typography display={"flex"} alignItems={"center"} color={infoColor}>
          <EmailIcon style={{ fill: infoColor, marginRight: 10 }} />
          treasurebox.noreply@gmail.com
        </Typography>

        <Typography
          display={"flex"}
          alignItems={"center"}
          color={infoColor}
          sx={{ cursor: "pointer", width: 268 }}
          onClick={() => nav("/about-us")}
        >
          <ApartmentIcon style={{ fill: infoColor, marginRight: 10 }} />
          Notation based on the Specific Commercial Transactions Law
        </Typography>

        <Typography
          display={"flex"}
          alignItems={"center"}
          color={infoColor}
          sx={{ cursor: "pointer", width: 268 }}
          onClick={() => nav("/terms-and-conditions")}
        >
          <AssignmentIcon style={{ fill: infoColor, marginRight: 10 }} />
          Terms and conditions
        </Typography>

        <Typography
          display={"flex"}
          alignItems={"center"}
          color={infoColor}
          sx={{ cursor: "pointer", width: 268 }}
          onClick={() => nav("/contact")}
        >
          <ContactMailIcon style={{ fill: infoColor, marginRight: 10 }} />
          Contact us
        </Typography>
        <Typography
          display={"flex"}
          alignItems={"center"}
          color={infoColor}
          sx={{ cursor: "pointer", width: 268 }}
          onClick={() => nav("/privacy-policy")}
        >
          <AdminPanelSettingsIcon
            style={{ fill: infoColor, marginRight: 10 }}
          />
          Privacy policy
        </Typography>
      </Box>
    </Box>
  );
}
