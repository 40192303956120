import { Box, Container, Typography } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/context/useAuth";

export function LoginLayout() {
  const { user, token } = useAuth();
  const navigate = useNavigate();

  const maintenance = process.env.REACT_APP_MAINTENANCE === "ON";

  if (maintenance) {
    navigate("/maintenance");
  }

  if (user && token) {
    if (!user?.user?.role || user?.user?.role === "admin") {
      navigate("/treasurebox-app/admin");
    } else {
      navigate("/treasurebox-app");
    }
  }

  const year = new Date().getFullYear();

  return (
    <Container maxWidth="lg">
      <Box
        minWidth={"100%"}
        minHeight={"100vh"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Outlet />
        <Typography textAlign={"center"} mt={10}>
          {String(year)} © Treasure Box
        </Typography>
      </Box>
    </Container>
  );
}
