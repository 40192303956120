import { Box, Fade } from "@mui/material";

export function FadeInContainer({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) {
  return (
    <Fade in={true} style={{ transformOrigin: "0 0 0" }} timeout={1000}>
      <Box>{children}</Box>
    </Fade>
  );
}
