import { Box, Container, Link, Typography } from "@mui/material";
import { FadeInContainer, TitleDivider } from "../../../Atoms";
import { useNavigate } from "react-router-dom";
import meninaEncomenda from "../../../../../assets/images/menina_com_encomenda.png";
import menincaComCelular from "../../../../../assets/images/menina_com_celular.png";
import eco1 from "../../../../../assets/images/economymode/1.jpg";
import shippmentOptions from "../../../../../assets/images/economymode/shippment_options.jpg";

import { grey } from "@mui/material/colors";

export function ServicesPrices() {
  const nav = useNavigate();

  return (
    <FadeInContainer>
      <Container maxWidth="lg">
        <TitleDivider title="Order Redirection" />

        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={3}
          mb={3}
        >
          <img src={meninaEncomenda} alt="girl" width={400} />
          <Typography textAlign={"center"}>
            We offer an address in Japan to our customers so they can shop at
            stores in Japan and around the world. We receive orders and ship
            them anywhere in the world, especially to Brazil.
          </Typography>

          <Typography textAlign={"center"}>
            Buy products in stores in Japan or anywhere in the world and send
            them to the address available in your account. We will receive your
            orders, take photos and place all the information in your suite. You
            then choose when and which products you want to send. Simple, easy
            and comfortable.
          </Typography>

          <Typography textAlign={"center"}>
            You follow everything through our system!
          </Typography>
          <Link
            component="button"
            variant="body2"
            underline="none"
            onClick={() => {
              nav("/login/new-user");
            }}
            fontSize={25}
          >
            Sign up, it's free.
          </Link>
        </Box>
      </Container>

      <Box sx={{ background: grey[100], width: "100%" }}>
        <Container maxWidth="lg" sx={{ pb: 2 }}>
          <TitleDivider title="Make an order" />
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={3}
            mb={3}
          >
            <img src={menincaComCelular} alt="girl" width={400} />
            <Typography textAlign={"center"}>
              Are you unable to make your purchases or don't have an
              international credit card? This service is for you!
            </Typography>

            <Typography textAlign={"center"}>
              Nothing more than, you send us the direct link of the desired
              product through the store's website. We click on each link and
              make the purchase for you here. When the product arrives, we take
              photos so you know exactly how your purchases arrive.
            </Typography>

            <Typography textAlign={"center"}>
              You no longer have to worry about country restrictions or payment
              blocks.
            </Typography>
            <Typography textAlign={"center"}>
              You follow everything through our system!
            </Typography>
            <Link
              component="button"
              variant="body2"
              underline="none"
              onClick={() => {
                nav("/login/new-user");
              }}
              fontSize={25}
            >
              Sign up, it's free.
            </Link>
          </Box>
        </Container>
      </Box>

      <Box sx={{ background: "transparent", width: "100%" }}>
        <Container maxWidth="lg" sx={{ pb: 2 }}>
          <TitleDivider title="Discover “economy mode”" />
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={3}
            mb={3}
          >
            <Typography textAlign={"center"}>
              By opting for this service, we remove the product from the box,
              remove excess cardboard and/or plastic blister and we try to
              minimize the volume of products inside the shipping box, With this
              you save on weight and volume, being able to buy much more while
              paying much less for shipping.
            </Typography>
            <img src={eco1} alt="eco1" width={"100%"} />
            <Typography textAlign={"center"}>
              You follow everything through our system!
            </Typography>
            <Link
              component="button"
              variant="body2"
              underline="none"
              onClick={() => {
                nav("/login/new-user");
              }}
              fontSize={25}
            >
              Sign up, it's free.
            </Link>
          </Box>
        </Container>
      </Box>

      <Box sx={{ background: grey[100], width: "100%" }}>
        <Container maxWidth="lg" sx={{ pb: 2 }}>
          <TitleDivider title="Shipping and Deadlines" />
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={3}
            mb={3}
          >
            <img src={shippmentOptions} alt="eco1" width={"100%"} />
            <Typography textAlign={"center"}>
              You follow everything through our system!
            </Typography>

            <Box>
              <Typography variant="h1" mb={1}>
                International Small Packet
              </Typography>
              <Typography p={1}>
                Simple shipping is the cheapest method, however it does not have
                detailed tracking and the delivery time can vary from 8 to 15
                business days. Furthermore, the weight limit is 2 kilos. We are
                not responsible for any packages sent using this method.
              </Typography>
            </Box>

            <Box>
              <Typography variant="h1" mb={1}>
                Priority Express International (EMS)
              </Typography>
              <Typography p={1}>
                Express shipping has full tracking and full insurance. The
                estimated deadline is 5 to 10 business days. All products are
                taxed with 100% certainty. We do not recommend sending more than
                10 pieces as upon arrival in Brazil you will be asked for your
                CNPJ and product invoice.
              </Typography>
            </Box>

            <Box>
              <Typography variant="h1" mb={1}>
                Ship - Internacional Parcel Post (Surface)
              </Typography>
              <Typography p={1}>
                Parcel post shipping is recommended for large or heavy products,
                has full tracking and full insurance. The estimated deadline is
                45 to 60 business days. This modality has a high chance of being
                taxed.
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </FadeInContainer>
  );
}
