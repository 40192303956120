import { useEffect, useRef, useState } from "react";
import pdfImage from "../../../../assets/images/file-pdf-solid-240.png";
import "./style.css";
import uploadImg from "../../../../assets/images/file-pdf-solid-240.png";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { CustomModal } from "../../Organisms";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import useAuth from "../../../../hooks/context/useAuth";

interface Props {
  onFileChange(val: any): void;
  oldFiles: any[];
  filesQtd?: number;
  fileRemoveAdmin?: any;
  disabledDeleteBTN?: boolean;
}

export function DropFileInput({
  onFileChange,
  oldFiles,
  filesQtd = 2,
  fileRemoveAdmin,
  disabledDeleteBTN = false,
}: Props) {
  const { user } = useAuth();
  const { isSmalldesktop } = useBreakpoints();
  const [modalOpen, setModalOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [fileList, setFileList] = useState([] as any);

  const wrapperRef = useRef(null) as any;

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  useEffect(() => {
    if (oldFiles.length > 0) {
      setFileList(oldFiles);
    }
  }, [oldFiles]);

  const onFileDrop = (e: any) => {
    const newFile = e.target.files[0];
    if (newFile) {
      const updatedList = [...fileList, newFile];
      setFileList(updatedList);
      onFileChange(updatedList);
    }
  };

  const fileRemove = (file: any) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    onFileChange(updatedList);
  };

  const fileRmByAdmin = async (file: any) => {
    await fileRemoveAdmin(file.code, file.id);
    fileRemove(file);
  };

  const downloadFile = async (url: string) => {
    try {
      // const down = await downloadService(code);

      setUrl(url);
      // window.open(url, "pdf show", "width=800,height=600");
      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", code);
      // document.body.appendChild(link);
      // link.click();
      setModalOpen(true);
    } catch {}
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "center",
      }}
    >
      {oldFiles?.length > 0 ? (
        <div className="drop-file-preview">
          {fileList.map((item: any, index: number) => (
            <div style={{ display: "flex" }} key={index}>
              <div
                key={index}
                className="drop-file-preview__item"
                onClick={() => downloadFile(item?.code)}
              >
                <img src={pdfImage} alt="" width={50} height={50} />
                <div className="drop-file-preview__item__info">
                  <p style={{ width: 150, wordBreak: "break-all" }}>
                    {item?.code}
                  </p>
                </div>
              </div>
              {user?.user?.role === "admin" && (
                <Button
                  onClick={() => fileRmByAdmin(item)}
                  aria-label="delete"
                  disabled={disabledDeleteBTN}
                >
                  <DeleteIcon />
                </Button>
              )}
            </div>
          ))}
        </div>
      ) : (
        <>
          <div
            ref={wrapperRef}
            className="drop-file-input"
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
          >
            <div className="drop-file-input__label">
              <img src={uploadImg} alt="" />
              <p>Click, Drag or Drop your files here - Max 500kb</p>
            </div>
            <input
              type="file"
              value=""
              accept="application/pdf"
              onChange={onFileDrop}
              disabled={fileList.length >= filesQtd ? true : false}
            />
          </div>
          <div>
            {fileList.length > 0 ? (
              <div className="drop-file-preview">
                {fileList.map((item: any, index: number) => (
                  <div key={index} className="drop-file-preview__item">
                    <img src={pdfImage} alt="" width={50} height={50} />
                    <div className="drop-file-preview__item__info">
                      <p style={{ width: 120, wordBreak: "break-all" }}>
                        {item.name}
                      </p>
                      <p>{(item.size / 1024).toFixed(1)} Kb</p>
                    </div>
                    <Button
                      onClick={() => fileRemove(item)}
                      aria-label="delete"
                      disabled={disabledDeleteBTN}
                    >
                      <DeleteIcon />
                    </Button>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </>
      )}
      <CustomModal
        openModal={modalOpen}
        setOpenModal={setModalOpen}
        width={isSmalldesktop ? "90%" : "60%"}
      >
        <div style={{ height: "100vh" }}>
          <embed src={url} width="100%" height="100%" type="application/pdf" />
        </div>
      </CustomModal>
    </div>
  );
}
