import { Box, Button, Typography } from "@mui/material";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import "./styles.css";

export function PrintComponent({
  productID,
  suiteID,
}: {
  productID: number;
  suiteID: number;
}) {
  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    bodyClass: "print",
    removeAfterPrint: true,
    copyStyles: true,
  });

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Button
        onClick={() => {
          handlePrint(null, () => contentToPrint?.current);
        }}
        variant="contained"
        sx={{ mr: 2 }}
      >
        PRINT
      </Button>
      <Box ref={contentToPrint} sx={{ display: "flex" }}>
        <Typography mr={1}>Product id: {productID}</Typography>
        <Typography>Suite: {suiteID}</Typography>
      </Box>
    </Box>
  );
}
