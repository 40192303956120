import { useState } from "react";
import {
  BoxWithTitle,
  ProductsCards,
} from "../../../components/AtomicDesign/Molecules";
import useAuth from "../../../hooks/context/useAuth";
import { useTranslate } from "../../../hooks/useTranslate";

import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { FadeInContainer } from "../../../components/AtomicDesign/Atoms";
import { Box } from "@mui/material";
import {
  CustomModal,
  NewProductBox,
} from "../../../components/AtomicDesign/Organisms";
import { useBreakpoints } from "../../../hooks/useBreakpoins";

import useProducts from "../../../hooks/context/useProducts";

export function UsersProducts() {
  const {
    cleanFilters,
    setHandleIDProduct,
    setAttData,
    records,
    productsData,
    loading,
    product,
    setProduct,
    productSolicitation,
    readAllMessages,
    sendNewMessage,
  } = useProducts();
  const { strings } = useTranslate();
  const { language } = useAuth();
  const { getFilterStorage, setFilters } = useLocalStorage();
  const { isSmalldesktop } = useBreakpoints();

  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
    setHandleIDProduct(null);
    setProduct({});
  };

  return (
    <FadeInContainer>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        alignContent={"center"}
        gap={5}
        p={1}
      >
        <BoxWithTitle
          title={strings.productsPage.title[language]}
          width="100%"
          childrenProps={{ height: "100%" }}
          padding={1}
          // filter

          hasFilter
          onSelectField={(val) => setFilters("key", val)}
          selectedField={getFilterStorage().key}
          onChangeTextField={(val) => {
            setFilters("searchTerm", val);
            setAttData((bool: boolean) => !bool);
          }}
          searchTerm={getFilterStorage().searchTerm}
          onClickCleanFilter={cleanFilters}
          // filter
          // pagnation
          hasPagination
          itensPerpage={(val) => {
            setFilters("itensPerPage", val);
            setAttData((bool: boolean) => !bool);
          }}
          itensPerPage={getFilterStorage().itensPerPage} // quantidade
          howManyPages={Math.ceil(records / getFilterStorage().itensPerPage)}
          onChangePage={(page) => {
            setFilters("offset", page - 1);
            setAttData((bool: boolean) => !bool);
          }}
          // pagnation
        >
          <ProductsCards
            productSolicitation={productSolicitation}
            data={productsData}
            onClickCredit={(val) => {
              setHandleIDProduct(val?.id);
              setModalOpen(true);
            }}
            isLoading={loading.productsLoading}
          />
        </BoxWithTitle>
      </Box>
      <CustomModal
        openModal={modalOpen}
        setOpenModal={closeModal}
        width={isSmalldesktop ? "90%" : "40%"}
      >
        <NewProductBox
          readAllMessages={readAllMessages}
          sendNewMessage={sendNewMessage}
          userList={[]}
          handleSubmit={() => {}}
          product={product}
          isFileLoading={false}
          isLoading={loading.oneOrder}
        />
      </CustomModal>
    </FadeInContainer>
  );
}
