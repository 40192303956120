import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export function CustomToolTip({
  text,
  children,
  placement = "bottom",
}: {
  text: string;
  children: React.ReactNode;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
}) {
  return (
    <BootstrapTooltip
      placement={placement}
      title={<Typography variant="h1">{text}</Typography>}
    >
      <Box>{children}</Box>
    </BootstrapTooltip>
  );
}
