import { useEffect, useState } from "react";
import {
  BoxContent,
  CircularLoading,
  CustomToolTip,
  FadeInContainer,
} from "../../../Atoms";
import { GenericInput } from "../../../Molecules";
import { Box, Button, List, ListItem, Typography } from "@mui/material";
import { LoadingConfigs } from "../../../../../pages";
import DeleteIcon from "@mui/icons-material/Delete";
import { useBreakpoints } from "../../../../../hooks/useBreakpoins";

type TermsAndConditionsConfigProps = {
  portuguese: string;
  english: string;
};

type NewTermsAndConditionsConfigProps = {
  portuguese: string;
  english: string;
  id: number;
};

interface Props {
  termsAndConditionsConfig: TermsAndConditionsConfigProps[];
  onSubmit(val: any): void;
  loading: LoadingConfigs;
}

export function TermsAndConditionsConfig({
  termsAndConditionsConfig,
  onSubmit,
  loading,
}: Props) {
  const { isMobile } = useBreakpoints();
  const [newTermsConfig, setNewTermsConfig] = useState(
    {} as TermsAndConditionsConfigProps
  );
  const [oldData, setOldData] = useState(
    [] as NewTermsAndConditionsConfigProps[]
  );

  const submit = () => {
    const payload = {
      termsAndConditions: [...oldData, newTermsConfig],
    };
    onSubmit(payload);
  };

  const removeOldData = (id: number) => {
    const filt = oldData.filter((item) => item.id !== id);
    setOldData(filt);
    const payload = {
      termsAndConditions: [...filt],
    };
    onSubmit(payload);
  };

  useEffect(() => {
    setOldData(
      termsAndConditionsConfig?.map((terms, index) => ({
        ...terms,
        id: index + 1,
      }))
    );
  }, [termsAndConditionsConfig]);

  return (
    <BoxContent config={{ mt: 1 }}>
      {loading.systemUse ? (
        <CircularLoading text="Atualizando" />
      ) : (
        <FadeInContainer>
          <Typography variant="h1" mb={2}>
            Terms and conditions
          </Typography>
          <Box
            display="flex"
            flexWrap={"wrap"}
            width={"100%"}
            gap={1}
            justifyContent={"center"}
          >
            {oldData?.map((system, index) => (
              <Box display="flex" width={isMobile ? "100%" : "40%"} key={index}>
                <Box
                  sx={{
                    border: "1px solid lightgray",
                    borderRadius: 5,
                    width: "100%",
                  }}
                  p={2}
                  display="flex"
                  justifyContent={"center"}
                  flexWrap={"wrap"}
                >
                  <List>
                    <ListItem
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        flexWrap: "wrap",
                      }}
                    >
                      <CustomToolTip placement="top" text={system?.portuguese}>
                        <Box>
                          <Typography>Português</Typography>
                          <Box display="flex">
                            <Typography
                              textAlign={"right"}
                              minWidth="30px"
                              mr={"5px"}
                            >
                              {index + 1}.
                            </Typography>
                            <Typography
                              sx={{ height: "80px", overflow: "hidden" }}
                            >
                              {system?.portuguese}
                            </Typography>
                          </Box>
                        </Box>
                      </CustomToolTip>

                      <CustomToolTip placement="top" text={system?.english}>
                        <Box>
                          <Typography>English</Typography>
                          <Box display="flex">
                            <Typography
                              textAlign={"right"}
                              minWidth="30px"
                              mr={"5px"}
                            >
                              {index + 1}.
                            </Typography>
                            <Typography
                              sx={{ height: "80px", overflow: "hidden" }}
                            >
                              {system?.english}
                            </Typography>
                          </Box>
                        </Box>
                      </CustomToolTip>
                    </ListItem>
                  </List>
                  <Button
                    onClick={() => removeOldData(system?.id)}
                    aria-label="delete"
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 1 }}
                  >
                    <DeleteIcon />
                  </Button>
                </Box>
              </Box>
            ))}
          </Box>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                border: "1px solid lightgray",
                borderRadius: 5,
                width: "90%",
                mt: 1,
                p: 2,
              }}
            >
              <Typography variant="h1" mt={2}>
                Novo campo
              </Typography>
              <GenericInput
                inputName={"portuguese"}
                onChange={(value) =>
                  setNewTermsConfig((old) => ({
                    ...old,
                    portuguese: value,
                    english: old?.english,
                  }))
                }
                placeholder={"Português"}
                hasIcon={false}
              />
              <GenericInput
                inputName={"english"}
                onChange={(value, name) =>
                  setNewTermsConfig((old) => ({
                    ...old,
                    portuguese: old?.portuguese,
                    english: value,
                  }))
                }
                placeholder={"English"}
                hasIcon={false}
              />
            </Box>
          </Box>
        </FadeInContainer>
      )}

      <Button
        variant="contained"
        onClick={submit}
        sx={{ mt: 1, width: isMobile ? "100%" : "auto" }}
      >
        Adicionar
      </Button>
    </BoxContent>
  );
}
