import { Box, Container, Tab, Tabs, Button } from "@mui/material";
import { amber, grey } from "@mui/material/colors";
import NEWTREASUREBOXLOGO from "../../../assets/images/NEWTREASUREBOXLOGO2.png";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../theme/colors";
import { useBreakpoints } from "../../../hooks/useBreakpoins";
import { useEffect, useState } from "react";

interface Props {
  activeTab: number;
  setActiveTab(tab: number): void;
  showtabs?: boolean;
}

// #2C3E50 blue 900
export function HeaderLP({ activeTab, setActiveTab, showtabs = true }: Props) {
  const nav = useNavigate();
  const { isSmalldesktop } = useBreakpoints();
  const [selectedColor, setColor] = useState("");

  useEffect(() => {
    setColor("#FFF");
  }, []);

  return (
    <Box sx={{ background: grey[100] }}>
      <Container maxWidth="lg" sx={{ p: 0 }}>
        <Box
          pt={3}
          pb={3}
          p={2}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <img
            src={NEWTREASUREBOXLOGO}
            width={180}
            alt="treasurebox logo"
            style={{ marginBottom: -10, marginTop: -9, cursor: "pointer" }}
            onClick={() => nav("/")}
          />
          <Button
            variant={"contained"}
            sx={{
              width: isSmalldesktop ? 150 : 200,
              height: 40,
              background: amber[500],
              "&:hover": { background: amber[200] },
            }}
            onClick={() => nav("/login")}
          >
            Login
          </Button>
        </Box>
        {showtabs && (
          <Tabs
            value={activeTab}
            onChange={(_e, value) => setActiveTab(value)}
            aria-label="disabled tabs example"
            centered
            variant={isSmalldesktop ? "scrollable" : "fullWidth"}
            TabIndicatorProps={{
              style: { background: amber[500], color: "red" },
            }}
            scrollButtons
            allowScrollButtonsMobile
            sx={{
              "& .Mui-selected": {
                background: colors.blue[900],
                borderRadius: "5px 5px 0 0",
                color: selectedColor,
              },
              "& .MuiTab-root": {
                fontSize: 11,
              },
            }}
          >
            <Tab label="HOME" value={0} />
            <Tab label="SERVICES AND PRICES" value={1} />
            <Tab label="FEE SIMULATION" value={2} />
            <Tab label="BEST STORES TO BUY" value={3} />
            <Tab label="CONTACT US" value={4} />
            <Tab label="FAQ" value={5} />
          </Tabs>
        )}
      </Container>
    </Box>
  );
}
