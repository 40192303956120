import { createTheme } from "@mui/material/styles";

export let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1000,
      xl: 1920,
    },
  },
});

theme.typography.h1 = {
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px",
  },
  fontFamily: "Roboto",
  fontWeight: 500,
};

theme.typography.h2 = {
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
  },
  fontFamily: "Roboto",
  fontWeight: 400,
};
