import { Button } from "@mui/material";
import { BoxContent, BreakpointBox, CircularLoading } from "../../Atoms";
import { GenericInput, GenericSelect } from "../../Molecules";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import treasureBoxLogo from "../../../../assets/images/NEWTREASUREBOXLOGO2.png";
import useAuth from "../../../../hooks/context/useAuth";
import { useTranslate } from "../../../../hooks/useTranslate";
import * as yup from "yup";
import { countriesWithZones } from "./data";

import { useFormik } from "formik";

import { useEffect } from "react";

interface Props {
  submitLoading: boolean;
  address?: IAddress | null;
  handleSubmit(val: EditCreateAddress): void;
  thisUserAlreadyHaveAddress: boolean;
}

export function EditCreateAddress({
  submitLoading,
  address,
  handleSubmit,
  thisUserAlreadyHaveAddress,
}: Props) {
  const { isMobile } = useBreakpoints();
  const { strings } = useTranslate();
  const { user, language } = useAuth();

  const validationSchema = yup.object({
    address: yup
      .string()
      .required(strings.validationFields.name.required[language]),
    adressNumber: yup
      .string()
      .required(strings.validationFields.addressNumber[language]),
    addressComplement: yup
      .string()
      .required(strings.validationFields.addressComplement[language]),
    city: yup.string().required(strings.validationFields.city[language]),
    state: yup.string().required(strings.validationFields.state[language]),
    postalCode: yup
      .string()
      .required(strings.validationFields.zipCode[language]),
    country: yup.string().required(strings.validationFields.country[language]),

    receiver: yup
      .string()
      .required(strings.validationFields.name.required[language]),
  });

  const formik = useFormik({
    initialValues: {
      address: "",
      adressNumber: "",
      addressComplement: "",
      addressNewComplement: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      ssn: "",
      receiver: "",
      globalZoneId: 0,
    },
    validationSchema,
    async onSubmit(values: any) {
      const globalZoneId = countriesWithZones.find(
        (country) => country.name === values.country
      )?.globalZoneId;

      if (address?.id) {
        handleSubmit({
          type: "update",
          addressID: address?.id,
          data: {
            ...values,
            globalZoneId,
          },
        });
      } else {
        handleSubmit({
          type: "create",
          userID: user?.user?.id,
          data: { ...values, globalZoneId },
        });
      }
    },
  });

  useEffect(() => {
    if (address) {
      formik.setFieldValue("address", address.address);
      formik.setFieldValue("adressNumber", address.adressNumber);
      formik.setFieldValue("addressComplement", address.addressComplement);
      formik.setFieldValue("city", address.city);
      formik.setFieldValue("state", address.state);
      formik.setFieldValue("postalCode", address.postalCode);
      formik.setFieldValue("country", address.country);
      formik.setFieldValue("ssn", address.ssn);
      formik.setFieldValue("receiver", address.receiver);
      formik.setFieldValue("globalZoneId", address.globalZoneId);
      formik.setFieldValue(
        "addressNewComplement",
        address.addressNewComplement
      );
    }
    if (!thisUserAlreadyHaveAddress) {
      formik.setFieldValue("ssn", user?.user?.ssn);
      formik.setFieldValue("receiver", user?.user?.name);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <BoxContent config={{ minWidth: "180px", p: 3 }}>
      <img src={treasureBoxLogo} width={150} alt="treasurebox logo" />
      {submitLoading ? (
        <CircularLoading />
      ) : (
        <>
          <BreakpointBox>
            <GenericInput
              hasIcon={false}
              onChange={(value, name) => formik.setFieldValue(name, value)}
              error={
                formik?.touched?.address && formik?.errors?.address
                  ? true
                  : false
              }
              errorMessage={
                formik?.touched?.address ? formik?.errors?.address : ""
              }
              value={formik?.values?.address}
              placeholder={strings.myAddress.labels.address[language]}
              inputName="address"
            />
            <GenericInput
              hasIcon={false}
              onChange={(value, name) => formik.setFieldValue(name, value)}
              error={
                formik?.touched?.adressNumber && formik?.errors?.adressNumber
                  ? true
                  : false
              }
              errorMessage={
                formik?.touched?.adressNumber
                  ? formik?.errors?.adressNumber
                  : ""
              }
              value={formik?.values?.adressNumber}
              placeholder={strings.myAddress.labels.number[language]}
              inputName="adressNumber"
              width={isMobile ? "100%" : "50%"}
            />
          </BreakpointBox>
          <BreakpointBox>
            <GenericInput
              hasIcon={false}
              onChange={(value, name) => formik.setFieldValue(name, value)}
              error={
                formik?.touched?.addressComplement &&
                formik?.errors?.addressComplement
                  ? true
                  : false
              }
              errorMessage={
                formik?.touched?.addressComplement
                  ? formik?.errors?.addressComplement
                  : ""
              }
              value={formik?.values?.addressComplement}
              placeholder={strings.myAddress.labels.addressComplement[language]}
              inputName="addressComplement"
            />
            <GenericInput
              hasIcon={false}
              onChange={(value, name) => formik.setFieldValue(name, value)}
              value={formik?.values?.addressNewComplement}
              placeholder={strings.myAddress.labels.complement[language]}
              inputName="addressNewComplement"
            />
          </BreakpointBox>
          <BreakpointBox>
            <GenericInput
              hasIcon={false}
              onChange={(value, name) => formik.setFieldValue(name, value)}
              error={
                formik?.touched?.city && formik?.errors?.city ? true : false
              }
              errorMessage={formik?.touched?.city ? formik?.errors?.city : ""}
              value={formik?.values?.city}
              placeholder={strings.myAddress.labels.city[language]}
              inputName="city"
              width={isMobile ? "100%" : "50%"}
            />
            <GenericInput
              hasIcon={false}
              onChange={(value, name) => formik.setFieldValue(name, value)}
              error={
                formik?.touched?.state && formik?.errors?.state ? true : false
              }
              errorMessage={formik?.touched?.state ? formik?.errors?.state : ""}
              value={formik?.values?.state}
              placeholder={strings.myAddress.labels.state[language]}
              inputName="state"
              width={isMobile ? "100%" : "50%"}
            />
          </BreakpointBox>
          <BreakpointBox>
            <GenericSelect
              name="country"
              onChange={(value, name) => formik.setFieldValue("country", value)}
              options={countriesWithZones.map((data) => ({
                label: data.name,
                value: data.name,
              }))}
              placeholder={strings.myAddress.labels.country[language]}
              value={formik?.values?.country}
              defaultValue={formik?.values?.country}
              error={
                formik?.touched?.country && formik?.errors?.country
                  ? true
                  : false
              }
              errorMessage={
                formik?.touched?.country ? formik?.errors?.country : ""
              }
              width={isMobile ? "100%" : "50%"}
            />
            {/* <GenericInput
          hasIcon={false}
          onChange={(value, name) => formik.setFieldValue(name, value)}
          error={
            formik?.touched?.country && formik?.errors?.country ? true : false
          }
          errorMessage={formik?.touched?.country ? formik?.errors?.country : ""}
          value={formik?.values?.country}
          placeholder={strings.myAddress.labels.country[language]}
          inputName="country"
          width={isMobile ? "100%" : "50%"}
        /> */}
            <GenericInput
              hasIcon={false}
              onChange={(value, name) => formik.setFieldValue(name, value)}
              error={
                formik?.touched?.postalCode && formik?.errors?.postalCode
                  ? true
                  : false
              }
              errorMessage={
                formik?.touched?.postalCode ? formik?.errors?.postalCode : ""
              }
              value={formik?.values?.postalCode}
              placeholder={strings.myAddress.labels.zipCode[language]}
              inputName="postalCode"
              width={isMobile ? "100%" : "50%"}
            />
          </BreakpointBox>
          <BreakpointBox>
            <GenericInput
              hasIcon={false}
              onChange={(value, name) => formik.setFieldValue(name, value)}
              error={formik?.touched?.ssn && formik?.errors?.ssn ? true : false}
              errorMessage={formik?.touched?.ssn ? formik?.errors?.ssn : ""}
              value={formik?.values?.ssn}
              placeholder={"CPF for Brazil"}
              inputName="ssn"
              width={isMobile ? "100%" : "50%"}
              disabled={!thisUserAlreadyHaveAddress}
            />
            <GenericInput
              hasIcon={false}
              onChange={(value, name) => formik.setFieldValue(name, value)}
              error={
                formik?.touched?.receiver && formik?.errors?.receiver
                  ? true
                  : false
              }
              errorMessage={
                formik?.touched?.receiver ? formik?.errors?.receiver : ""
              }
              value={formik?.values?.receiver}
              placeholder={strings.myAddress.labels.receiver[language]}
              inputName="receiver"
              width={isMobile ? "100%" : "50%"}
              disabled={!thisUserAlreadyHaveAddress}
            />
          </BreakpointBox>
          <Button
            sx={{ mt: 3 }}
            fullWidth
            variant="contained"
            onClick={() => formik.handleSubmit()}
          >
            {address?.id ? "Update" : "Create"}
          </Button>
        </>
      )}
    </BoxContent>
  );
}
