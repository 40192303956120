import { useEffect, useState } from "react";
import { BoxContent, CircularLoading, FadeInContainer } from "../../../Atoms";
import { GenericInput } from "../../../Molecules";
import { Box, Button, Typography } from "@mui/material";
import { LoadingConfigs } from "../../../../../pages";
import * as MaterialIcons from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { grey } from "@mui/material/colors";
import { useBreakpoints } from "../../../../../hooks/useBreakpoins";
import { CustomModal } from "../../Modal";
import { BasicCard } from "../../../Molecules/BasicCard";

type WorkHoursConfigProps = {
  icon: string;
  text: {
    portuguese: string;
    english: string;
  };
};

type NewWorkHoursConfigProps = {
  icon: string;
  text: {
    portuguese: string;
    english: string;
  };
  id: number;
};

interface Props {
  workHoursConfig: WorkHoursConfigProps[];
  onSubmit(val: any): void;
  loading: LoadingConfigs;
}

export function WorkHoursConfig({ workHoursConfig, onSubmit, loading }: Props) {
  const { isMobile } = useBreakpoints();
  const [newWorkHoursConfig, setWorkHoursConfig] = useState(
    {} as WorkHoursConfigProps
  );
  const [oldData, setOldData] = useState([] as NewWorkHoursConfigProps[]);
  const [openModal, setOpenModal] = useState(false);
  const [iconSearch, setIconSearch] = useState("");
  const submit = () => {
    const payload = {
      workHours: [...oldData, newWorkHoursConfig],
    };
    onSubmit(payload);
  };

  const getIcon = (iconName: any) => {
    const Icon = MaterialIcons[iconName as keyof typeof MaterialIcons];
    return Icon ? <Icon /> : <></>;
  };

  const removeOldData = (id: number) => {
    const filt = oldData.filter((item) => item.id !== id);
    setOldData(filt);
    const payload = {
      workHours: [...filt],
    };
    onSubmit(payload);
  };

  useEffect(() => {
    setOldData(workHoursConfig?.map((hr, index) => ({ ...hr, id: index + 1 })));
  }, [workHoursConfig]);

  const filterIcon = (name: string) => {
    const keys = Object.keys(MaterialIcons);
    if (!name) return keys;
    return keys.filter((icn) => icn.toLowerCase().includes(name.toLowerCase()));
  };

  return (
    <BoxContent>
      {loading.workHours ? (
        <CircularLoading text="Atualizando" />
      ) : (
        <FadeInContainer>
          <Typography variant="h1" mb={2}>
            Work Hours
          </Typography>
          <Box
            display="flex"
            flexWrap={"wrap"}
            width={"100%"}
            gap={1}
            justifyContent={"center"}
          >
            {oldData?.map((hours) => (
              <Box display="flex" width={isMobile ? "100%" : "30%"}>
                <Box
                  sx={{
                    border: "1px solid lightgray",
                    borderRadius: 5,
                    width: "100%",
                  }}
                  p={2}
                  display="flex"
                  justifyContent={"center"}
                  flexWrap={"wrap"}
                >
                  <Box mr={1}>
                    <Typography>English</Typography>
                    <BasicCard icon={hours?.icon} text={hours?.text?.english} />
                  </Box>
                  <Box>
                    <Typography>Português</Typography>
                    <BasicCard
                      icon={hours?.icon}
                      text={hours?.text?.portuguese}
                    />
                  </Box>
                  <Button
                    onClick={() => removeOldData(hours?.id)}
                    aria-label="delete"
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 1 }}
                  >
                    <DeleteIcon />
                  </Button>
                </Box>
              </Box>
            ))}
          </Box>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                border: "1px solid lightgray",
                borderRadius: 5,
                width: "79%",
                mt: 1,
                p: 2,
              }}
            >
              <Typography variant="h1" mt={2}>
                Novo campo
              </Typography>
              <GenericInput
                inputName={"portuguese"}
                onChange={(value) =>
                  setWorkHoursConfig((old) => ({
                    ...old,
                    text: { portuguese: value, english: old?.text?.english },
                  }))
                }
                placeholder={"Português"}
                hasIcon={false}
              />
              <GenericInput
                inputName={"english"}
                onChange={(value, name) =>
                  setWorkHoursConfig((old) => ({
                    ...old,
                    text: { portuguese: old?.text?.portuguese, english: value },
                  }))
                }
                placeholder={"English"}
                hasIcon={false}
              />
              <Box display="flex" alignItems={"center"} ml={1}>
                <Typography color={grey[500]} mr={2}>
                  Icon
                </Typography>
                {getIcon(newWorkHoursConfig?.icon)}
              </Box>

              <Button onClick={() => setOpenModal(true)} variant="contained">
                Click here to select icon
              </Button>
            </Box>
          </Box>
        </FadeInContainer>
      )}

      <Button
        variant="contained"
        onClick={submit}
        sx={{ mt: 1, width: isMobile ? "100%" : "auto" }}
      >
        Adicionar
      </Button>

      <CustomModal openModal={openModal} setOpenModal={setOpenModal}>
        <GenericInput
          inputName="search"
          onChange={(value) => setIconSearch(value)}
          placeholder="Search icon"
          hasIcon={false}
        />
        <Box display={"flex"} flexWrap={"wrap"}>
          {filterIcon(iconSearch).map((icn) => (
            <Button
              sx={{ color: "black" }}
              onClick={() => {
                setWorkHoursConfig((old) => ({
                  ...old,
                  icon: icn,
                }));
                setOpenModal(false);
                setIconSearch("");
              }}
            >
              {getIcon(icn)}
            </Button>
          ))}
        </Box>
      </CustomModal>
    </BoxContent>
  );
}
