export function useLocalStorage() {
  const getFilterStorage = (cleanFilter?: boolean) => {
    if (cleanFilter) {
      localStorage.setItem(
        "@filter",
        JSON.stringify({
          key: "",
          searchTerm: "",
          itensPerPage: 20,
          offset: 0,
        })
      );
    }

    const localFilter = localStorage.getItem("@filter");
    if (localFilter) {
      const parseFilter = JSON.parse(localFilter) as Filter;
      return {
        key: parseFilter.key,
        searchTerm: parseFilter.searchTerm,
        itensPerPage: parseFilter.itensPerPage,
        offset: parseFilter.offset,
      };
    } else {
      const defaultFilter = {
        key: "",
        searchTerm: "",
        itensPerPage: 20,
        offset: 0,
      };
      localStorage.setItem("@filter", JSON.stringify(defaultFilter));
      return defaultFilter;
    }
  };

  const setFilters = (
    filterKey: "key" | "searchTerm" | "itensPerPage" | "offset",
    value: string | number
  ) => {
    const localFilter = localStorage.getItem("@filter");
    if (localFilter) {
      const parseFilter = JSON.parse(localFilter) as Filter;
      localStorage.setItem(
        "@filter",
        JSON.stringify({ ...parseFilter, [filterKey]: value })
      );
    }
  };

  return { getFilterStorage, setFilters };
}
