import { Box, IconButton, List, ListItem, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { CustomModal } from "../Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { useTranslate } from "../../../../hooks/useTranslate";
import useAuth from "../../../../hooks/context/useAuth";

interface Props {
  handleChangeFiles(images: any): void;
  oldFiles?: { id: number; code: string }[];
  removeFileAdmin?(imageID: number): void;
  cleanFileState: boolean;
  disabled?: boolean;
}

export function DropImageZone({
  handleChangeFiles,
  oldFiles,
  removeFileAdmin,
  cleanFileState,
  disabled = false,
}: Props) {
  const { isMobile } = useBreakpoints();
  const { strings } = useTranslate();
  const { language } = useAuth();
  const [files, setFiles] = useState([] as any);
  const [openModal, setOpenModal] = useState(false);
  const [handleImage, setHandleImage] = useState<{
    index: number;
    type: "oldFiles" | "newFiles";
  } | null>(null);

  const onDrop = useCallback((acceptedFiles: any, rejectedFiles: any) => {
    if (acceptedFiles?.length) {
      setFiles((previousFiles: any) => [
        ...previousFiles,
        ...acceptedFiles.map((file: any) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        ),
      ]);
    }
  }, []);

  useEffect(() => {
    setFiles([]);
  }, [cleanFileState]);

  useEffect(() => {
    handleChangeFiles(files);
    // eslint-disable-next-line
  }, [files]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/*": [],
    },
    maxSize: 1024 * 1000,
    onDrop,
  });

  useEffect(() => {
    // Revoke the data uris to avoid memory leaks
    return () =>
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const removeFile = (name: string) => {
    setFiles((files: any) => files.filter((file: any) => file.name !== name));
  };

  return (
    <Box width={"95%"} p={0} mt={2} ml={-4.1}>
      {!disabled && (
        <>
          <Typography textAlign={"center"} variant="h1" mb={2}>
            {strings.dragDrop.files[language]}
          </Typography>
          <Box
            {...getRootProps()}
            sx={{
              cursor: "pointer",
              border: "1px dotted gray",
              borderRadius: 5,
              p: 2,
              width: "100%",
              bgcolor: isDragActive ? "lightgray" : "transparent",
            }}
          >
            <input {...getInputProps()} />
            <Typography textAlign={"center"}>
              {isDragActive ? (
                <p>{strings.dragDrop.title1[language]}</p>
              ) : (
                <p>{strings.dragDrop.title2[language]}</p>
              )}
            </Typography>
          </Box>
        </>
      )}
      {oldFiles && (
        <>
          <Typography variant="h2" textAlign={"center"} mt={2}>
            {strings.dragDrop.images[language]}
          </Typography>
          <List
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              gap: 1,
              mt: 1,
            }}
          >
            {oldFiles.map((file, index: number) => (
              <ListItem key={file?.code} sx={{ maxWidth: 120 }}>
                <img
                  src={file?.code}
                  alt={file?.code}
                  width={90}
                  height={90}
                  onClick={() => {
                    setHandleImage({ index, type: "oldFiles" });
                    setOpenModal(true);
                  }}
                  style={{ borderRadius: 5, cursor: "pointer" }}
                />
                {!disabled && (
                  <IconButton
                    aria-label="delete"
                    onClick={() => removeFileAdmin && removeFileAdmin(file?.id)}
                    sx={{
                      position: "absolute",
                      bgcolor: "red",
                      marginTop: "-80px",
                      marginLeft: "80px",
                      zIndex: 12651651651561561,
                      "&:hover": { bgcolor: "pink" },
                    }}
                  >
                    <DeleteIcon sx={{ fill: "white" }} />
                  </IconButton>
                )}
              </ListItem>
            ))}
          </List>
        </>
      )}

      {files.length > 0 && (
        <Typography variant="h2" textAlign={"center"}>
          {strings.dragDrop.ready[language]}
        </Typography>
      )}
      <List
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          gap: 1,
          mt: 1,
        }}
      >
        {files.map((file: any, index: number) => (
          <ListItem key={file.name} sx={{ maxWidth: 120 }}>
            <img
              src={file.preview}
              alt={file.name}
              width={90}
              height={90}
              onLoad={() => {
                URL.revokeObjectURL(file.preview);
              }}
              onClick={() => {
                setHandleImage({ index, type: "newFiles" });
                setOpenModal(true);
              }}
              style={{ borderRadius: 5, cursor: "pointer" }}
            />
            {!disabled && (
              <IconButton
                aria-label="delete"
                onClick={() => removeFile(file.name)}
                sx={{
                  position: "absolute",
                  bgcolor: "red",
                  marginTop: "-80px",
                  marginLeft: "80px",
                  zIndex: 12651651651561561,
                  "&:hover": { bgcolor: "pink" },
                }}
              >
                <DeleteIcon sx={{ fill: "white" }} />
              </IconButton>
            )}
          </ListItem>
        ))}
      </List>
      {handleImage !== null && (
        <CustomModal
          openModal={openModal}
          setOpenModal={(val) => {
            setOpenModal(val);
            setHandleImage(null);
          }}
          title=""
          width={"90%"}
        >
          <Box display="flex" justifyContent={"center"}>
            <img
              src={
                oldFiles && handleImage?.type === "oldFiles"
                  ? oldFiles[handleImage?.index]?.code
                  : URL.createObjectURL(
                      files[handleImage?.index as keyof typeof files]
                    )
              }
              alt={files[handleImage?.index as keyof typeof files]?.name}
              width={isMobile ? "100%" : "auto"}

              //   onLoad={() => {
              //     URL.revokeObjectURL(
              //       files[handleImage as keyof typeof files]?.preview
              //     );
              //   }}
            />
          </Box>
        </CustomModal>
      )}
    </Box>
  );
}
