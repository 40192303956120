import { Box, Typography, Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Link } from "react-router-dom";

import { useEffect, useState } from "react";

export function CookieWarning() {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const verifyPolicy = localStorage.getItem("@treasurebox-cookie");
    if (verifyPolicy) {
      setShowMessage(false);
    } else {
      setShowMessage(true);
    }
  }, []);

  if (!showMessage) {
    return <></>;
  }
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 5,
        background: grey[600],
        display: "Flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        p: 1,
      }}
    >
      <Typography textAlign={"center"} color="#FFFFFF" sx={{ mr: 2 }}>
        We are using cookies, by using our website, you agree to our{"  "}
        <Link to="/terms-and-conditions">Terms and Conditions.</Link>
      </Typography>
      <Button
        variant="contained"
        onClick={() => {
          localStorage.setItem("@treasurebox-cookie", "1");
          setShowMessage(false);
        }}
        sx={{ background: grey[800], "&:hover": { background: grey[400] } }}
      >
        Ok
      </Button>
    </Box>
  );
}
