import { Box, Container, Divider, Typography } from "@mui/material";
import { HeaderLP } from "../../../components/layouts/Header/HeaderLP";
import {
  FadeInContainer,
  TitleDivider,
} from "../../../components/AtomicDesign/Atoms";
import { useBreakpoints } from "../../../hooks/useBreakpoins";

export function AboutUs() {
  const { isSmalldesktop } = useBreakpoints();
  const options = [
    {
      title: "Legal name",
      description: "Treasure Box Japan合同会社",
    },
    {
      title: "Address",
      description: "愛知県西尾市上町薬師前１−１２",
    },
    {
      title: "Contact phone",
      description: "0563 754339",
    },
    {
      title: "Company email",
      description: "treasurebox.noreply@gmail.com",
    },
    {
      title: "Head of Operations",
      description: "Ferreira Barbosa Osvaldo",
    },
    {
      title: "Additional fees",
      description:
        "Delivery fee (varies from 400 to 1,800 yen/box), handling fee (payment at convenience store: 100 yen)",
    },
    {
      title: "Exchange and return policy",
      description:
        "<In case of returns/exchanges due to customer reasons> Products that have not yet been shipped: You can cancel your order by pressing the cancel button on the website. Products after shipment: For unopened products, if you contact our customer support center (phone number: 0563 754339) within 10 days of receiving the product, we will refund you at your own shipping cost or issue you a product of the same value or less. We will exchange it with We do not accept returns or exchanges for products that have been opened. <If the product is defective> We will refund you or exchange it for a new product at our shipping cost. First, contact the Customer Service Center (phone number: 0563 754339).",
    },
    {
      title: "Delivery times",
      description:
        "Orders will be processed within 3-5 business days. Products should arrive within 14 days and will be available as soon as you place your order.",
    },
    {
      title: "Accepted payment methods",
      description: "Credit cards or local bank transfer",
    },
    {
      title: "Payment period",
      description:
        "Credit card payments are processed immediately. Local bank transfers must be made within 3 days of the order date.",
    },
    {
      title: "Prices",
      description: "¥ 4,000 - Prices shown on each service",
    },
  ];

  return (
    <Box width={"100%"} mb={5}>
      <HeaderLP activeTab={0} setActiveTab={() => {}} showtabs={false} />
      <FadeInContainer>
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: isSmalldesktop ? -8 : -15,
            zIndex: 1515418518,
          }}
        >
          <TitleDivider title="All information about us" />
          <Typography sx={{ mb: 5 }}>
            Here you can see all information about or company, if you have any
            questions, please contat us.
          </Typography>
          <Typography
            sx={{
              mb: 5,
              fontSize: 20,
              fontWeight: 500,
              textDecoration: "underline",
            }}
          >
            Notation based on the Specific Commercial Transactions Law
          </Typography>

          <Box display={"flex"} flexDirection={"column"} gap={4}>
            {options.map(({ title, description }) => (
              <Box
                width="100%"
                display={"flex"}
                flexDirection={"column"}
                gap={4}
              >
                <Box display={"flex"} justifyContent={"space-between"} gap={4}>
                  <Typography sx={{ width: "15%" }}>{title}</Typography>
                  <Typography sx={{ textAlign: "justify", width: "80%" }}>
                    {description}
                  </Typography>
                </Box>
                <Divider />
              </Box>
            ))}
          </Box>
        </Container>
      </FadeInContainer>
    </Box>
  );
}
