import React from "react";
import {
  Box,
  Input,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { AccountCircle } from "@mui/icons-material";

interface Props {
  value?: string | number;
  onChange(value: string, name: string): void;
  error?: boolean;
  hasIcon?: boolean;
  inputName: string;
  placeholder: string;
  width?: string;
  errorMessage?: string | any;
  inputType?: "text" | "password" | "email" | "number" | "date";
  disabled?: boolean;
  startAdornment?: any;
  endAdornment?: any;
  defaultValue?: string | number;
}

export function GenericInput({
  value,
  onChange,
  error,
  hasIcon = true,
  inputName,
  placeholder,
  width = "100%",
  errorMessage,
  inputType = "text",
  disabled = false,
  startAdornment = null,
  endAdornment = null,
  defaultValue,
}: Props) {
  const onChangeValue = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = e.target as HTMLInputElement;
    if (inputName === "phone" || inputName === "ssn") {
      return onChange(target.value.replace(/\D/g, ""), target.name);
    }
    onChange(target.value, target.name);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: width,
      }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
      >
        {hasIcon && (
          <AccountCircle sx={{ color: "action.active", mr: 1, my: 0.5 }} />
        )}

        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
          <InputLabel htmlFor={`standard-adornment-${inputName}`}>
            {placeholder}
          </InputLabel>
          <Input
            endAdornment={endAdornment}
            id={`standard-adornment-${inputName}`}
            type={inputType}
            value={value}
            name={inputName}
            onChange={onChangeValue}
            error={error}
            disabled={disabled}
            startAdornment={startAdornment}
            defaultValue={defaultValue}
          />
        </FormControl>
      </Box>
      <FormHelperText error>{errorMessage}</FormHelperText>
    </Box>
  );
}
