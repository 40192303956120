import { Box, CardMedia, IconButton, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useState } from "react";

interface Props {
  images: { code: string }[];
}

export function CardImages({ images }: Props) {
  const [imageIndex, setImageIndex] = useState(0);

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={"100%"}
    >
      <IconButton
        onClick={() =>
          setImageIndex((i) => {
            if (i === 0) return 0;
            return i - 1;
          })
        }
        sx={{ height: 40 }}
      >
        <ArrowBackIosNewIcon />
      </IconButton>
      <Box>
        <CardMedia
          sx={{
            height: 180,
            width: 180,
          }}
          image={images[imageIndex]?.code}
          title="green iguana"
        />
        <Typography
          textAlign={"center"}
          mt={-3}
          color="white"
          sx={{ textShadow: "0 0 2px black" }}
        >
          {`${imageIndex + 1} / ${images?.length}`}
        </Typography>
      </Box>
      <IconButton
        onClick={() =>
          setImageIndex((i) => {
            if (i === images?.length - 1) return images?.length - 1;
            return i + 1;
          })
        }
        sx={{ height: 40 }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
}
