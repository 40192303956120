import React, { useState } from "react";
import {
  Box,
  Input,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { Lock, VisibilityOff, Visibility } from "@mui/icons-material";

interface Props {
  value: string;
  onChange(value: string): void;
  error?: boolean;
  hasIcon?: boolean;
  placeholder: string;
  errorMessage?: string;
}

export function PasswordField({
  value,
  onChange,
  error,
  hasIcon = true,
  placeholder,
  errorMessage,
}: Props) {
  const [showPassword, setShowPassword] = useState(false);

  const onChangeValue = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = e.target as HTMLInputElement;
    onChange(target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
      >
        {hasIcon && <Lock sx={{ color: "action.active", mr: 1, mt: 2 }} />}
        <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
          <InputLabel htmlFor="standard-adornment-password">
            {placeholder}
          </InputLabel>
          <Input
            fullWidth
            id="standard-adornment-password"
            type={showPassword ? "text" : "password"}
            onChange={onChangeValue}
            value={value}
            error={error}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((old) => !old)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
      <FormHelperText error>{errorMessage}</FormHelperText>
    </Box>
  );
}
