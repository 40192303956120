import { createContext, useContext, useEffect, useMemo, useState } from "react";
import useAuth from "./useAuth";
import { useLocalStorage } from "../useLocalStorage";
// import useToast from "./useToast";
import {
  getOneProductService,
  getProductsListService,
  solicitationService,
} from "../../services/productsService";
import useToast from "./useToast";
import { useTranslate } from "../useTranslate";
import {
  readMessageService,
  sendNewMessageService,
} from "../../services/messageService";

const ProductsContext = createContext({} as ProductProviderProps);

type Props = {
  children: React.ReactNode;
};

function ProductsProvider({ children }: Props) {
  const { getFilterStorage, setFilters } = useLocalStorage();
  const { getUserLocalStorage, language, getUser } = useAuth();
  const { strings } = useTranslate();
  const { setOpen, setToastData } = useToast();
  const { user } = getUserLocalStorage();
  const [productsData, setProductsData] = useState<Products[]>([]);
  const [product, setProduct] = useState({} as Products);
  const [handleIDProduct, setHandleIDProduct] = useState<number | null>();
  const [records, setRecords] = useState(0);
  const [attData, setAttData] = useState(false);
  const [loading, setLoading] = useState({
    uploadLoading: false,
    usersLoading: false,
    productsLoading: false,
    oneOrder: false,
  });
  const productsDataMemo = useMemo(() => productsData, [productsData]);

  const getData = async (cleanFilter?: boolean, noLoading?: boolean) => {
    if (!noLoading) {
      setLoading((old) => ({ ...old, productsLoading: true }));
    }

    try {
      const filt = getFilterStorage(cleanFilter);
      const response = await getProductsListService(
        user?.user?.id,
        user?.suite[0]?.id,
        filt
      );
      setProductsData(response.data);
      setRecords(response.count);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
    } finally {
      setLoading((old) => ({ ...old, productsLoading: false }));
    }
  };

  const getOneProduct = async (loading?: boolean) => {
    if (loading) {
      setLoading((old) => ({ ...old, oneOrder: true }));
    }
    try {
      if (handleIDProduct) {
        const response = await getOneProductService(handleIDProduct);
        setProduct(response);
      }
    } catch {
    } finally {
      setLoading((old) => ({ ...old, oneOrder: false }));
    }
  };

  const productSolicitation = async (
    info: any,
    solicitationType: {
      text: "removePackage" | "takeNewPhotos" | "";
      price: number;
    }
  ) => {
    if (
      Number(user?.money) === 0 ||
      Number(user?.money) - solicitationType.price < 0
    ) {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.packageError[language],
      });
      return setOpen(true);
    }
    try {
      await solicitationService(info.data, solicitationType.text);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: strings.toastMessages.orderCreated[language],
      });
      setOpen(true);
      getUser(user?.user?.id);
    } catch (e: any) {
      if (e?.response?.data?.message === "Already solicited") {
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "error",
          message: strings.toastMessages.alreadySolicited[language],
        });
      } else if (e?.response?.data?.message === "no stock") {
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "error",
          message: strings.toastMessages.noStock[language],
        });
      } else {
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "error",
          message: strings.toastMessages.genericError[language],
        });
      }
      setOpen(true);
    } finally {
      getData(false, true);
      getOneProduct();
    }
  };

  const sendNewMessage = async ({
    orderID,
    userID,
    description,
    type,
    messageType,
  }: INewMessage) => {
    if (!userID || !orderID) return;

    try {
      await sendNewMessageService({
        orderID,
        userID,
        description,
        type,
        messageType,
      });
      getOneProduct();
      getData(false, true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    }
  };

  const readAllMessages = async (orderID?: number) => {
    if (!orderID) return;
    try {
      await readMessageService(orderID, "product");

      getOneProduct();
      getData(false, true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    } finally {
    }
  };

  const cleanFilters = () => {
    getFilterStorage(true);
    getData(true);
  };

  useEffect(() => {
    getOneProduct(true);
    // eslint-disable-next-line
  }, [handleIDProduct]);

  useEffect(() => {
    if (getFilterStorage().searchTerm !== "") {
      getData();
    } else {
      getData(false, false);
    }
    // eslint-disable-next-line
  }, [attData]);

  return (
    <ProductsContext.Provider
      value={{
        productSolicitation,
        productsDataMemo,
        sendNewMessage,
        readAllMessages,
        setFilters,
        cleanFilters,
        productsData,
        loading,
        handleIDProduct,
        setHandleIDProduct,
        setAttData,
        getFilterStorage,
        records,
        product,
        getOneProduct,
        setProduct,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
}

export { ProductsProvider, ProductsContext };

export default function useProducts() {
  return useContext(ProductsContext);
}
