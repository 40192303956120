import { createContext, useContext, useState } from "react";
import { IUseToastContext, ToastData } from "../../types/useToast";

const ToastContext = createContext({} as IUseToastContext);

type ToastProviderProps = {
  children: React.ReactNode;
};

function ToastProvider({ children }: ToastProviderProps) {
  const [open, setOpen] = useState(false);
  const [toastData, setToastData] = useState<ToastData>({
    message: "",
    duration: 5000,
    type: "success",
    anchor: { vertical: "top", horizontal: "center" },
  });

  return (
    <ToastContext.Provider value={{ setOpen, open, toastData, setToastData }}>
      {children}
    </ToastContext.Provider>
  );
}

export { ToastProvider, ToastContext };

export default function useToast() {
  return useContext(ToastContext);
}
