import { createContext, useContext, useEffect, useMemo, useState } from "react";
import useAuth from "./useAuth";
import {
  getOneOrdersToBuy,
  getOrdersAdminToBuy,
  updateOrderAdmin,
  verifyOrderToBuyPayment,
} from "../../services/orderToBuyService";
import { deleteProofPaymentService } from "../../services/uploadFiles";
import {
  readMessageService,
  sendNewMessageService,
} from "../../services/messageService";
import { useLocalStorage } from "../useLocalStorage";
import useToast from "./useToast";
import { useTranslate } from "../useTranslate";
import { useLocation } from "react-router-dom";

const OrdersAdminContext = createContext({} as OrdersAdminContextProps);

type OrderProviderProps = {
  children: React.ReactNode;
};

function OrdersAdminProvider({ children }: OrderProviderProps) {
  const { pathname } = useLocation();
  const { getFilterStorage, setFilters } = useLocalStorage();
  const { getUserLocalStorage, language } = useAuth();
  const { setOpen, setToastData } = useToast();
  const { strings } = useTranslate();
  const { user } = getUserLocalStorage();
  const [ordersData, setOrdersData] = useState<IOrderToBuy[]>([]);
  const [order, setOrder] = useState<IOrderToBuy>({} as IOrderToBuy);
  const [handleIDOrder, setHandleIDOrder] = useState<number | null>();
  const [records, setRecords] = useState(0);
  const [attData, setAttData] = useState(false);
  const [loading, setLoading] = useState({
    uploadLoading: false,
    ordersLoading: false,
    oneOrder: false,
  });
  const [keepAttMessages, setKeepAttMessages] = useState(false);
  const [menuOrdersCount, setMenuOrdersCount] = useState(0);
  const ordersDataMemo = useMemo(() => ordersData, [ordersData]);

  const getDataAdmin = async (cleanFilter?: boolean, noLoading?: boolean) => {
    if (!noLoading) {
      setLoading((old) => ({ ...old, ordersLoading: true }));
    }

    try {
      const filt = getFilterStorage(cleanFilter);
      const response = await getOrdersAdminToBuy(user?.user?.id, filt);
      setOrdersData(response.data);
      setRecords(response.count);
      setMenuOrdersCount(response.activeServices ?? 0);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
    } finally {
      setLoading((old) => ({ ...old, ordersLoading: false }));
    }
  };

  const getOneOrder = async (loading?: boolean) => {
    if (loading) {
      setLoading((old) => ({ ...old, oneOrder: true }));
    }
    try {
      if (handleIDOrder) {
        const response = await getOneOrdersToBuy(handleIDOrder);
        setOrder(response);
      }
    } catch {
    } finally {
      setLoading((old) => ({ ...old, oneOrder: false }));
    }
  };

  const submitOrder = async (info: any) => {
    try {
      await updateOrderAdmin(info.data);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: strings.toastMessages.orderCreated[language],
      });
      setOpen(true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    } finally {
      getDataAdmin(false, true);
      getOneOrder();
    }
  };

  const fileRemoveAdmin = async (file: any, voucherID: number) => {
    try {
      setLoading((old) => ({ ...old, uploadLoading: true }));
      await deleteProofPaymentService(file, voucherID);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    } finally {
      setLoading((old) => ({ ...old, uploadLoading: false }));
      getOneOrder();
    }
  };

  const sendNewMessage = async ({
    orderID,
    userID,
    description,
    type,
    messageType,
  }: INewMessage) => {
    if (!userID || !orderID) return;

    try {
      await sendNewMessageService({
        orderID,
        userID,
        description,
        type,
        messageType,
      });
      getOneOrder();
      getDataAdmin(false, true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    }
  };

  const readAllMessages = async (orderID: number) => {
    try {
      await readMessageService(orderID);

      getOneOrder();
      getDataAdmin(false, true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    } finally {
    }
  };

  const verifyPayment = async (chargeID: string, orderID: number) => {
    if (loading) {
      setLoading((old) => ({ ...old, oneOrder: true }));
    }
    try {
      if (handleIDOrder) {
        const response = await verifyOrderToBuyPayment(chargeID, orderID);
        setOrder(response);
      }
    } catch {
    } finally {
      setLoading((old) => ({ ...old, oneOrder: false }));
      getOneOrder();
      getDataAdmin();
    }
  };

  const cleanFilters = () => {
    getFilterStorage(true);
    getDataAdmin(true);
  };

  useEffect(() => {
    getOneOrder(true);
    // eslint-disable-next-line
  }, [handleIDOrder]);

  useEffect(() => {
    const interval = setInterval(() => {
      getOneOrder();
    }, 30000);

    if (!keepAttMessages) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [keepAttMessages]);

  useEffect(() => {
    let interval = undefined as any;
    if (pathname === "/treasurebox-app/admin") {
      interval = setInterval(() => {
        getDataAdmin(false, true);
      }, 30000);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    if (pathname === "/treasurebox-app/admin") {
      if (getFilterStorage().searchTerm !== "") {
        getDataAdmin();
      } else {
        getDataAdmin(false, true);
      }
    }
    // eslint-disable-next-line
  }, [attData, pathname]);

  useEffect(() => {
    getDataAdmin(false, false);

    // eslint-disable-next-line
  }, []);

  return (
    <OrdersAdminContext.Provider
      value={{
        verifyPayment,
        ordersDataMemo,
        setFilters,
        submitOrder,
        cleanFilters,
        fileRemoveAdmin,
        loading,
        getOneOrder,
        menuOrdersCount,
        order,
        handleIDOrder,
        setHandleIDOrder,
        sendNewMessage,
        setAttData,
        getFilterStorage,
        records,
        readAllMessages,
        setKeepAttMessages,
        setOrder,
      }}
    >
      {children}
    </OrdersAdminContext.Provider>
  );
}

export { OrdersAdminProvider, OrdersAdminContext };

export default function useOrdersAdmin() {
  return useContext(OrdersAdminContext);
}
