import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, FormHelperText } from "@mui/material";

interface Props {
  onChangeDate(date: any): any;
  date?: Date;
  label: string;
  errorMessage?: any;
  error?: boolean;
  disabled?: boolean;
}

export default function CustomDatePicker({
  onChangeDate,
  date = new Date(),
  errorMessage,
  error,
  label,
  disabled = false,
}: Props) {
  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            label={label}
            format="dd/MM/yyyy"
            onChange={onChangeDate}
            value={date}
            disabled={disabled}
          />
        </DemoContainer>
      </LocalizationProvider>
      {error && <FormHelperText error>{errorMessage}</FormHelperText>}
    </Box>
  );
}
