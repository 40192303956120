import { Button } from "@mui/material";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import useToast from "../../../../hooks/context/useToast";
import { useTranslate } from "../../../../hooks/useTranslate";
import useAuth from "../../../../hooks/context/useAuth";
import { CustomToolTip } from "../../Atoms";

export function CopyClipboardBTN({ text }: { text: string }) {
  const { setOpen, setToastData } = useToast();
  const { strings } = useTranslate();
  const { language } = useAuth();

  const copyContent = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: strings.toastMessages.copy[language],
      });
      setOpen(true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: strings.toastMessages.copyError[language],
      });
      setOpen(true);
    }
  };

  return (
    <CustomToolTip text={strings.tooltips.copyAddress[language]}>
      <Button variant="contained" onClick={copyContent} sx={{ height: "100%" }}>
        <FileCopyOutlinedIcon />
      </Button>
    </CustomToolTip>
  );
}
