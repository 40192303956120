import { Box, Container, Typography } from "@mui/material";
import { useBreakpoints } from "../../../hooks/useBreakpoins";
import { HeaderLP } from "../../../components/layouts/Header/HeaderLP";
import {
  FadeInContainer,
  TitleDivider,
} from "../../../components/AtomicDesign/Atoms";
import { data } from "./data";

export function PrivacyPolicy() {
  const { isSmalldesktop } = useBreakpoints();

  const fontSizeTitle = isSmalldesktop ? 20 : 25;
  const fontSizeSubTitle = isSmalldesktop ? 15 : 20;
  const fontSizeText = isSmalldesktop ? 12 : 15;

  return (
    <Box width={"100%"} mb={18}>
      <HeaderLP activeTab={0} setActiveTab={() => {}} showtabs={false} />
      <FadeInContainer>
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: "column",

            mb: isSmalldesktop ? -8 : -15,
            zIndex: 1515418518,
          }}
        >
          <TitleDivider title="Privacy & Cookies Policy" />

          {data.map(({ text, type }) => {
            if (type === "title") {
              return (
                <Typography
                  fontSize={fontSizeTitle}
                  mb={3}
                  mt={3}
                  textAlign={"center"}
                >
                  {text}
                </Typography>
              );
            }
            if (type === "subtitle") {
              return (
                <Typography
                  fontSize={fontSizeSubTitle}
                  textAlign={"left"}
                  mt={3}
                  mb={1}
                >
                  {text}
                </Typography>
              );
            }
            if (type === "text") {
              return (
                <Typography
                  fontSize={fontSizeText}
                  textAlign={"left"}
                  mb={0.5}
                  mt={0.5}
                >
                  {text}
                </Typography>
              );
            }
            if (type === "JSON") {
              return (
                <div
                  dangerouslySetInnerHTML={{ __html: text }}
                  style={{ margin: "5px 5px" }}
                />
              );
            }
            return <></>;
          })}

          <Typography textAlign={"right"} mt={4} mb={2}>
            Promulgated on 11/01/2023
          </Typography>
          <Typography textAlign={"left"} mt={2} mb={2}>
            Treasure Box Japan合同会社
          </Typography>
          <Typography textAlign={"right"} mt={2} mb={2}>
            Executive Director: Barbosa Osvaldo
          </Typography>
        </Container>
      </FadeInContainer>
    </Box>
  );
}
