import { api } from "./api";

export const updateUserServiceAdmin = async (data: IUpdateUserDTO) => {
  const response2 = await api.post("/update-user-admin", { data });
  return response2.data;
};

export const getUserServiceAdmin = async (filter: {
  searchKey: string;
  searchTerm: string;
  itensPerPage: number;
  offset: number;
}) => {
  const response2 = await api.get("/users-admin", { params: { ...filter } });
  return response2.data;
};

export const getDashboard = async (userID: number) => {
  const response2 = await api.get("/dashboard", { params: { userID } });
  return response2.data;
};
