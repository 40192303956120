import { Box } from "@mui/material";
import { BoxWithTitle } from "../../Molecules";
import { BasicCard } from "../../Molecules/BasicCard";
import { colors } from "../../../../theme/colors";

interface Props {
  services: any[];
  boxTitle: string;
  width?: string;
}

export function BoxInfoCards({ services, boxTitle, width = "92.5%" }: Props) {
  return (
    <Box sx={{ borderRadius: "5px", width, bgcolor: colors.white[500] }}>
      <BoxWithTitle
        title={boxTitle}
        childrenProps={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
        }}
      >
        {services.map((item, index) => (
          <BasicCard key={index} icon={item.icon} text={item.text} />
        ))}
      </BoxWithTitle>
    </Box>
  );
}
