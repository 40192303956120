import { Box } from "@mui/material";
import { FadeInContainer } from "../../../components/AtomicDesign/Atoms";
import {
  BoxWithTitle,
  OrderToBuyCards,
  SelectOrderType,
} from "../../../components/AtomicDesign/Molecules";
import {
  CustomModal,
  NewOrderToBuyBox,
} from "../../../components/AtomicDesign/Organisms";
import { useState } from "react";
import { useBreakpoints } from "../../../hooks/useBreakpoins";

import useOrders from "../../../hooks/context/useOrders";
import { useTranslate } from "../../../hooks/useTranslate";
import useAuth from "../../../hooks/context/useAuth";

export function ClientNewOrder() {
  const {
    cleanFilters,
    ordersDataMemo,
    setFilters,
    submitOrder,
    handleIDOrder,
    setHandleIDOrder,
    setOrder,
    records,
    getFilterStorage,
    setAttData,
    loading,
    verifyPayment,
  } = useOrders();
  const { strings } = useTranslate();
  const { language } = useAuth();
  const { isSmalldesktop } = useBreakpoints();
  const [modalOpen, setModalOpen] = useState(false);
  const [orderType, setOrderType] = useState<OrderType>(null);

  const closeModal = () => {
    setOrder({} as IOrderToBuy);
    setModalOpen(false);
    setHandleIDOrder(null);
    setOrderType(null);
  };

  return (
    <FadeInContainer>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        alignContent={"center"}
        gap={5}
        p={1}
      >
        <BoxWithTitle
          title={strings.newOrderPage.title[language]}
          width="100%"
          childrenProps={{ height: "100%" }}
          padding={1}
          // right button
          hasButton
          onClick={() => setModalOpen(true)}
          buttonTitle={strings.buttons.newServiceBox[language]}
          // right button
          // filter
          hasFilter
          onSelectField={(val) => setFilters("key", val)}
          selectedField={getFilterStorage().key}
          onChangeTextField={(val) => {
            setFilters("searchTerm", val);
            setAttData((bool: boolean) => !bool);
          }}
          searchTerm={getFilterStorage().searchTerm}
          onClickCleanFilter={cleanFilters}
          // filter
          // pagnation
          hasPagination
          itensPerpage={(val) => {
            setFilters("itensPerPage", val);
            setAttData((bool: boolean) => !bool);
          }}
          itensPerPage={getFilterStorage().itensPerPage} // quantidade
          howManyPages={Math.ceil(records / getFilterStorage().itensPerPage)}
          onChangePage={(page) => {
            setFilters("offset", page - 1);
            setAttData((bool: boolean) => !bool);
          }}
          // pagnation
        >
          <OrderToBuyCards
            data={ordersDataMemo}
            onClickOrder={(order) => {
              setHandleIDOrder(order.id);
              setOrderType(order.orderType);
              setModalOpen(true);
            }}
            isLoading={loading.ordersLoading}
          />
        </BoxWithTitle>
      </Box>
      <CustomModal
        openModal={modalOpen}
        setOpenModal={closeModal}
        width={isSmalldesktop ? "90%" : "40%"}
        {...(orderType &&
          !handleIDOrder && {
            turnBack: () => setOrderType(null),
          })}
      >
        {!orderType ? (
          <SelectOrderType setOrderType={setOrderType} />
        ) : (
          <NewOrderToBuyBox
            orderType={orderType}
            verifyPayment={(chargeID, orderID) =>
              verifyPayment(chargeID, orderID)
            }
            handleSubmit={(data) => {
              if (data.type === "create") {
                closeModal();
              }
              submitOrder(data);
              // closeModal();
            }}
            orderID={handleIDOrder}
            isFileLoading={loading.uploadLoading}
            isLoading={loading.oneOrder}
          />
        )}
      </CustomModal>
    </FadeInContainer>
  );
}
