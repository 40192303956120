import { api } from "./api";

export const uploadService = async (
  data: any[],
  suite: number,
  creditPaidAll: boolean,
  orderID?: number
) => {
  // await new Promise((resolve, reject) => {
  try {
    if (creditPaidAll) {
      await api.post("/update-order-to-buy", {
        data: { id: orderID, status: "paymentSent" },
      });
    }
    if (!creditPaidAll && data.length === 0) {
      throw new Error("fileMissing");
    }
    await Promise.all(
      data.map(async (item) => {
        await api.post(
          "/upload",
          { file: item, orderID },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      })
    );
    // resolve(true);
  } catch (err: any) {
    throw new Error(err);
  }
  // });
};

export const downloadService = async (fileName: string) => {
  const download = api.get(
    "/download",

    { params: { fileName }, responseType: "arraybuffer" }
  );
  return download;
};

export const deleteProofPaymentService = async (
  fileName: string,
  id: number
) => {
  const deleteProofPay = api.delete("/delete-proof-payment", {
    params: { fileName, id },
  });
  return deleteProofPay;
};
