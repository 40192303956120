import { Box } from "@mui/material";
import { HeaderLP } from "../../components/layouts/Header/HeaderLP";
import { useState } from "react";
import {
  BestStoreToBuy,
  ContactUS,
  FAQ,
  Home,
  ServicesPrices,
  ShippingSimulator,
} from "../../components/AtomicDesign/Organisms";

export function LandingPage() {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <Box width={"100%"}>
      <HeaderLP activeTab={activeTab} setActiveTab={setActiveTab} />

      {activeTab === 0 && <Home />}
      {activeTab === 1 && <ServicesPrices />}
      {activeTab === 2 && <ShippingSimulator />}
      {activeTab === 3 && <BestStoreToBuy />}
      {activeTab === 4 && <ContactUS />}
      {activeTab === 5 && <FAQ />}
    </Box>
  );
}
