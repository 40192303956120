import { useEffect, useState } from "react";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { CustomModal } from "../Modal";
import axios from "axios";
import { CircularLoading } from "../../Atoms";
import { Box, Divider, Typography } from "@mui/material";
import { UseDateTime } from "../../../../hooks/useDateTime";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import ArchiveIcon from "@mui/icons-material/Archive";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PublicIcon from "@mui/icons-material/Public";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import ApartmentIcon from "@mui/icons-material/Apartment";

import {
  amber,
  blue,
  green,
  lightBlue,
  orange,
  red,
  teal,
} from "@mui/material/colors";

interface Props {
  setOpen(): void;
  open: boolean;
  trackingCode: any;
}

export function TrackingPackage({ trackingCode, open, setOpen }: Props) {
  const { formatedDate } = UseDateTime();
  const { isSmalldesktop } = useBreakpoints();
  const [history, setHistory] = useState({} as TrackingHistory);
  const [loading, setLoading] = useState(false);

  const getTrackingHistory = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `https://api.linketrack.com/track/json?user=darkdanielr@gmail.com&token=f4dae8eecee05bcbc3a0ad18cf7b469ae469145af5ce905bff4c597bfe2feee1&codigo=${trackingCode}`
      );
      setHistory(data);
    } catch {
      setHistory({} as TrackingHistory);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (trackingCode && open) {
      getTrackingHistory();
    }
    // eslint-disable-next-line
  }, [trackingCode, open]);

  const setTime = (date: string, time: string) => {
    const splitDate = date.split("/");
    const splitTime = time.split(":");
    const newDate = new Date();
    newDate.setDate(Number(splitDate[0]));
    newDate.setMonth(Number(splitDate[1]) - 1);
    newDate.setFullYear(Number(splitDate[2]));

    newDate.setHours(Number(splitTime[0]));
    newDate.setMinutes(Number(splitTime[1]));
    newDate.setSeconds(Number(splitTime[2]));

    return newDate;
  };

  const statusByIcon = {
    "Objeto postado": <MarkunreadMailboxIcon />,
    "Objeto recebido na unidade de exportação no país de origem": (
      <ArchiveIcon style={{ fill: blue[500] }} />
    ),
    "Objeto encaminhado": <LocalShippingIcon style={{ fill: amber[300] }} />,
    "Objeto recebido pelos Correios do Brasil": (
      <PublicIcon style={{ fill: green[300] }} />
    ),
    "Liberado sem tributação": <MoneyOffIcon style={{ fill: teal[200] }} />,
    "Aguardando pagamento do despacho postal": (
      <RequestQuoteIcon style={{ fill: orange[800] }} />
    ),
    "Pagamento confirmado": <PriceCheckIcon style={{ fill: teal[200] }} />,
    "Objeto saiu para entrega ao destinatário": (
      <DeliveryDiningIcon style={{ fill: green[900] }} />
    ),
    "Objeto entregue ao destinatário": (
      <Inventory2Icon style={{ fill: lightBlue[200] }} />
    ),
    "A entrega não pode ser efetuada - Carteiro não atendido": (
      <PersonOffIcon style={{ fill: red[500] }} />
    ),
    "Objeto aguardando retirada no endereço indicado": <ApartmentIcon />,
  };

  return (
    <CustomModal
      title="History"
      openModal={open}
      setOpenModal={setOpen}
      width={isSmalldesktop ? "90%" : "30%"}
    >
      {loading ? (
        <CircularLoading />
      ) : (
        history?.eventos?.length > 0 &&
        history?.eventos?.map((history, key) => (
          <Box key={key}>
            <Box sx={{ width: "100&", p: 1 }}>
              <Box display="flex" alignItems={"center"}>
                {statusByIcon[history?.status as keyof typeof statusByIcon]}
                <Box ml={1}>
                  <Typography fontWeight={500}>
                    {formatedDate(setTime(history?.data, history?.hora), true)}
                  </Typography>
                  <Typography>{history?.status}</Typography>
                  {history?.status ===
                    "Objeto aguardando retirada no endereço indicado" && (
                    <a
                      style={{ marginLeft: 10 }}
                      href="https://rastreamento.correios.com.br/app/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Clique para consultar o endereço no site dos correios
                    </a>
                  )}
                  {history?.subStatus.map((subStatus, key) => (
                    <Typography ml={1} fontSize={13} key={key}>
                      {subStatus}
                    </Typography>
                  ))}
                </Box>
              </Box>
            </Box>
            <Divider />
          </Box>
        ))
      )}
      {history?.eventos?.length === 0 ||
        (!history?.eventos && (
          <Typography sx={{ p: 2 }}>
            Rastreio possivelmente antigo ou não localizado, verifique no site
            dos{" "}
            <a
              href="https://rastreamento.correios.com.br/app/index.php"
              target="_blank"
              rel="noreferrer"
            >
              Correios
            </a>
          </Typography>
        ))}
      <Typography textAlign="center" mt={1}>
        rastreado com
        <a
          style={{ marginLeft: 10 }}
          href="https://linketrack.com"
          target="_blank"
          rel="noreferrer"
        >
          link track
        </a>
      </Typography>
    </CustomModal>
  );
}
