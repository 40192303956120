import { Box } from "@mui/material";
import { FadeInContainer } from "../../../components/AtomicDesign/Atoms";
import {
  BoxWithTitle,
  ShippmentCards,
} from "../../../components/AtomicDesign/Molecules";
import {
  CustomModal,
  NewShippmentBox,
  UpdateShippmentBox,
} from "../../../components/AtomicDesign/Organisms";
import { useState } from "react";
import { useBreakpoints } from "../../../hooks/useBreakpoins";
import { useTranslate } from "../../../hooks/useTranslate";
import useAuth from "../../../hooks/context/useAuth";
import useShippments from "../../../hooks/context/useShippments";

export function Shippments() {
  const {
    cleanFilters,
    shippmentsDataMemo,
    shippment,
    setFilters,
    submitOrder,
    handleIDOrder,
    setHandleIDOrder,
    setShippment,
    records,
    getFilterStorage,
    setAttData,
    loading,
    getUserProducts,
  } = useShippments();

  const { strings } = useTranslate();
  const { language } = useAuth();
  const { isSmalldesktop } = useBreakpoints();
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setShippment({} as OneShippment);
    setModalOpen(false);
    setHandleIDOrder(null);
  };

  return (
    <FadeInContainer>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        alignContent={"center"}
        gap={5}
        p={1}
      >
        <BoxWithTitle
          title={strings.shippmentPage.title[language]}
          width="100%"
          childrenProps={{ height: "100%" }}
          padding={1}
          // right button
          hasButton
          onClick={() => setModalOpen(true)}
          buttonTitle={strings.buttons.newShippment[language]}
          // right button
          // filter
          hasFilter
          onSelectField={(val) => setFilters("key", val)}
          selectedField={getFilterStorage().key}
          onChangeTextField={(val) => {
            setFilters("searchTerm", val);
            setAttData((bool: boolean) => !bool);
          }}
          searchTerm={getFilterStorage().searchTerm}
          onClickCleanFilter={cleanFilters}
          // filter
          // pagnation
          hasPagination
          itensPerpage={(val) => {
            setFilters("itensPerPage", val);
            setAttData((bool: boolean) => !bool);
          }}
          itensPerPage={getFilterStorage().itensPerPage} // quantidade
          howManyPages={Math.ceil(records / getFilterStorage().itensPerPage)}
          onChangePage={(page) => {
            setFilters("offset", page - 1);
            setAttData((bool: boolean) => !bool);
          }}
          // pagnation
        >
          <ShippmentCards
            data={shippmentsDataMemo}
            onClickShippment={(id) => {
              setHandleIDOrder(id);
              setModalOpen(true);
            }}
            isLoading={loading.ordersLoading}
          />
        </BoxWithTitle>
      </Box>
      <CustomModal
        openModal={modalOpen}
        setOpenModal={closeModal}
        width={isSmalldesktop ? "90%" : "40%"}
        title={
          handleIDOrder
            ? `${strings.shippmentPage.modal.shippment[language]} ${handleIDOrder}`
            : ""
        }
      >
        {handleIDOrder ? (
          <UpdateShippmentBox
            data={shippment ?? ({} as OneShippment)}
            getProducts={getUserProducts}
            closeModal={closeModal}
          />
        ) : (
          <NewShippmentBox
            handleSubmit={(ship) => {
              submitOrder(ship);
              closeModal();
            }}
            getProducts={getUserProducts}
            loading={loading.submitDelivery}
          />
        )}
      </CustomModal>
    </FadeInContainer>
  );
}
