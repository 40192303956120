import { Box, Container, Grow, Typography } from "@mui/material";
import { red, teal } from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import meninacomCelular from "../../assets/images/menina_com_celular.png";
import meninaComDuvida from "../../assets/images/menina_em_pe.png";

export function PaymentSuccess({
  seconds,
  isPaid,
}: {
  seconds: number;
  isPaid: boolean;
}) {
  return (
    <Container
      maxWidth="lg"
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <img
        src={isPaid ? meninacomCelular : meninaComDuvida}
        width={350}
        alt="merrybright"
      />
      <Box
        sx={{
          background: isPaid ? teal[400] : teal[900],
          height: 325,
          width: "100%",
          borderRadius: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          p={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Grow timeout={1000} in>
            {isPaid ? (
              <CheckIcon
                style={{
                  fill: teal[400],
                  background: "#FFFFFF",
                  borderRadius: "50%",
                  fontSize: 100,
                }}
              />
            ) : (
              <CloseIcon
                style={{
                  fill: "#FFFFFF",
                  background: red[400],
                  borderRadius: "50%",
                  fontSize: 100,
                }}
              />
            )}
          </Grow>
          <Grow timeout={2000} in>
            <Typography color="#FFFFFF" fontSize={25}>
              {isPaid ? "All done!" : "Something went wrong"}
            </Typography>
          </Grow>
          <Grow timeout={3000} in>
            <Typography color="#FFFFFF" fontSize={25} textAlign={"center"}>
              {isPaid
                ? `I'll redirect you to your profile in
              ${seconds} seconds`
                : `Your payment was not concluded, you can verify your credit card, contact your card issuer or try again, i'll redirect you to your profile in ${seconds} seconds`}
            </Typography>
          </Grow>
        </Box>
      </Box>
    </Container>
  );
}
