import { Box, Button, Container, FormHelperText } from "@mui/material";
import { ContactInfo, GenericInput } from "../../../Molecules";
import { BreakpointBox, FadeInContainer, TitleDivider } from "../../../Atoms";
import { sendEmail } from "../../../../../services/messageService";
import { useFormik } from "formik";
import * as yup from "yup";
import { HtmlTextEditor } from "../../ReactQuillHtmlEditor";
import NearMeIcon from "@mui/icons-material/NearMe";
import { amber } from "@mui/material/colors";
import useToast from "../../../../../hooks/context/useToast";
import { useBreakpoints } from "../../../../../hooks/useBreakpoins";

export function ContactUS() {
  const { setOpen, setToastData } = useToast();
  const { isSmalldesktop } = useBreakpoints();
  const validationSchema = yup.object({
    name: yup.string().required("Write your name"),
    email: yup
      .string()
      .email("Insert a valid e-mail")
      .required("Write your e-mail"),
    subject: yup.string().required("Write your subject"),
    message: yup
      .string()
      .test("string test", (val) => {
        const regex = new RegExp(/(&nbsp;|<([^>]+)>)/gi);
        if (!val) return false;
        if (val.replace(regex, "").length === 0) return false;
        return true;
      })
      .required("Write details for your subject"),
  });
  const formik = useFormik<ISendEmail>({
    initialValues: {
      email: "",
      name: "",
      subject: "",
      message: "",
    },
    validationSchema,
    async onSubmit(values) {
      try {
        const response = await sendEmail({ ...values });
        if (response?.message === "success") {
          setToastData({
            anchor: { vertical: "top", horizontal: "center" },
            duration: 5000,
            type: "success",
            message: "The email has been sent",
          });
          setOpen(true);
        }
      } catch {
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "success",
          message:
            "An error occurred while sending the email, please try again later",
        });
        setOpen(true);
      }
    },
  });

  return (
    <Container maxWidth="lg">
      <FadeInContainer>
        <Box>
          <TitleDivider title="Any questions? Contact us anytime!" />
          <Box width={"100%"} display={"flex"} justifyContent={"center"}>
            <Box width={"80%"} display={"flex"} justifyContent={"center"}>
              <ContactInfo infoColor="black" />
            </Box>
          </Box>

          <TitleDivider title="Send an e-mail" />
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <BreakpointBox>
                <GenericInput
                  hasIcon={false}
                  onChange={(value, name) => formik.setFieldValue(name, value)}
                  error={
                    formik?.touched?.name && formik?.errors?.name ? true : false
                  }
                  errorMessage={
                    formik?.touched?.name ? formik?.errors?.name : ""
                  }
                  value={formik?.values?.name}
                  placeholder={"Name"}
                  inputName="name"
                />

                <GenericInput
                  hasIcon={false}
                  onChange={(value, name) => formik.setFieldValue(name, value)}
                  error={
                    formik?.touched?.email && formik?.errors?.email
                      ? true
                      : false
                  }
                  errorMessage={
                    formik?.touched?.email ? formik?.errors?.email : ""
                  }
                  value={formik?.values?.email}
                  placeholder={"E-mail"}
                  inputName="email"
                />
              </BreakpointBox>
              <GenericInput
                hasIcon={false}
                onChange={(value, name) => formik.setFieldValue(name, value)}
                error={
                  formik?.touched?.subject && formik?.errors?.subject
                    ? true
                    : false
                }
                errorMessage={
                  formik?.touched?.subject ? formik?.errors?.subject : ""
                }
                value={formik?.values?.subject}
                placeholder={"Subject"}
                inputName="subject"
              />

              <HtmlTextEditor
                onChange={(val) => formik.setFieldValue("message", val)}
                value={formik.values.message}
              />
              {formik?.touched?.message && formik?.errors?.message && (
                <FormHelperText error sx={{ mt: 7 }}>
                  {formik?.errors?.message}
                </FormHelperText>
              )}
            </Box>

            <Button
              variant={"contained"}
              sx={{
                width: "100%",
                height: 40,
                background: amber[500],
                "&:hover": { background: amber[200] },
                mb: 10,
                mt: isSmalldesktop ? 10 : 7,
              }}
              onClick={() => formik.submitForm()}
            >
              Send e-mail <NearMeIcon sx={{ ml: 1 }} />
            </Button>
          </Box>
        </Box>
      </FadeInContainer>
    </Container>
  );
}
