import { Box, Container, Divider, Link } from "@mui/material";
import { FadeInContainer, TitleDivider } from "../../../Atoms";
import { CustomAccordion } from "../../../Molecules";
import { useNavigate } from "react-router-dom";
import meninaEmPe from "../../../../../assets/images/menina_em_pe.png";

export function FAQ() {
  const nav = useNavigate();
  const questions = [
    {
      title: "How to buy with Treasure Box Japan?",
      description:
        'After registering in our system, you can click on the "Orders" menu and "+ New Order" and fill in the necessary information with the link to the website that contains the desired product, our team will analyze the link and request payment in the amount which is described in the link, after payment confirmation, the product will be purchased and we will wait for it to arrive in our stock.',
    },
    {
      title: "When my product arrive?",
      description:
        'Your product arrives in our stock after the company that owns the product sends it to our warehouse here in Japan and we will register your products in our system and you will be notified when it arrives in our stock!, you can see in "Suite" menu.',
    },
    {
      title: "After my product arrive, what i need to do?",
      description:
        'After you see that your product is in your registry, simply go to the "Shippments" menu and click on "New shippment" and fill in the necessary information and a new delivery will be created, then our team will analyze the delivery and continue with other arrangements as payment or possibly request some correction of the delivery.',
    },
    {
      title: "What services you offer?",
      description:
        'We offer the redirection service for all countries, our team is fully focused on responding quickly to each service offered, among them we offer product purchases, offers in advertisements or ask questions in advertisements, we also offer the "economy mode", where we withdraw the products in the original box, so that it is possible to send the largest quantity of products, all of this must be requested in advance in our system.',
    },
    {
      title: "You are from Japan?",
      description:
        "Yes, we live in Japan and because of this we have access to any existing store in Japan, contact the location where you want a product and we will serve you with all our attention!",
    },
    {
      title: "Do you have a warehouse to store products safely?",
      description:
        "Yes, we have a 3-storey warehouse where all your products will be stored in a safe and organized manner, we are not a small place, we have all the logistics to serve you in the best way!",
    },
    {
      title: "How does product organization work?",
      description:
        'Each customer when registering will have a "Suite" number, the Suite is their place in our warehouse, all their products are stored in their exclusive space within our warehouse, the Suite number is unique and non-transferable.',
    },
    {
      title: "What payment methods are accepted?",
      description:
        'We accept payment via "Transferwise", "Paypal" and Pix (Brazil only). You send proof within the order and after analysis, the order will follow the remaining steps.',
    },
    {
      title: "What shipping methods do you use?",
      description:
        'We use the 3 main methods, which are: "Small packet", "EMS", "Surface", and on our Home you can simulate your shipping!',
    },
    {
      title: "Is there a product that cannot be shipped?",
      description:
        'Yes, flammable products even if small or very large products or are extremely heavy, place the order in the "Orders" menu within our system and we will check all of this for you!',
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ mb: 5 }}>
      <FadeInContainer>
        <TitleDivider title="Top questions" />
        <Box
          width="100%"
          display={"flex"}
          justifyContent={"center"}
          mb={2}
          mt={-2}
        >
          <img src={meninaEmPe} alt="pirataphoto" width={500} />
        </Box>

        <Box width="100%" display={"flex"} justifyContent={"center"}>
          <Box width={"80%"}>
            <CustomAccordion list={questions} />
          </Box>
        </Box>

        <Box
          display={"flex"}
          width={"100%"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          mb={5}
        >
          <Divider sx={{ width: "80%", mb: 5 }} />
          <Link
            component="button"
            variant="body2"
            underline="none"
            onClick={() => {
              nav("/login/new-user");
            }}
            fontSize={25}
          >
            Sign up, it's free!
          </Link>
        </Box>
      </FadeInContainer>
    </Container>
  );
}
