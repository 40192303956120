import { Box, Typography } from "@mui/material";

interface Props {
  title: string;
  text: string;
  alignText?: "center" | "right" | "left";
  padding?: string;
}

export function TextCard({
  title,
  text,
  alignText = "left",
  padding = "15px 0px",
}: Props) {
  return (
    <Box display={"flex"} mt={0.5} p={padding}>
      {title && (
        <Box minWidth={"100px"}>
          <Typography fontWeight={500}>{title}</Typography>
        </Box>
      )}
      {text && (
        <Box width="100%">
          <Typography textAlign={alignText}>{text}</Typography>
        </Box>
      )}
    </Box>
  );
}
