import { Container } from "@mui/material";
import { FadeInContainer, TitleDivider } from "../../../Atoms";
import { ShippingFeeSimulator } from "../../ShippingFeeSimulator";

export function ShippingSimulator() {
  return (
    <FadeInContainer>
      <Container maxWidth="lg">
        <TitleDivider title="Price simulation" />
        <ShippingFeeSimulator />
      </Container>
    </FadeInContainer>
  );
}
