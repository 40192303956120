import {
  CardActions,
  CardContent,
  Card,
  Box,
  Chip,
  Typography,
} from "@mui/material";
import {
  DataText,
  Button,
  CustomToolTip,
  NoData,
  MailNotification,
  CircularLoading,
} from "../../Atoms";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import useAuth from "../../../../hooks/context/useAuth";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import { UseDateTime } from "../../../../hooks/useDateTime";
import { useCommonData } from "../../../../hooks/useCommonData";
import { Credits } from "../../../../types/credits";

interface Props {
  data: Credits[];
  onClickCredit(val: Credits): void;
  isLoading?: boolean;
}

export function CreditCards({ data, onClickCredit, isLoading }: Props) {
  const { labelStatus } = useCommonData();
  const { formatedDate } = UseDateTime();
  const { isSmalldesktop, isMobile, mediumDesktop, semiSmalldesktop } =
    useBreakpoints();
  const { JPYmoney } = useJPYmoney();
  const { user } = useAuth();

  const titleWidth = 100;

  const hasNewMessage = (credit: Credits) => {
    const messages = credit?.WarningMessage?.sort((a: IMessage, b: IMessage) =>
      a?.created < b?.created ? 1 : -1
    );
    if (!messages) return false;
    if (messages?.length === 0) return false;
    if (!messages[0]?.read && user?.user?.id !== messages[0]?.usersId) {
      if (user?.user?.role === messages[0]?.type) {
        return false;
      }
      return true;
    }
    return false;
  };

  const breakpoints = () => {
    if (isMobile) return "100%";
    if (isSmalldesktop) return "48.5%";
    if (semiSmalldesktop) return "32%";
    if (mediumDesktop) return "23.9%";
    return "19%";
  };

  return (
    <Box
      width={"auto"}
      display="flex"
      flexWrap={"wrap"}
      rowGap={1.5}
      columnGap={1.5}
      justifyContent={"flex-start"}
    >
      {isLoading ? (
        <CircularLoading />
      ) : data && data?.length > 0 ? (
        data.map((item, index) => (
          <Card
            sx={{
              width: breakpoints(),
              minWidth: "245px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            key={index}
          >
            <Box>
              <Typography p={1} variant="h2" fontWeight={500}>
                {item?.userName}
              </Typography>
            </Box>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  justifyContent: "center",
                  rowGap: 0.5,
                }}
              >
                <DataText
                  title={"Created at:"}
                  text={formatedDate(item.createdAt)}
                  titleProps={{ width: titleWidth }}
                />
                <DataText
                  title={"ID:"}
                  text={item.id}
                  titleProps={{ width: titleWidth }}
                />
                <DataText
                  title={"Suite:"}
                  text={`${item?.Users?.suite[0]?.id}`}
                  titleProps={{ width: titleWidth }}
                />
                <CustomToolTip text={item.Users.user.name}>
                  <DataText
                    title={"Customer:"}
                    text={`${item?.Users?.user?.name}`}
                    titleProps={{ width: titleWidth }}
                  />
                </CustomToolTip>
                <DataText
                  title={"Credit:"}
                  text={`${JPYmoney(Number(item.creditValue))}`}
                  titleProps={{ width: titleWidth }}
                />
                <DataText
                  title={"Status:"}
                  text={`${
                    labelStatus[item.status as keyof typeof labelStatus].label
                  }`.toUpperCase()}
                  titleProps={{ width: titleWidth }}
                />
              </Box>
            </CardContent>
            <CardActions
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                config={{
                  size: "small",
                  fullWidth: false,
                  onClick: () => onClickCredit(item),
                }}
                style={{ color: "white" }}
              >
                {labelStatus.button.label}
              </Button>
              <MailNotification hasNewMessage={hasNewMessage(item)} disabled />
              <Chip
                label={
                  labelStatus[item.status as keyof typeof labelStatus].label
                }
                style={{
                  background:
                    labelStatus[item.status as keyof typeof labelStatus].color,
                  color: "white",
                  fontWeight: 500,
                }}
              />
            </CardActions>
          </Card>
        ))
      ) : (
        <NoData text="No data" />
      )}
    </Box>
  );
}
