import { BoxContent, BreakpointBox, Button } from "../../Atoms";
import { EmailField, GenericInput, PasswordField } from "../../Molecules";
import { FormikProps } from "formik";
import treasureBoxLogo from "../../../../assets/images/NEWTREASUREBOXLOGO2.png";
import CountrySelect from "../AutocompletePhone";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "../../../../hooks/useTranslate";
import useAuth from "../../../../hooks/context/useAuth";

interface IFormInputs {
  name: string;
  phoneCode: string;
  phone: string;
  ssn: string;
  email: string;
  password: string;
}

export function NewUserBox({ formik }: { formik: FormikProps<IFormInputs> }) {
  const { strings } = useTranslate();
  const { language } = useAuth();
  const navigate = useNavigate();
  const handleCreateUser = () => {
    formik.handleSubmit();
  };

  return (
    <BoxContent config={{ p: 3, mt: 5, mb: 5 }}>
      <img src={treasureBoxLogo} width={150} alt="treasurebox logo" />
      <GenericInput
        hasIcon={false}
        onChange={(value, name) => formik.setFieldValue(name, value)}
        error={formik.touched.name && formik.errors.name ? true : false}
        errorMessage={formik.touched.name ? formik.errors.name : ""}
        value={formik.values.name}
        placeholder="Name"
        inputName="name"
      />

      <BreakpointBox>
        <Box display="flex" sx={{ width: "100%" }}>
          <CountrySelect
            selectedCode={(val) => formik.setFieldValue("phoneCode", val)}
          />
          <GenericInput
            hasIcon={false}
            onChange={(value, name) => formik.setFieldValue(name, value)}
            error={
              (formik.touched.phone && formik.errors.phone) ||
              (formik.touched.phoneCode && formik.errors.phoneCode)
                ? true
                : false
            }
            errorMessage={
              formik.touched.phone || formik.touched.phoneCode
                ? formik.errors.phone
                  ? formik.errors.phone
                  : formik.errors.phoneCode
                  ? formik.errors.phoneCode
                  : ""
                : ""
            }
            value={formik.values.phone}
            placeholder="Phone"
            inputName="phone"
          />
        </Box>
        <GenericInput
          hasIcon={false}
          onChange={(value, name) => formik.setFieldValue(name, value)}
          error={formik.touched.ssn && formik.errors.ssn ? true : false}
          errorMessage={formik.touched.ssn ? formik.errors.ssn : ""}
          value={formik.values.ssn}
          placeholder="CPF(exclusive to Brazil)"
          inputName="ssn"
        />
      </BreakpointBox>

      <EmailField
        hasIcon={false}
        onChange={(value) => formik.setFieldValue("email", value)}
        error={formik.touched.email && formik.errors.email ? true : false}
        errorMessage={formik.touched.email ? formik.errors.email : ""}
        value={formik.values.email}
        placeholder="E-mail"
      />
      <PasswordField
        hasIcon={false}
        onChange={(value) => formik.setFieldValue("password", value)}
        error={formik.touched.password && formik.errors.password ? true : false}
        errorMessage={formik.touched.password ? formik.errors.password : ""}
        value={formik.values.password}
        placeholder="Password"
      />
      <Button
        config={{
          variant: "contained",
          onClick: handleCreateUser,
        }}
        style={{ marginTop: 12 }}
      >
        {strings.buttons.createLogin[language]}
      </Button>
      <Button
        config={{
          variant: "text",
          onClick: () => navigate("/"),
          sx: { bgcolor: "transparent" },
        }}
        style={{ marginTop: 12 }}
      >
        {strings.buttons.cancelBtn[language]}
      </Button>
    </BoxContent>
  );
}
