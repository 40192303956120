import { Box } from "@mui/material";
import { HeaderLP } from "../../../components/layouts/Header/HeaderLP";
import { ContactUS } from "../../../components/AtomicDesign/Organisms";

export function ContactPage() {
  return (
    <Box width={"100%"} mb={5}>
      <HeaderLP activeTab={0} setActiveTab={() => {}} showtabs={false} />
      <ContactUS />
    </Box>
  );
}
