import { api } from "./api";

export const getUserAddress = async (userID: number) => {
  const response2 = await api.get("/address", { params: { userID } });
  return response2.data;
};

export const createAddress = async (val: EditCreateAddress) => {
  await api.post("/create-address", val);
};

export const editAddress = async (val: EditCreateAddress) => {
  await api.post("/update-address", val);
};

export const deleteAddress = async (addressID: number) => {
  await api.delete("/update-address", { params: { addressID } });
};
