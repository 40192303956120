import { Box, Typography } from "@mui/material";
import meninaCelular from "../../assets/images/menina_com_celular.png";
import { FadeInContainer } from "../../components/AtomicDesign/Atoms";
import { useBreakpoints } from "../../hooks/useBreakpoins";

export function MaintenancePage() {
  const { isSmalldesktop } = useBreakpoints();
  return (
    <FadeInContainer>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        flexDirection={"column"}
        gap={5}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        height={"100vh"}
      >
        <img
          src={meninaCelular}
          alt="menina com celular"
          width={isSmalldesktop ? "100%" : "20%"}
        />

        <Typography fontSize={20} textAlign={"center"} p={1}>
          Our site is under maintenance, we are working to improve your
          experience, we'll return as soon as possible.
        </Typography>

        <Typography textAlign={"center"}>
          {String(new Date().getFullYear())} © Treasure Box
        </Typography>
      </Box>
    </FadeInContainer>
  );
}
