import { Box, BoxProps } from "@mui/material";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";

export function BreakpointBox({
  children,
  props,
}: {
  children: React.ReactNode[];
  props?: BoxProps;
}) {
  const { isMobile } = useBreakpoints();
  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={isMobile ? "column" : "row"}
      {...props}
    >
      {children}
    </Box>
  );
}
