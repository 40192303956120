import * as React from "react";
import { CardActions, CardContent, IconButton, Card, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataText, Button, CircularLoading } from "../../Atoms";
import useAuth from "../../../../hooks/context/useAuth";
import { useTranslate } from "../../../../hooks/useTranslate";

interface Props {
  loading: Boolean;
  data?: IAddress[];
  onClickEditAddress(val: IAddress): void;
  onClickDeleteAddress(addressID: number): void;
}

export function AddressBox({
  loading,
  data,
  onClickEditAddress,
  onClickDeleteAddress,
}: Props) {
  const { language } = useAuth();
  const { strings } = useTranslate();
  return (
    <Box
      width={"100%"}
      display="flex"
      flexWrap={"wrap"}
      rowGap={2}
      justifyContent={"space-evenly"}
    >
      {loading ? (
        <CircularLoading />
      ) : (
        data &&
        data?.length > 0 &&
        data.map((item, index) => (
          <Card
            sx={{
              width: 340,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            key={index}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  justifyContent: "center",
                  rowGap: 1,
                }}
              >
                <DataText
                  title={strings.myAddress.labels.address[language]}
                  text={`${item.address} ${item.adressNumber}`}
                />
                <DataText
                  title={strings.myAddress.labels.Neighborhood[language]}
                  text={`${item.addressComplement}`}
                />
                <DataText
                  title={strings.myAddress.labels.complementInCard[language]}
                  text={`${item.addressNewComplement}`}
                />
                <DataText
                  title={strings.myAddress.labels.city[language]}
                  text={`${item.city} / ${item.state}`}
                />
                <DataText
                  title={strings.myAddress.labels.country[language]}
                  text={`${item.country}`}
                />
                <DataText
                  title={strings.myAddress.labels.zipCode[language]}
                  text={`${item.postalCode}`}
                />
                <DataText
                  title={strings.myAddress.labels.ssn[language]}
                  text={`${item.ssn}`}
                />
                <DataText
                  title={strings.myAddress.labels.receiver[language]}
                  text={`${item.receiver}`}
                />
              </Box>
            </CardContent>
            <CardActions
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                config={{
                  size: "small",
                  fullWidth: false,
                  onClick: () => onClickEditAddress(item),
                }}
                style={{ color: "white" }}
              >
                {strings.buttons.edit[language]}
              </Button>
              <IconButton
                onClick={() => onClickDeleteAddress(item.id)}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </CardActions>
          </Card>
        ))
      )}
    </Box>
  );
}
